import {
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  useTheme,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { MdSsidChart } from "react-icons/md";
import { enqueueSnackbar } from "notistack";

import {
  setHighlightedScenarios,
  setUpdateChart,
} from "../../store/slices/board";
import { updateFilters, setScenario } from "../../store/slices/global";
import { setStageLoadingText } from "../../store/slices/appmain";
import { setPopupStatus3 } from "../../store/slices/datasets";
import DNDListProvider, { DNDListItem } from "../DND/context";
import CustomPopoverScratch from "../PopOver/CustomPopover";
import useSubscriptions from "../../hooks/useSubscriptions";
import { getTransactionByParams } from "../../Helper/data";
import Translate from "../../hooks/HOC/Translate";
import EndPoints from "../../APICall/EndPoints";
import CustomModal from "../Model/CustomModal";
import { Color, Constant } from "../../Helper";
import RadioCircle from "../RadioCircle";
import ColorPicker from "../ColorPicker";
import TitleInput from "./TitleInput";
import AddButton from "../AddButton";
import APICall from "../../APICall";
import MenuView from "./MenuView";
import DropDown from "./DropDown";
import theme from "../../theme";
import store from "../../store";
import Icon from "../Icon";

const options = [
  {
    value: 1,
    label: "Edit",
    icon: <EditIcon />,
  },
  {
    value: 4,
    label: "Delete",
    icon: <DeleteIcon />,
  },
];

const ScenarioDropdown = ({ width = "12rem", color = "slate" }) => {
  const dispatch = useDispatch();

  const onClose = (ids) => {
    if (ids) {
      let chart_keys = {};
      ids?.forEach((id) => {
        chart_keys[id] = true;
      });
      setTimeout(() => {
        dispatch(
          updateFilters({
            parent_key: "kanban",
            obj: {
              chart_keys,
            },
          })
        );
        dispatch(setUpdateChart());
      }, 0);
    }
  };
  return (
    <CustomPopoverScratch button={<View width={width} color={color} />}>
      <OverlayView onClose={onClose} />
    </CustomPopoverScratch>
  );
};

export default ScenarioDropdown;

const View = ({ width, color, popup_state }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  //redux
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );
  const dataset_uuid = useSelector(
    (state) => state.boardSlice?.dataSetData?.uuid
  );

  const isOpen = popup_state?.isOpen;
  let title = t("Select scenario");
  const item = scenarioById?.[highlightedScenarios?.[0]]?.[0];
  const base_id = scenarioByTitle?.["Base"]?.[0]?.uuid;

  if (highlightedScenarios?.length > 0) {
    title = item?.title === "Base" ? t(item?.title) : item?.title;
  }

  useEffect(() => {
    if (
      !highlightedScenarios ||
      highlightedScenarios?.length === 0 ||
      !highlightedScenarios?.[0] ||
      highlightedScenarios?.[0] !== base_id
    ) {
      if (base_id && dataset_uuid) {
        dispatch(setHighlightedScenarios([base_id]));
        dispatch(setUpdateChart());
      }
    }
  }, [base_id, dataset_uuid]);

  return (
    <Box
      sx={{
        borderRadius: theme.borderRadius.main,
        backgroundColor:
          item?.title === "Base"
            ? theme.palette.color.white
            : theme.palette.color?.[item?.color || "slate"]?.[200],
        border: `1px solid ${theme.palette.color?.[item?.color || "slate"]?.[300]}`,
        px: "0.6rem",
        py: "0.5rem",
        cursor: "pointer",
        width: width,
        mr: "1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "&:hover": {
          "& svg, p": {
            color: `${theme.palette.primary.main} !important`,
            borderColor: `${theme.palette.primary.main} !important`,
          },
        },
      }}
    >
      <MdSsidChart
        style={{
          fontSize: "1.15rem",
          color: theme.palette.color?.[item?.color || "slate"]?.[600],
        }}
      />
      <Typography
        variant={"body2"}
        fontWeight={"fontWeightMediumBold"}
        sx={{
          width: "80%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          fontSize: "0.85rem",
          textOverflow: "ellipsis",
          color: theme.palette.color?.[item?.color || "slate"]?.[600],
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      <KeyboardArrowDownIcon
        sx={{
          color: theme.palette.color?.[color]?.[600],
          transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
          transition: "transform 0.3s ease-in-out",
        }}
      />
    </Box>
  );
};

const ItemView = ({
  dragHandleRef,
  item,
  isActive,
  isActiveEye,
  onClick,
  handleClickEyeBtn,
  handleClickMore,
}) => {
  const { t } = useTranslation();
  const scenarioById = store?.getState()?.globalSlice.scenarioById;
  const reference_scenario = scenarioById?.[item?.reference]?.[0]?.title;

  return (
    <Stack
      key={item?.uuid}
      onClick={onClick}
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        color: theme.palette.color?.[item?.color || "slate"]?.[600],
        borderRadius: 1,
        pl: "2rem",
        pr: "0.5rem",
        py: "0.15rem",
        cursor: "pointer",
        width: "100%",
        backgroundColor: isActive
          ? theme.palette.color?.[item?.color || "slate"]?.[100]
          : "transparent",
        "&:hover": {
          "& #dnd-drag-handle": {
            visibility: "visible !important",
          },
          backgroundColor:
            theme.palette.color?.[item?.color || "slate"]?.[
              isActive ? 100 : 50
            ],
        },
      }}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <ListItemIcon sx={{ justifyContent: "center" }}>
          <div
            ref={dragHandleRef}
            id={"dnd-drag-handle"}
            style={{
              visibility: "hidden",
              position: "absolute",
              cursor: "grab",
              left: "4%",
              top: "26%",
            }}
          >
            <DragIndicatorOutlinedIcon />
          </div>
          <RadioCircle
            checked={isActive}
            size={18}
            color={item?.color || "slate"}
          />
        </ListItemIcon>
        <ListItemText
          primary={item?.title === "Base" ? t(item?.title) : item?.title}
          secondary={
            item?.reference
              ? `Reference: ${
                  reference_scenario === "Base"
                    ? t(reference_scenario)
                    : reference_scenario
                }`
              : ""
          }
          sx={{
            p: 0,
            "& .MuiListItemText-primary": {
              fontWeight: 600,
              fontSize: "0.85rem",
              color: theme.palette.color?.[item?.color || "slate"]?.[500],
            },
            "& .MuiListItemText-secondary": {
              fontWeight: 500,
              fontSize: "0.65rem",
              color: theme.palette.color?.[item?.color || "slate"]?.[500],
            },
          }}
        />
      </Stack>
      {handleClickMore ? (
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton
            // onClick={onClickStack}
            edge="end"
            aria-label="eye"
            sx={{ background: "transparent", mr: "0.2rem", zIndex: 2 }}
          >
            <Icon
              onClick={handleClickEyeBtn}
              icon={isActiveEye ? <FaEye /> : <FaEyeSlash />}
              color={
                isActiveEye
                  ? theme.palette.color?.[item?.color || "slate"]?.[500]
                  : theme.palette.color?.["slate"]?.[300]
              }
              fontSize={"1.5rem"}
              style={{
                background: "transparent",
              }}
            />
          </IconButton>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-haspopup="true"
            onClick={handleClickMore}
            sx={{ background: "transparent !important", p: 0 }}
          >
            <MoreVertIcon
              sx={{
                background: "transparent !important",
                color: theme.palette.color.slate[600],
              }}
            />
          </IconButton>
        </Stack>
      ) : null}
    </Stack>
  );
};

const ItemViewWrapper = ({
  isActive,
  isActiveEye,
  color,
  item,
  index,
  handleClickEyeBtn,
  handleClickMore,
  onClick,
}) => {
  const dragHandleRef = useRef(null);
  const elementRef = useRef(null);

  return (
    <DNDListItem
      elementRef={elementRef}
      dragHandleRef={dragHandleRef}
      item={item}
      index={index}
      itemView={
        <ItemView
          dragHandleRef={dragHandleRef}
          item={item}
          isActive={isActive}
          isActiveEye={isActiveEye}
          color={color}
          handleClickMore={handleClickMore}
          handleClickEyeBtn={handleClickEyeBtn}
          onClick={onClick}
        />
      }
    />
  );
};

const OverlayView = ({ popup_state, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itemRef = useRef({});
  const isDataUpdated = useRef(false);

  //redux state
  const scenario = useSelector((state) => state.globalSlice?.scenario);

  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );
  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );
  //state
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (!popup_state?.isOpen) {
      onClose(isDataUpdated?.current ? selectedIds : null);
    } else {
      const chartLineKeys = store?.getState()?.boardSlice?.chartLineKeys;
      setSelectedIds(
        chartLineKeys
          ?.filter((o1) => o1?.isScenario && o1?.isSelected)
          ?.map((o1) => o1?.uuid)
      );
    }
  }, [popup_state]);

  //Api
  const getScenarioById = async (UUID) => {
    await APICall("get", EndPoints.scenarios + `${UUID}/`, null, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.status === 404) {
        setTimeout(() => {
          const base_id = scenarioByTitle?.["Base"]?.[0]?.uuid;
          const updatedScenario = scenario?.filter((o1) => o1.uuid !== UUID);
          dispatch(setStageLoadingText(null));
          dispatch(setPopupStatus3(null));
          dispatch(setScenario(updatedScenario));
          if (highlightedScenarios?.includes(UUID)) {
            dispatch(setHighlightedScenarios([base_id]));
          }
          setAnchorEl(null);
          enqueueSnackbar(t("Scenario_Deleted_Successfully"), {
            variant: "success",
            autoHideDuration: 2000,
          });
        }, 0);
      } else {
        setTimeout(() => {
          getScenarioById(UUID);
        }, 4000);
      }
    });
  };

  const deleteScenarioByIdApi = async (uuid) => {
    dispatch(setStageLoadingText("process_scenario_is_being_deleted"));
    await APICall("delete", EndPoints.scenarios + `${uuid}/`).then(
      (response) => {
        if (response.status === 204 && response) {
          getScenarioById(uuid);
        }
      }
    );
  };

  const batchUpdateScenarios = async (payload) => {
    await APICall("put", EndPoints.scenarios + "batch_update/", payload, {
      doNotCatchRespond: true,
    });
  };

  //function
  const updateChartKeys = (e, uuid, alwaysAdd) => {
    e?.stopPropagation();
    isDataUpdated.current = true;
    if (selectedIds?.includes(uuid)) {
      if (!alwaysAdd) {
        setSelectedIds((prev) => prev?.filter((o1) => o1 !== uuid));
      }
    } else {
      setSelectedIds((prev) => [...(prev || []), uuid]);
    }
  };

  const onClick = (e, value) => {
    dispatch(setHighlightedScenarios(value));
    updateChartKeys(null, value?.[0], true);
  };

  const openAddForm = (e) => {
    itemRef.current = {
      modalType: "add",
      payload: null,
    };
    setAnchorEl(e.currentTarget);
  };

  const onClickItem = (e, value) => {
    e?.stopPropagation();

    if (value === 1) {
      setAnchorEl(e.currentTarget);
    }
    if (value === 4) {
      setAnchorElMenu(null);
      onClickDelete();
    }
  };

  const handleClickMore = (e, item) => {
    e?.stopPropagation();

    itemRef.current = {
      modalType: "edit",
      payload: item,
    };
    setAnchorElMenu(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const onClickDelete = async () => {
    if (!itemRef?.current?.payload?.can_be_deleted) {
      dispatch(
        setPopupStatus3({
          open: true,
          overlay_type: "delete",
          onConfirm: handleCloseMenu,
          payload: {
            title: t("Attention"),
            message: <Translate i18nkey={`scenario_delete_message`} />,
            confirmText: t("Ok"),
            hideCancel: true,
          },
        })
      );
      return;
    }

    const response = await getTransactionByParams({
      page: 1,
      page_size: 1,
      scenario: [itemRef?.current?.payload?.uuid],
    });

    dispatch(
      setPopupStatus3({
        open: true,
        overlay_type: "delete",
        onConfirm: onOkDelete,
        payload: {
          message:
            response?.count > 0 ? (
              <Translate
                i18nkey={"scenario_delete_message"}
                values={{ count: response?.count }}
              />
            ) : (
              `${t("Are_you_sure_delete_this")} ${t("Scenario")}?`
            ),
        },
      })
    );
  };

  const onOkDelete = () => {
    deleteScenarioByIdApi(itemRef.current?.payload?.uuid);
  };

  const onChangeItems = ({ items }) => {
    dispatch(setScenario(items));
    batchUpdateScenarios(items);
  };

  const updateDataBeforeSave = (data) => {
    let array = [];
    data?.forEach((item, index) => {
      if (Constant?.defaultScenario?.[item?.title]) {
        array.push({ ...item, ...Constant?.defaultScenario?.[item?.title] });
      } else {
        array.push({ ...item, position: index + 1 });
      }
    });
    return array;
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: "30rem",
        pt: "1rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          maxHeight: "21rem",
          overflow: "auto",
          ...theme.thinScrollBar,
        }}
      >
        <DNDListProvider
          items={scenario}
          setItems={onChangeItems}
          updateDataBeforeSave={updateDataBeforeSave}
        >
          {scenario?.map((item, index) => {
            const isActive = highlightedScenarios?.includes(item?.uuid);
            const isActiveEye = selectedIds?.includes(item?.uuid);

            return (
              <ItemViewWrapper
                key={item?.uuid}
                item={item}
                index={index}
                isActive={isActive}
                isActiveEye={isActiveEye}
                handleClickEyeBtn={(e) => updateChartKeys(e, item?.uuid)}
                handleClickMore={(e) => handleClickMore(e, item)}
                onClick={(e) => onClick(e, [item?.uuid])}
              />
            );
          })}
        </DNDListProvider>
      </Stack>

      <AddButton
        onClick={openAddForm}
        label={t("Add new Scenario")}
        isSecondary
        hideKey
        sx={{
          width: "fit-content",
          mt: "3rem",
          ml: "2rem",
          mb: "1.5rem",
        }}
      />
      <InnerOverlay
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        modalType={itemRef.current?.modalType}
        payload={itemRef.current?.payload}
      />
      {Boolean(anchorElMenu) ? (
        <MenuView
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          options={options}
          handleClose={handleCloseMenu}
          onClickItem={onClickItem}
        />
      ) : null}
    </Grid>
  );
};

const InnerOverlay = ({
  anchorEl,
  setAnchorEl,
  modalType = "add",
  payload,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubscriptionValid] = useSubscriptions();
  const isDataUpdated = useRef(false);

  //redux state
  const scenario = useSelector((state) => state.globalSlice.scenario);

  //state
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({
    title: "",
    alias: "",
    note: null,
  });
  const [error, setError] = useState({
    title: "",
    reference: "",
  });

  //api
  const addScenarioApi = async (obj) => {
    setLoading(true);
    await APICall("post", EndPoints.scenarios, obj)
      .then((response) => {
        if (response.status === 201 && response.data) {
          enqueueSnackbar(t("New_Scenarios_Added_Successfully"), {
            variant: "success",
            autoHideDuration: 2000,
          });
          dispatch(setScenario([...scenario, response.data]));
          handleClose();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateScenarioByID = async (id, obj) => {
    setLoading(true);

    await APICall("patch", EndPoints.scenarios + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          isDataUpdated.current = false;
          const data = [...scenario];
          const index = data?.findIndex((o1) => o1.uuid === id);
          data[index] = response.data;
          dispatch(setScenario(data));
          handleClose();
        } else {
          if (response?.data?.title?.[0]) {
            enqueueSnackbar(t(response?.data?.title?.[0]), {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    if (Boolean(anchorEl)) {
      isDataUpdated.current = false;
      if (modalType === "add") {
        setItem({
          title: "",
          reference: "",
          color: null,
        });
        setLoading(false);
      } else {
        setItem({
          ...payload,
          colorHex: Color.scenario_name_to_hase?.[payload?.color],
        });
        setLoading(false);
      }
    }
  }, [anchorEl, modalType, payload]);

  //functions
  const onChange = (event) => {
    isDataUpdated.current = true;
    if (error?.[event.target.name]) {
      setError((prev) => ({ ...prev, [event.target.name]: false }));
    }
    setItem((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onChangeDropDown = async (event, value) => {
    isDataUpdated.current = true;
    if (error?.reference) {
      setError((prev) => ({ ...prev, reference: false }));
    }
    setItem((prev) => ({
      ...prev,
      reference: value?.uuid,
    }));
  };

  const onChangeColor = (color) => {
    isDataUpdated.current = true;
    setItem((prev) => ({
      ...prev,
      colorHex: color,
      color:
        color?.includes("#") && Color?.scenario_hase_to_name[color]
          ? Color?.scenario_hase_to_name[color]
          : color,
    }));
  };

  const onConfirm = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      if (item?.title?.trim("") === "") {
        setError((prev) => ({
          ...prev,
          title: true,
        }));
        return;
      }
      if (!item?.reference) {
        setError((prev) => ({
          ...prev,
          reference: true,
        }));
        return;
      }
      if (modalType === "add") {
        if (scenario?.length < 10) {
          const maxPosition = scenario?.reduce(
            (max, { position }) => Math.max(max, position),
            0
          );
          let obj = {
            title:
              item?.title?.length > 0
                ? item?.title
                : `Scenario ${(scenario?.length || 0) + 1}`,
            note: "note",
            color: item?.color || "violet",
            reference: item?.reference || null,
            position: (Number(maxPosition) || 0) + 1,
            style: "solid",
          };
          addScenarioApi(obj);
        }
      } else {
        if (isDataUpdated.current) {
          setLoading(true);
          updateScenarioByID(item?.uuid, item);
        }
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <CustomModal
      textAdd={modalType === "add" ? t("Create") : t("save")}
      heading={t("Create Scenario")}
      onClose={handleClose}
      onAdd={onConfirm}
      loadingAdd={loading}
      modalType={modalType}
      open={open}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.color.white,
          p: "2rem",
          width: "fit-content",
          maxHeight: "50rem",
          minWidth: "45rem",
          height: "max-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: 0,
          borderRadius: 4,
          position: "relative",
        }}
      >
        <TitleInput
          value={item?.title}
          error={Boolean(error?.title)}
          helperText={error?.title}
          onChange={onChange}
          name="title"
          label={t("SCENARIO NAME")}
          placeholder={t("scenario_name_placeholder")}
          hideTitle
          likeGoogle
          variant="filled"
          disabled={loading}
          size="small"
          sx={{
            width: "100%",
          }}
        />

        <DropDown
          mb={0}
          hideTitle
          likeGoogle
          variant="filled"
          label={t("SCENARIO REFERENCE")}
          placeholder={t("Scenario")}
          value={scenario?.find((o1) => o1.uuid === item?.reference) || null}
          onChange={onChangeDropDown}
          options={scenario}
          getOptionLabel={(item) =>
            (item?.title === "Base" ? t(item?.title) : item?.title) ?? ""
          }
          renderOption={(item) =>
            (item?.title === "Base" ? t(item?.title) : item?.title) ?? ""
          }
          disabled={loading}
          error={Boolean(error?.reference)}
          helperText={error?.reference}
        />

        <Typography
          color="color.description"
          sx={{
            width: "100%",
            fontSize: "0.75rem",
            fontWeight: 500,
            ml: "1.75rem",
          }}
        >
          {t("choose_reference_scenario_info")}
        </Typography>
        <Typography
          color="color.mainTitle"
          sx={{
            width: "100%",
            fontSize: "0.85rem",
            fontWeight: 700,
            mt: "1.5rem",
            ml: "1.75rem",
          }}
        >
          {t("Color")}
        </Typography>

        <ColorPicker
          options={Color.scenarioTheme}
          color={item?.colorHex || item?.color}
          onChangeColor={onChangeColor}
          size={26}
          width={"60%"}
        />
      </Box>
    </CustomModal>
  );
};
