import {
  dropTargetForElements,
  monitorForElements,
  draggable,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import {
  useCallback,
  useContext,
  useEffect,
  Fragment,
  useState,
  useRef,
  memo,
} from "react";
import { preserveOffsetOnSource } from "@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { Button, CircularProgress, Grid, Tooltip, styled } from "@mui/material";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TbRowInsertBottom, TbRowInsertTop } from "react-icons/tb";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import AddIcon from "@mui/icons-material/Add";
import FocusRing from "@atlaskit/focus-ring";
import { useSelector } from "react-redux";
import { GoGear } from "react-icons/go";
import invariant from "tiny-invariant";
import { t } from "i18next";

import { DependencyContext, TreeContext } from "./tree-context";
import InlineEdit from "../../../../components/InlineEdit";
import { Constant } from "../../../../Helper";
import { indentPerLevel } from "./constants";
import theme from "../../../../theme";

const iconColor = "#44546F";

function ChildIcon() {
  return (
    <svg aria-hidden={true} width={24} height={24} viewBox="0 0 24 24">
      <circle cx={12} cy={12} r={2} fill={iconColor} />
    </svg>
  );
}

function GroupIcon({ expanded }) {
  const Icon = expanded ? ChevronDownIcon : ChevronRightIcon;
  return <Icon label="" color={iconColor} />;
}

function Icon({ item, isPreview }) {
  if (!item.children.length || isPreview) {
    return <ChildIcon />;
  }
  return <GroupIcon expanded={item.expanded ?? false} />;
}

const OuterButtonStyled = styled("div")((props) => {
  return {
    "--grid": "8px",
    /**
     * Without this Safari renders white text on drag.
     */
    color: "currentColor",
    border: props?.$isHighlighted
      ? `1px solid ${theme.palette.primary[500]}`
      : 0,
    width: "100%",
    position: "relative",
    background: "transparent",
    padding: 4,
    // padding: 0,
    borderRadius: 3,
    cursor: props?.$isPreview ? "default" : "pointer",
    "& .actionBtnText": {
      backgroundColor: theme.palette.color.slate[50],
      borderRadius: theme.borderRadius.main,
      width: "fit-content",
      height: "1.8rem",
      marginLeft: "0.5rem",
      fontSize: "0.9rem",
      minWidth: "1.8rem",
      display:
        props?.$anchorElMenu &&
        props?.$SavedCategoryInfo?.node?.uuid === props?.$uuid
          ? "display"
          : "none",
      color: theme.palette.color.slate[700],

      "&: hover": {
        backgroundColor: theme.palette.color.slate[100],
      },
    },
    "& .actionBtn": {
      backgroundColor: theme.palette.color.slate[50],
      borderRadius: theme.borderRadius.main,
      width: "1.8rem",
      height: "1.8rem",
      marginLeft: "0.5rem",
      minWidth: "1.8rem",
      display:
        props?.$anchorElMenu &&
        props?.$SavedCategoryInfo?.node?.uuid === props?.$uuid
          ? "display"
          : "none",
      "&: hover": {
        backgroundColor: theme.palette.color.slate[100],
      },
      "& .actionBtnIcon": {
        backgroundColor: "transparent",
        color: theme.palette.color.slate[700],
        fontSize: "1.2rem",
        margin: 0,
      },
    },
    "&:hover": {
      "& .category_grab_icon": {
        display: props?.$isPreview ? "none" : "flex",
      },
      "& .actionBtn": {
        display: props?.$isPreview ? "none" : "flex",
      },
      "& .actionBtnText": {
        display: props?.$isPreview ? "none" : "flex",
      },
      "& #category_assigned_chip": {
        display: "none",
      },
    },
  };
});

const TreeItemWrapper = styled("div")(({ $state }) => ({
  borderBottom: `1px solid ${theme.palette.color.slate[200]}`,
  ...($state === "idle"
    ? {
        background: theme.palette.color.white,
        borderRadius: 3,
        cursor: "pointer",
        // ":hover": {
        //   background: "rgba(9, 30, 66, 0.06)",
        // },
      }
    : {}),
}));

function Preview({ item, rect }) {
  return (
    <span
      style={{
        marginBottom: 40,
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        background: "white",
        borderRadius: 3,
        backgroundColor: theme.palette.color.slate[300],
        boxSizing: "border-box",
        width: rect?.width,
        height: rect?.height,
      }}
    >
      <span style={{ width: "2rem", alignItems: "center", display: "flex" }}>
        <Icon item={item} />
      </span>

      <CircleIcon
        sx={{
          fontSize: "1.375rem",
          ml: "0.5rem",
          mr: "1rem",
          color: item?.visible ? item?.color : theme.palette.color.slate[300],
        }}
      />
      <span
        style={{
          display: "flex",
          alignItems: "center",
          width: "35rem",
          height: "1.5rem",
        }}
      >
        <span
          style={{
            maxWidth: "25rem",
            fontFamily: theme.typography.fontFamily,
            fontWeight: item?.visible ? 600 : 400,
            color: item?.visible
              ? theme.palette.color.slate[700]
              : theme.palette.color.slate[500],
            fontSize: "0.95rem",
            position: "relative",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textAlign: "left",
          }}
        >
          {item?.immutable ? t(item?.title) : item?.title}
        </span>
        <CategoryAssigned has_transactions={item?.has_transactions} />
        {!item?.visible ? (
          <VisibilityOffIcon
            className={"actionBtnIcon"}
            sx={{
              fontSize: "1.1rem",
              ml: "0.5rem",
              color: `${theme.palette.color.slate[300]} !important`,
            }}
          />
        ) : null}
      </span>

      <span
        style={{
          minWidth: "12rem",
          fontFamily: theme.typography.fontFamily,
          fontWeight: item?.visible ? 600 : 400,
          color: item?.visible
            ? theme.palette.color.slate[700]
            : theme.palette.color.slate[500],
          fontSize: "0.95rem",
          textAlign: "left",
        }}
      >
        {t(Constant.CategoryType[item?.category_type])}
      </span>
    </span>
  );
}

function getParentLevelOfInstruction(instruction) {
  if (instruction.type === "instruction-blocked") {
    return getParentLevelOfInstruction(instruction.desired);
  }
  if (instruction.type === "reparent") {
    return instruction.desiredLevel - 1;
  }
  return instruction.currentLevel - 1;
}

function delay({ waitMs: timeMs, fn }) {
  let timeoutId = window.setTimeout(() => {
    timeoutId = null;
    fn();
  }, timeMs);
  return function cancel() {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
}

const TreeItem = memo(function TreeItem({
  item,
  level,
  updateTree,
  index,
  categoryRef,
  isLoading,
  SavedCategoryInfo,
  anchorElMenu,
  anchorElNote,
  onClickMore,
  isOverlay,
  isPreview,
  hiddenCategory,
  allowParentCategory,
  ruleTitle,
  isSelect,
  preventClose,
  onClickCategoryTitle,
  openNoteOverlay,
  searchText,
  onSetTitle,
}) {
  const buttonRef = useRef(null);
  const dragHandle = useRef(null);

  const [state, setState] = useState("idle");
  const [instruction, setInstruction] = useState(null);
  const cancelExpandRef = useRef(null);

  const { uniqueContextId, getPathToItem, registerTreeItem } =
    useContext(TreeContext);
  const { attachInstruction, extractInstruction } =
    useContext(DependencyContext);
  const toggleOpen = useCallback(
    (e) => {
      e?.stopPropagation();
      if (updateTree) {
        updateTree({ type: "toggle", itemId: item?.uuid });
      }
    },
    [updateTree, item]
  );

  useEffect(() => {
    invariant(buttonRef.current);
    invariant(dragHandle.current);
    // invariant(actionMenuTriggerRef.current);
    return registerTreeItem({
      itemId: item?.uuid,
      element: buttonRef.current,
      dragHandle: dragHandle.current,
      // actionMenuTrigger: actionMenuTriggerRef.current,
    });
  }, [item?.uuid, registerTreeItem]);

  const cancelExpand = useCallback(() => {
    cancelExpandRef.current?.();
    cancelExpandRef.current = null;
  }, []);

  const clearParentOfInstructionState = useCallback(() => {
    setState((current) =>
      current === "parent-of-instruction" ? "idle" : current
    );
  }, []);

  // When an item has an instruction applied
  // we are highlighting it's parent item for improved clarity
  const shouldHighlightParent = useCallback(
    (location) => {
      const target = location.current.dropTargets[0];
      if (!target) {
        return false;
      }

      const instruction = extractInstruction(target.data);

      if (!instruction) {
        return false;
      }

      const targetId = target.data?.uuid;
      invariant(typeof targetId === "string");

      const path = getPathToItem(targetId);
      const parentLevel = getParentLevelOfInstruction(instruction);
      const parentId = path[parentLevel];
      return parentId === item?.uuid;
    },
    [getPathToItem, extractInstruction, item]
  );

  useEffect(() => {
    invariant(buttonRef.current);
    invariant(dragHandle.current);

    function updateIsParentOfInstruction({ location }) {
      if (shouldHighlightParent(location)) {
        setState("parent-of-instruction");
        return;
      }
      clearParentOfInstructionState();
    }

    return combine(
      draggable({
        element: buttonRef.current,
        dragHandle: dragHandle.current,
        getInitialData: () => ({
          uuid: item?.uuid,
          type: "tree-item",
          isOpenOnDragStart: item.expanded,
          children: item?.children?.length,
          depth_level: item?.depth_level,
          item_level: level,
          title: item?.title,
          uniqueContextId,
        }),
        onGenerateDragPreview: ({ location, nativeSetDragImage, source }) => {
          const rect = source.element.getBoundingClientRect();

          setCustomNativeDragPreview({
            getOffset: preserveOffsetOnSource({
              element: buttonRef.current,
              input: location.current.input,
            }),
            // getOffset: pointerOutsideOfPreview({ x: "16px", y: "8px" }),
            render: ({ container }) => {
              const root = createRoot(container);
              root.render(<Preview item={item} rect={rect} />);
              return () => {
                root.unmount();
              };
            },
            nativeSetDragImage,
          });
        },
        onDragStart: ({ source }) => {
          setState("dragging");
          // collapse open items during a drag
          if (source.data.isOpenOnDragStart) {
            updateTree({
              type: "collapse",
              itemId: item?.uuid,
              isDragging: true,
            });
          }
        },
        onDrop: ({ source }) => {
          setState("idle");
          if (source.data.isOpenOnDragStart) {
            updateTree({ type: "expand", itemId: item?.uuid });
          }
        },
      }),
      dropTargetForElements({
        element: buttonRef.current,
        getData: (arg) => {
          const _data = arg?.source?.data;
          const data = { uuid: item?.uuid };
          // console.log(
          //   "🚀 source",
          //   _data?.title,
          //   _data?.depth_level,
          //   "---->>",
          //   item?.title,
          //   level
          // );
          let block = [];
          if (level === 0) {
            if (_data?.depth_level > 2) {
              block.push("make-child");
            }
            if (_data?.depth_level > 3) {
              block.push("reorder-above");
              block.push("reorder-below");
            }
          }
          if (level === 1) {
            if (_data?.depth_level > 1) {
              block.push("make-child");
            }
            if (_data?.depth_level > 2) {
              block.push("reorder-above");
              block.push("reorder-below");
            }
          }
          if (level === 2) {
            block = ["make-child"];
            if (_data?.depth_level > 1) {
              block.push("reorder-above");
              block.push("reorder-below");
            }
          }
          return attachInstruction(data, {
            input: arg.input,
            element: arg.element,
            indentPerLevel: level * indentPerLevel,
            currentLevel: level,
            block: block,
          });
        },
        canDrop: (arg) => {
          return (
            arg?.source.data.type === "tree-item" &&
            arg?.source.data.uniqueContextId === uniqueContextId
          );
        },

        getIsSticky: () => true,
        onDrag: ({ self, source }) => {
          const instruction = extractInstruction(self.data);

          if (source.data?.uuid !== item?.uuid) {
            // expand after 500ms if still merging
            if (
              instruction?.type === "make-child" &&
              item.children.length &&
              !item.expanded &&
              !cancelExpandRef.current
            ) {
              cancelExpandRef.current = delay({
                waitMs: 500,
                fn: () =>
                  updateTree({
                    type: "expand",
                    itemId: item?.uuid,
                    isDragging: true,
                  }),
              });
            }
            if (instruction?.type !== "make-child" && cancelExpandRef.current) {
              cancelExpand();
            }

            setInstruction(instruction);
            return;
          }
          if (instruction?.type === "reparent") {
            setInstruction(instruction);
            return;
          }
          setInstruction(null);
        },
        onDragLeave: () => {
          cancelExpand();
          setInstruction(null);
        },
        onDrop: () => {
          cancelExpand();
          setInstruction(null);
        },
      }),
      monitorForElements({
        canMonitor: ({ source }) =>
          source.data.uniqueContextId === uniqueContextId,
        onDragStart: updateIsParentOfInstruction,
        onDrag: updateIsParentOfInstruction,
        onDrop() {
          clearParentOfInstructionState();
        },
      })
    );
  }, [
    attachInstruction,
    cancelExpand,
    clearParentOfInstructionState,
    extractInstruction,
    item,
    level,
    shouldHighlightParent,
    uniqueContextId,
    updateTree,
  ]);

  useEffect(
    function mount() {
      return function unmount() {
        cancelExpand();
      };
    },
    [cancelExpand]
  );

  const aria = (() => {
    if (!item.children.length) {
      return undefined;
    }
    return {
      "aria-expanded": item.expanded,
      "aria-controls": `tree-item-${item?.uuid}--subtree`,
    };
  })();

  return (
    <Fragment>
      <ItemView
        item={item}
        isOverlay={isOverlay}
        isPreview={isPreview}
        hiddenCategory={hiddenCategory}
        allowParentCategory={allowParentCategory}
        ruleTitle={ruleTitle}
        isSelect={isSelect}
        preventClose={preventClose}
        onClickCategoryTitle={onClickCategoryTitle}
        categoryRef={categoryRef}
        buttonRef={buttonRef}
        dragHandle={dragHandle}
        level={level}
        index={index}
        state={state}
        aria={aria}
        instruction={instruction}
        toggleOpen={toggleOpen}
        isLoading={isLoading}
        SavedCategoryInfo={SavedCategoryInfo}
        anchorElMenu={anchorElMenu}
        anchorElNote={anchorElNote}
        openNoteOverlay={openNoteOverlay}
        onClickMore={onClickMore}
        searchText={searchText}
        onSetTitle={onSetTitle}
      />
      {item.children.length && item.expanded ? (
        <div id={aria?.["aria-controls"]}>
          {item.children.map((child, index) => {
            return (
              <TreeItem
                item={child}
                key={child?.uuid}
                level={level + 1}
                index={index}
                updateTree={updateTree}
                categoryRef={categoryRef}
                isLoading={isLoading}
                SavedCategoryInfo={SavedCategoryInfo}
                anchorElMenu={anchorElMenu}
                anchorElNote={anchorElNote}
                openNoteOverlay={openNoteOverlay}
                onClickMore={onClickMore}
                isOverlay={isOverlay}
                isPreview={isPreview}
                hiddenCategory={hiddenCategory}
                allowParentCategory={allowParentCategory}
                ruleTitle={ruleTitle}
                isSelect={isSelect}
                preventClose={preventClose}
                onClickCategoryTitle={onClickCategoryTitle}
                searchText={searchText}
                onSetTitle={onSetTitle}
              />
            );
          })}
        </div>
      ) : null}
    </Fragment>
  );
});

export default TreeItem;

const CategoryAssigned = ({ has_transactions }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        has_transactions
          ? t("CategoryAssignedTooltip")
          : t("CategoryNotAssignedTooltip")
      }
      placement="top"
    >
      <Button
        id="category_assigned_chip"
        disableElevation
        disableRipple
        color="primary"
        variant="span"
        component="span"
        size="small"
        sx={{
          ml: "0.1rem",
          fontSize: "0.6rem",
          lineHeight: "normal",
          borderRadius: theme.borderRadius.main,
          color: has_transactions
            ? theme.palette.color.slate[500]
            : theme.palette.color.slate[300],
          backgroundColor: has_transactions
            ? theme.palette.color.slate[50]
            : "transparent",
          width: "fit-content",
          minWidth: "unset",
          cursor: "default",
          px: "0.4rem",
          "&:hover": {
            backgroundColor: has_transactions
              ? theme.palette.color.slate[50]
              : "transparent",
          },
        }}
      >
        {has_transactions ? t("CategoryAssigned") : t("CategoryNotAssigned")}
      </Button>
    </Tooltip>
  );
};

const ItemView = ({
  item,
  buttonRef,
  dragHandle,
  categoryRef,
  level,
  index,
  state,
  aria,
  toggleOpen,
  instruction,
  isLoading,
  SavedCategoryInfo,
  openNoteOverlay,
  anchorElMenu,
  anchorElNote,
  onClickMore,
  isOverlay,
  isPreview,
  hiddenCategory,
  allowParentCategory,
  ruleTitle,
  isSelect,
  preventClose,
  onClickCategoryTitle,
  searchText,
  onSetTitle,
}) => {
  const { t } = useTranslation();
  const { DropIndicator } = useContext(DependencyContext);
  const vat = useSelector((state) => state.globalSlice.vat);
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const onClickSetting = useCallback(
    (e) => {
      e.stopPropagation();
      categoryRef.current?.openOverlay({
        selectedItem: item,
        type: item.type,
        modalType: "edit",
      });
    },
    [categoryRef, item]
  );

  const onClickDescription = useCallback(
    (e) => {
      e.stopPropagation();
      openNoteOverlay({
        node: item,
        type: item.type,
        modalType: "edit",
        currentTarget: e.currentTarget,
      });
    },
    [categoryRef, item]
  );

  const isHighlighted =
    searchText &&
    item?.title?.toLowerCase()?.includes(searchText?.toLowerCase());
  let buttons = [];

  if (isOverlay) {
    const disabled =
      (!allowParentCategory && item?.children?.length > 0) ||
      hiddenCategory?.includes(item?.uuid);
    buttons.push(
      <Tooltip
        title={
          disabled
            ? ""
            : isSelect
              ? t("category_action_select")
              : t("category_action_assign")
        }
        followCursor
        placement="top"
      >
        <span>
          <Button
            className={"actionBtnText"}
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              onClickCategoryTitle(e, item, appliedFilterlist);
            }}
            sx={{
              opacity: disabled ? 0.5 : 1,
              cursor: disabled
                ? "not-allowed !important"
                : "pointer !important",
              color: `${theme.palette.color.slate[700]} !important`,
              backgroundColor: `${theme.palette.primary[disabled ? 50 : 100]} !important`,
            }}
          >
            {isSelect ? t("Select") : t("Assign")}
          </Button>
        </span>
      </Tooltip>
    );
  }

  if (level < 2) {
    buttons.push(
      <Tooltip placement="top" title={t("category_action_add_sub_tooltip")}>
        <Button
          className={"actionBtn"}
          onClick={(e) => categoryRef.current?.onClickAddSubCategory(e, item)}
          sx={{
            borderColor: `${theme.palette.primary[400]} !important`,
            width: "fit-content !important",
          }}
        >
          <AddIcon
            className={"actionBtnIcon"}
            sx={{ width: "fit-content", fontSize: "1.3rem" }}
          />
        </Button>
      </Tooltip>
    );
    buttons.push(
      <Tooltip placement="top" title={t("category_action_add_above_tooltip")}>
        <Button
          className={"actionBtn"}
          onClick={(e) => categoryRef.current?.onClickAddAboveCategory(e, item)}
          sx={{
            borderColor: `${theme.palette.primary[400]} !important`,
            width: "fit-content !important",
          }}
        >
          <TbRowInsertTop
            className={"actionBtnIcon"}
            style={{ fontSize: "1.2rem" }}
          />
        </Button>
      </Tooltip>
    );
    buttons.push(
      <Tooltip placement="top" title={t("category_action_add_below_tooltip")}>
        <Button
          className={"actionBtn"}
          onClick={(e) => categoryRef.current?.onClickAddBelowCategory(e, item)}
          sx={{
            borderColor: `${theme.palette.primary[400]} !important`,
            width: "fit-content !important",
          }}
        >
          <TbRowInsertBottom
            className={"actionBtnIcon"}
            style={{ fontSize: "1.2rem" }}
          />
        </Button>
      </Tooltip>
    );
  }
  if (item?.note) {
    buttons.push(
      <Tooltip
        placement="top"
        title={t("category_action_see_description_tooltip")}
      >
        <Button
          className={"actionBtn"}
          onClick={onClickDescription}
          disableRipple
          disableTouchRipple
          disableFocusRipple
          sx={{
            width: "fit-content !important",
          }}
        >
          <ManageSearchIcon
            className={"actionBtnIcon"}
            sx={{ fontSize: "1.2rem", mr: "0.25rem" }}
          />
        </Button>
      </Tooltip>
    );
  }
  buttons.push(
    <Tooltip placement="top" title={t("category_action_edit_tooltip")}>
      <Button
        className={"actionBtnText"}
        onClick={onClickSetting}
        sx={{
          width: "fit-content !important",
        }}
      >
        <GoGear
          fontSize={"1.2rem"}
          style={{
            marginRight: "0.5rem",
          }}
        />
        {t("category_action_edit")}
      </Button>
    </Tooltip>
  );
  buttons.push(
    <Button
      className={"actionBtn"}
      onClick={(e) => onClickMore(e, item)}
      sx={{
        padding: isLoading === item?.uuid ? "0px !important" : "4px",
        display: isLoading === item?.uuid ? "flex !important" : "unset",
        borderColor: `${theme.palette.primary[400]} !important`,
        backgroundColor: "transparent !important",
      }}
    >
      {isLoading === item?.uuid ? (
        <CircularProgress size={18} />
      ) : (
        <MoreVertIcon className={"actionBtnIcon"} />
      )}
    </Button>
  );
  const taxItem = vat?.find((o1) => o1.uuid === item?.tax);
  return (
    <TreeItemWrapper $state={state} style={{ position: "relative" }}>
      <FocusRing isInset>
        <OuterButtonStyled
          {...aria}
          id={`tree-item-${item?.uuid}`}
          onClick={item?.children?.length > 0 ? toggleOpen : undefined}
          ref={buttonRef}
          type="button"
          style={{ paddingLeft: level * indentPerLevel }}
          data-index={index}
          data-level={level}
          data-testid={`tree-item-${item?.uuid}`}
          $anchorElMenu={Boolean(anchorElMenu) || !!anchorElNote}
          $SavedCategoryInfo={SavedCategoryInfo?.current}
          $uuid={item?.uuid}
          $isHighlighted={isHighlighted}
          $isPreview={isPreview}
        >
          <span
            style={{
              padding: "var(--grid)",
              // paddingRight: 40,
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              background: "transparent",
              borderRadius: 3,
              opacity: state === "dragging" ? 0.4 : 1,
            }}
          >
            <Grid
              item
              xs={0.3}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <DragIndicatorOutlinedIcon
                ref={dragHandle}
                className="category_grab_icon"
                sx={{
                  display: "none",
                  fontSize: "1.25rem",
                  zIndex: 1005,
                  cursor: "grab",
                }}
              />
            </Grid>

            <Grid
              item
              xs={0.3}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Icon item={item} isPreview={isPreview} />
            </Grid>

            <Grid
              item
              xs={isPreview || isOverlay ? 1 : 0.5}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <CircleIcon
                sx={{
                  fontSize: isPreview ? "1.1rem" : "1.375rem",
                  color: item?.visible
                    ? item?.color
                    : theme.palette.color.slate[300],
                }}
              />
            </Grid>

            <Grid
              item
              xs={isOverlay ? 7.5 : 4.5}
              sx={{
                display: "flex",
                alignItems: "center",
                height: "1.5rem",
              }}
            >
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InlineEdit
                  item={item}
                  text={item?.immutable ? t(item?.title) : item?.title}
                  readable={isPreview}
                  onSetText={onSetTitle}
                  minWidth={"auto"}
                  maxWidth={"26rem"}
                  height={"fit-content"}
                  readOnly={isPreview || item?.immutable}
                  style={{
                    width: `calc(${Math.ceil((item?.immutable ? t(item?.title) : item?.title)?.replaceAll(" ", "")?.length) + "ch"} + 0.5rem)`,
                  }}
                  textStyle={{
                    fontWeight: item?.visible ? 600 : 400,
                    color: item?.visible
                      ? theme.palette.color.slate[700]
                      : theme.palette.color.slate[500],
                    fontSize: isPreview ? "0.85rem" : "0.95rem",
                    cursor:
                      isPreview || item?.immutable ? "not-allowed" : "pointer",
                  }}
                />

                {isPreview ? (
                  <Tooltip title={item?.note} placement="top">
                    <span
                      style={{
                        maxWidth: "26rem",
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 500,
                        color: theme.palette.color.description,
                        fontSize: "0.75rem",
                        textAlign: "left",
                        marginTop: "0.1rem",
                        marginLeft: "0.55rem",
                        lineHeight: "0.85rem",
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        textOverflow: "ellipsis",
                        maxHeight: "1.5rem",
                      }}
                    >
                      {item?.note}
                    </span>
                  </Tooltip>
                ) : null}
              </span>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!isPreview ? (
                  <CategoryAssigned has_transactions={item?.has_transactions} />
                ) : null}
                {!item?.visible ? (
                  <VisibilityOffIcon
                    className={"actionBtnIcon"}
                    sx={{
                      fontSize: "1.1rem",
                      ml: "0.5rem",
                      color: `${theme.palette.color.slate[300]} !important`,
                    }}
                  />
                ) : null}

                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: theme.palette.color.white,
                    width: "max-content",
                    marginLeft: "0.5rem",
                  }}
                >
                  {buttons?.map((item, index) => (
                    <Fragment key={index}>{item}</Fragment>
                  ))}
                </div>
              </div>
            </Grid>
            {!isOverlay ? (
              <Grid
                item
                xs={3}
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: 500,
                  color: theme.palette.color.description,
                  fontSize: "0.75rem",
                  textAlign: "left",
                  marginTop: "0.1rem",
                  marginLeft: "0.55rem",
                  display: "-webkit-box",
                  lineHeight: "0.85rem",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  textOverflow: "ellipsis",
                  maxHeight: "3.4rem",
                  paddingRight: "0.5rem",
                }}
              >
                <Tooltip title={item?.note} placement="top">
                  <span style={{}}>{item?.note}</span>
                </Tooltip>
              </Grid>
            ) : null}
            {!isOverlay && !isPreview ? (
              <Grid
                item
                xs={1}
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: item?.visible ? 600 : 400,
                  color: item?.visible
                    ? theme.palette.color.slate[700]
                    : theme.palette.color.slate[500],
                  fontSize: "0.95rem",
                  textAlign: "left",
                }}
              >
                {` ${taxItem?.value ? `${taxItem?.value} %` : ""}`}
              </Grid>
            ) : null}
            {!isOverlay || isPreview ? (
              <Grid
                item
                xs={2}
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: item?.visible ? 600 : 400,
                  color: item?.visible
                    ? theme.palette.color.slate[700]
                    : theme.palette.color.slate[500],
                  fontSize: "0.8rem",
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  marginLeft: isPreview ? "0.5rem" : 0,
                }}
              >
                {t(Constant.CategoryType[item?.category_type])}
              </Grid>
            ) : null}
          </span>

          {instruction ? <DropIndicator instruction={instruction} /> : null}
        </OuterButtonStyled>
      </FocusRing>
    </TreeItemWrapper>
  );
};
