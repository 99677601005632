import { IconButton, Backdrop, Stack, Box } from "@mui/material";
import { MdZoomOutMap } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { useMemo, useState } from "react";
import { useTheme } from "@mui/styles";

import CustomModal from "../../../components/Model/CustomModal";
import Icon from "../../../components/Icon";
import Chart from "../Organizations/Chart";

const AccountsChart = ({
  disableRefresh,
  onClickRefresh,
  AllApiParams,
  chart_data,
  currency,
}) => {
  const theme = useTheme();
  const apiParams = useMemo(
    () => AllApiParams?.filter((item) => item?.currency === currency),
    [AllApiParams, currency]
  );
  return (
    <Stack
      sx={{
        display: "flex",
        height: "15rem",
        borderRadius: theme.borderRadius.main,
        backgroundColor: theme.palette.color.white,
        p: "1.5rem",
        pb: chart_data?.isFetching ? "1.5rem" : "1rem",
        mb: "1.5rem",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: theme.boxShadow,
        width: "100%",
      }}
    >
      <Chart
        dataset={{ uuid: "overview", currency }}
        chart_data={chart_data}
        apiParams={apiParams}
        Xaxis={{
          hide: true,
        }}
        Yaxis={{
          hide: true,
        }}
        margin={{
          top: 20,
          bottom: 20,
        }}
        tick={{
          fontSize: "0.6rem",
          fontWeight: 600,
        }}
      />
      {chart_data?.data?.length > 0 &&
      apiParams?.length > 0 &&
      !chart_data?.isFetching ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          sx={{ width: "100%", gap: "0.25rem", mb: "-4px" }}
        >
          <IconButton
            disabled={disableRefresh}
            onClick={onClickRefresh}
            sx={{ background: "transparent", mr: "0.25rem", p: "3px" }}
          >
            <Icon
              tooltip={"Refresh"}
              icon={<IoMdRefresh />}
              color={theme.palette.color.slate[disableRefresh ? 300 : 600]}
              fontSize={"1.7rem"}
            />
          </IconButton>
          <ZoomChart
            chart_data={chart_data}
            currency={currency}
            apiParams={apiParams}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};

export default AccountsChart;

const ZoomChart = ({ chart_data, currency, apiParams }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <IconButton
        onClick={handleOpen}
        sx={{ background: "transparent", p: "3px", mr: "-3px" }}
      >
        <Icon
          tooltip={"Zoom"}
          icon={<MdZoomOutMap />}
          color={theme.palette.color.slate[600]}
          fontSize={"1.4rem"}
        />
      </IconButton>
      <CustomModal
        open={open}
        onClose={handleClose}
        hideAction
        restProps={{
          slots: { backdrop: Backdrop },
          slotProps: {
            backdrop: {
              timeout: 500,
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                backdropFilter: "blur(4px)",
              },
            },
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.color.white,
            p: "1rem",
            width: "80vw",
            height: "65vh",
          }}
        >
          <Chart
            apiParams={apiParams}
            dataset={{ uuid: "overview", currency }}
            chart_data={chart_data}
            margin={{
              top: 30,
              left: 20,
              right: 30,
            }}
            tick={{
              fontSize: "0.9rem",
              fontWeight: 600,
            }}
          />
        </Box>
      </CustomModal>
    </div>
  );
};
