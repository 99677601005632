import { useDispatch, useSelector } from "react-redux";

import HeaderAddOverlay from "../../../../components/Header/DatasetHeader/HeaderAddOverlay";
import { setPopupStatus } from "../../../../store/slices/datasets";
import CustomPopover from "../../../../components/PopOver";

const AddTransactionModal = () => {
  const popupStatus = useSelector((state) => state.datasetSlice?.popupStatus);
  const dispatch = useDispatch();

  const onClosePopOver = () => {
    dispatch(
      setPopupStatus({
        open: false,
        anchorEl: null,
      })
    );
  };

  const popupState = {
    close: onClosePopOver,
  };

  return (
    <CustomPopover
      type="anchor"
      anchorEl={popupStatus?.anchorEl}
      onClose={onClosePopOver}
      showRight
    >
      <HeaderAddOverlay
        popupState={popupState}
        overlayType={popupStatus?.payload?.type}
        date={popupStatus?.payload?.date}
      />
    </CustomPopover>
  );
};

export default AddTransactionModal;
