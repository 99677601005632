import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as React from "react";

import TailwindButton from "../../../components/Overlay/TailwindButton";
import CustomModal from "../../../components/Model/CustomModal";
import Translate from "../../../hooks/HOC/Translate";

const CategoryLevelAttentionOverlay = ({
  open,
  setOpen,
  title,
  message,
  onCloseCallback,
  onAdd,
  loading,
  addText,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //functions
  const onClose = () => {
    setOpen(false);
    if (onCloseCallback) onCloseCallback();
  };

  return (
    <div>
      <CustomModal
        hideAction
        open={!!open}
        onClose={onClose}
        width="100%"
        height="100%"
        PaperPropsSx={{
          minWidth: "65rem",
          maxWidth: "70rem",
          height: "20rem",
        }}
      >
        <div style={{ width: "100%", height: "100%", padding: "2rem" }}>
          <Typography
            variant="div"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "1.2rem",
              lineHeight: "1.5rem",
              fontWeight: "600",
              color: "color.slate.500",
            }}
          >
            <InfoOutlinedIcon
              sx={{
                color: "color.slate.500",
                mr: "1rem",
              }}
            />

            {t(title)}
          </Typography>
          <Typography
            my="1.5rem"
            variant="body1"
            sx={{
              fontSize: "1rem",
              color: "color.description",
            }}
          >
            <Translate i18nkey={message} />
          </Typography>

          <Box
            sx={{
              bottom: "1.5rem",
              position: "absolute",
              mt: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "95%",
            }}
          >
            <TailwindButton
              type="cancel"
              text={t("Cancel")}
              onClick={onClose}
              sx={{
                ml: 0,
                borderRadius: 50,
              }}
            />
            <TailwindButton
              text={addText ? t(addText) : t("Ok")}
              onClick={onAdd}
              loading={loading}
              sx={{
                ml: 0,
                borderRadius: 50,
              }}
            />
          </Box>
        </div>
      </CustomModal>
    </div>
  );
};

export default CategoryLevelAttentionOverlay;
