import {
  ClickAwayListener,
  Tooltip,
  Popper,
  Paper,
  Fade,
  Box,
} from "@mui/material";
import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";

import {
  DataSourceCell,
  GrossValueCell,
  TitleCell,
  DateCell,
} from "../../../../components/MuiTableCells";
import ComponentLoader from "../../../../components/ComponentLoader";
import { getTransactionByParams } from "../../../../Helper/data";
import CategoryChip from "../../../../components/CategoryChip";
import { Color, Constant, Fonts } from "../../../../Helper";
import StateChip from "../../../../components/StateChip";
import QuickLinkView from "./Component/QuickLinkView";
import EmptyView from "./Component/EmptyView";

const LatestTransactionsWidget = ({ widgetType, chartType }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isBank = widgetType === "latest-bank-transactions";

  const columns = useMemo(
    () => [
      {
        field: "due_date",
        headerName: t("Due Date"),
        flex: 0.7,
        editable: false,
        type: "date",
        renderCell: (params) => <DateCell theme={theme} value={params.value} />,
      },
      {
        field: "title",
        headerName: t("Title"),
        flex: 1.4,
        editable: false,
        renderCell: (params) => (
          <TitleCell
            theme={theme}
            row={params.row}
            t={t}
            value={params.value}
            sx={{ textAlign: "left" }}
          />
        ),
      },
      {
        field: "data_source",
        headerName: isBank ? t("Bank") : t("Data_Source"),
        flex: 0.8,
        editable: false,

        renderCell: (params) => (
          <DataSourceCell theme={theme} row={params.row} t={t} />
        ),
      },
      {
        field: "gross_value",
        headerName: t("Amount"),
        flex: 0.9,
        editable: false,
        type: "float",
        valueGetter: ({ value }) => {
          return value ? Number(value || 0) : null;
        },
        renderCell: (params) => <GrossValueCell {...params} theme={theme} />,
      },
      {
        field: "state",
        headerName: t("State"),
        flex: 0.85,
        editable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <StateChip
              title={params?.value}
              income_expense_type={params?.row?.income_expense_type}
              hideBorder
              hideIcon
              showClickCursor
              width="6rem"
              height="1.4rem"
              fontSize="0.6rem"
            />
          );
        },
      },
      {
        field: "category",
        headerName: t("category"),
        flex: 1,
        editable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <CategoryChip
              categoryId={params.value}
              width={"100%"}
              showTooltip
              noCategoryLabel={"Uncategorized"}
              noCategoryLabel_tooltip={"Uncategorized"}
              fontSize="0.6rem"
            />
          );
        },
      },
    ],
    [isBank, t, theme]
  );

  const info = useMemo(() => {
    let link_state = [];
    let expanded_link_state = [];
    let moreState = [];
    let state_color = "slate";
    if (widgetType === "latest-invoice-transactions") {
      link_state = ["Late", "Invoice open", "Others"];
      expanded_link_state = [
        "",
        "Invoice draft",
        "Invoice sent",
        "Open",
        "Sales invoice",
        "Purchase invoice",
        "Invoice paid",
        "Partially paid",
        "All",
        "Late",
        "Invoice open",
        "Close",
      ];
      moreState = Constant.invoiceState;
      state_color = Constant?.defaultState?.["Invoice open"]?.color || "slate";
    } else if (widgetType === "latest-offer-transactions") {
      link_state = ["Offer draft", "Offer sent", "Others"];
      expanded_link_state = [
        "Offer accepted",
        "Offer partially calculated",
        "Offer calculated",
        "Offer archived",
        "Offer rejected",
        "All",
        "Offer draft",
        "Offer sent",
        "Close",
      ];
      moreState = Constant.offerState;
      state_color = Constant?.defaultState?.["Offer draft"]?.color || "slate";
    } else if (widgetType === "latest-lead-transactions") {
      link_state = [...Constant.leadState, "All"];
      expanded_link_state = [];
      moreState = Constant.leadState;
      state_color = Constant?.defaultState?.["Lead - won"]?.color || "slate";
    } else {
      link_state = ["Pending", "Booked", "Others"];
      expanded_link_state = [
        "",
        "",
        "Sales credit note",
        "Purchase credit note",
        "Undefined",
        "All",
        "Pending",
        "Booked",
        "Close",
      ];
      moreState = Constant.bookedPositionsStates;
      state_color = Constant?.defaultState?.["Booked"]?.color || "slate";
    }
    return {
      link_state,
      expanded_link_state,
      moreState,
      state_color,
    };
  }, [widgetType]);

  //state
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const getRowClassName = useCallback((params) => {
    if (params?.row?.income_expense_type === 1) {
      return "latest-transactions-inflow-row";
    } else if (params?.row?.income_expense_type === 2) {
      return "latest-transactions-outflow-row";
    } else {
      return "";
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingTop: "0.5rem",
        position: "relative",
      }}
    >
      <LogicFunctions
        setData={setData}
        setIsFetching={setIsFetching}
        widgetType={widgetType}
        chartType={chartType}
        isBank={isBank}
      />
      {isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data?.length > 0 ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            "& .latest-transactions-inflow-row": {
              color: Color.tailwind.green[500],
            },
            "& .latest-transactions-outflow-row": {
              color: Color.tailwind.red[500],
            },
            "& .latest-widget-cell-text": {
              fontSize: "0.7rem",
            },
          }}
        >
          <DataGrid
            disableColumnMenu
            disableSelectionOnClick
            density="compact"
            autoHeight
            pageSize={5}
            rows={data}
            columns={data?.length > 0 ? columns?.slice(0) : []}
            getRowId={(row) => row?.uuid}
            getRowClassName={getRowClassName}
            sx={{
              "&, [class^=MuiDataGrid]": { border: "none" },
              "&, [class^=MuiDataGrid-cell]": {
                px: "4px",
              },
              "&, [role^=columnheader]": {
                px: "6px",
              },
              "&, [class^=MuiDataGrid-columnHeaderTitle]": {
                fontSize: "0.8rem",
                fontWeight: 600,
                fontFamily: Fonts.Text,
              },
            }}
          />

          <QuickLinks
            rawData={data}
            info={info}
            isDetailedTransactions
            page_size={15}
            source={widgetType === "latest-bank-transactions" ? 2 : undefined}
          />
        </Box>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </div>
  );
};

export default LatestTransactionsWidget;

const LogicFunctions = ({ setData, setIsFetching, widgetType, chartType }) => {
  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);

  const multiStatesIds = useMemo(() => {
    let array = [];
    let all_state = [];
    if (widgetType === "latest-invoice-transactions") {
      all_state = Constant.invoiceState;
    }
    if (widgetType === "latest-offer-transactions") {
      all_state = Constant.offerState;
    }
    if (widgetType === "latest-lead-transactions") {
      all_state = Constant.leadState;
    }
    if (widgetType === "latest-bank-transactions") {
      all_state = Constant.bookedPositionsStates;
    }
    all_state.forEach((item) => {
      if (stateByTitle?.[item]?.[0]?.uuid) {
        array.push(stateByTitle?.[item]?.[0]?.uuid);
      }
    });
    return array;
  }, [stateByTitle, widgetType]);

  const Latest_Transactions = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        page: 1,
        page_size: 15,
        apiType: widgetType,
        multiStatesIds,
        chartType: chartType,
      },
    ],
    queryFn: ({ signal }) => {
      let params = {
        config: {
          signal: signal,
        },
        dataset: dataSetData?.uuid,
        page: 1,
        page_size: 15,
        order_by: "-due_date",
        is_reconciled: false,
        state: multiStatesIds,
      };
      if (widgetType === "latest-bank-transactions") {
        params.source = 2;
      } else {
        // params.source = 4;
      }
      if (chartType === "Inflow") {
        params.income_expense_type = 1;
      }
      if (chartType === "Outflow") {
        params.income_expense_type = 2;
      }

      if (!dataSetData.use_global_categories) {
        params.category_dataset = dataSetData?.uuid;
      } else {
        params.global_category = true;
      }
      const result = getTransactionByParams(params);
      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled: !!dataSetData?.uuid && !!chartType,
    priority: 3,
  });

  //lifeCycle method
  useEffect(() => {
    setIsFetching(Latest_Transactions?.isFetching);
  }, [Latest_Transactions?.isFetching]);

  useEffect(() => {
    if (!Latest_Transactions?.isFetching && Latest_Transactions?.data) {
      setIsFetching(true);
      const latestTransactions = Latest_Transactions?.data?.results || [];
      setTimeout(() => {
        setIsFetching(false);
        setData(latestTransactions);
      }, 250);
    }
  }, [Latest_Transactions?.data, Latest_Transactions?.isFetching]);

  return null;
};

const QuickLinks = ({
  rawData,
  info,
  isDetailedTransactions,
  page_size,
  source,
}) => {
  const overlayRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  //functions
  const onClickOthers = () => setAnchorEl(overlayRef.current);

  const onClickAway = () => setAnchorEl(null);

  return (
    <Box
      ref={overlayRef}
      sx={{
        position: "absolute",
        bottom: 0,
        right: 0,
        alignSelf: "flex-end",
        "& .base-Popper-root": {
          transform: "translate3d(16px, 32px, 0px) !important",
          zIndex: 1,
        },
      }}
    >
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={"top-end"}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                elevation={1}
                sx={{
                  width: "32rem",
                  pt: "1rem",
                  px: "1rem",
                  mb: "1rem",
                  display: "flex",
                  flexWrap: "wrap",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
              >
                {info?.expanded_link_state?.map((item) => (
                  <QuickLinkView
                    key={item}
                    title={item}
                    tooltip={
                      item === "Invoice paid"
                        ? `invoice-status-widget-link-invoice-paid-tooltip`
                        : null
                    }
                    state_color={info?.state_color}
                    moreState={info?.moreState}
                    data={rawData}
                    onClickClose={onClickAway}
                    isDetailedTransactions={isDetailedTransactions}
                    page_size={page_size}
                    source={source}
                    sx={{
                      mb: "1rem",
                    }}
                  />
                ))}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      {info?.link_state?.map((item) => (
        <QuickLinkView
          key={item}
          tooltip={
            item === "Invoice paid"
              ? `invoice-status-widget-link-invoice-paid-tooltip`
              : null
          }
          title={item}
          onClickOthers={onClickOthers}
          moreState={info?.moreState}
          data={rawData}
          isDetailedTransactions={isDetailedTransactions}
          page_size={page_size}
          source={source}
        />
      ))}
    </Box>
  );
};
