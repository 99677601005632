import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Tooltip, useTheme } from "@mui/material";
import Popover from "@mui/material/Popover";
import * as React from "react";

import Translate from "../../hooks/HOC/Translate";

export default function CustomPopoverScratch({
  children,
  button,
  tooltip = "",
  disabled,
  height = "fit-content",
  width = "fit-content",
  PaperPropsSx,
  sx,
  popupProps = {},
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "right",
  },
}) {
  const theme = useTheme();
  const payload = React.useRef({ anchorOrigin, transformOrigin });
  const triggerRef = React.useRef(null);

  //state
  const calculatePosition = (e, popup_state) => {
    if (triggerRef.current && !disabled) {
      const rect = triggerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      // Dynamic positioning logic
      const vertical = rect.top > windowHeight / 2 ? "top" : "bottom";
      const horizontal = rect.left > windowWidth / 2 ? "right" : "left";
      payload.current = {
        anchorOrigin: {
          vertical,
          horizontal,
        },
        transformOrigin: {
          vertical: vertical === "top" ? "bottom" : "top",
          horizontal,
        },
      };
      popup_state?.open(e?.currentTarget);
    }
  };

  return (
    <PopupState variant="popper">
      {(popup_state) => (
        <>
          <Tooltip
            placement="top"
            title={tooltip ? <Translate i18nkey={tooltip} /> : ""}
          >
            <div
              ref={triggerRef}
              {...bindTrigger(popup_state)}
              onClick={
                disabled ? undefined : (e) => calculatePosition(e, popup_state)
              }
              style={{
                width: "fit-content",
                height: "fit-content",
                display: "flex",
                alignItems: "center",
              }}
            >
              {React?.cloneElement(button, { popup_state })}
            </div>
          </Tooltip>
          <Popover
            {...bindPopover(popup_state)}
            anchorOrigin={payload.current.anchorOrigin}
            transformOrigin={payload.current.transformOrigin}
            container={document.body}
            slotProps={{
              paper: {
                sx: {
                  mt: "0.5rem",
                  p: "0.25rem",
                  mx: 0,
                  boxShadow: theme.boxShadow,
                  borderRadius: theme.borderRadius.borderRadiusXL2,
                  overflow: "hidden",
                  ...PaperPropsSx,
                },
              },
            }}
            {...popupProps}
            sx={sx}
          >
            <div
              style={{
                position: "relative",
                borderRadius: theme.borderRadius.borderRadiusXL,
                outline: `2px solid ${theme.palette.color.grey[300]}`,
                height,
                width,
                overflow: "hidden",
              }}
            >
              {React?.cloneElement(children, { popup_state })}
            </div>
          </Popover>
        </>
      )}
    </PopupState>
  );
}
