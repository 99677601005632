import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useTranslation } from "react-i18next";
import { Box, Paper } from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import CategoryTreeSelect from "../../CategoryTreeSelect";
import { cloneDeep } from "../../../../Helper/data";
import CategoryChip from "../../../CategoryChip";
import FilterBaseView from "../FilterBaseView";
import theme from "../../../../theme";

const CategoryFilterView = ({
  heading = "Category_Filter",
  tooltip = "tooltip_Category_Filter",
  categoryType = 3,
  defaultType,
  selectedIds = [],
  onClickReset,
  onOpen,
  onClose,
  onChangeValue,
  hideHeading,
  maxWidth = "fit-content",
  isFilterOpen,
  isMultiple,
  hideUncategorize = false,
}) => {
  const { t } = useTranslation();
  const _selectionCategories = useSelector(
    (state) => state.categorySlice.selectionCategories
  );
  const selectionCategories = useMemo(
    () => [
      {
        uuid: "unCategorized_category",
        title: "unCategorized_category",
        immutable: true,
        color: "slate",
      },
      ..._selectionCategories,
    ],
    [_selectionCategories]
  );

  //state
  const [anchorEl, setAnchorEl] = useState(null);

  // const isActive = selectedIds?.length > 0;

  //function
  const onClickCategoryTitle = (e, item) => {
    let data = selectedIds ? cloneDeep(selectedIds) : [];

    const find = selectedIds?.filter((o1) => o1 === item?.uuid);
    if (find?.length > 0) {
      data = data?.filter((o1) => o1 !== item?.uuid);
    } else {
      data.push(item?.uuid);
    }
    onChangeValue(data);
  };

  const onDeleteCategory = (item) => {
    let data = selectedIds ? cloneDeep(selectedIds) : [];
    data = data?.filter((o1) => o1 !== item?.uuid);
    onChangeValue(data);
  };

  const onOpenDropDown = (e) => {
    e.stopPropagation();
    if (isFilterOpen) {
      isFilterOpen.current = true;
    }
    setAnchorEl(e.currentTarget);
    if (onOpen) onOpen();
  };

  const onCloseDropDown = (e) => {
    if (isFilterOpen) {
      isFilterOpen.current = false;
    }
    setAnchorEl(null);
    if (onClose) onClose();
  };

  const Filtered = useMemo(
    () => selectionCategories?.filter((o1) => selectedIds?.includes(o1?.uuid)),
    [selectedIds, selectionCategories]
  );

  const allView = useMemo(() => {
    if (selectedIds?.length > 0 && Filtered?.length > 0) {
      return Filtered?.map((item, index) => {
        return (
          <CategoryChip
            key={item?.uuid}
            categoryId={
              item?.uuid === "unCategorized_category" ? null : item?.uuid
            }
            noCategoryLabel={item?.title}
            hideNoCategoryColor
            noCategoryLabel_tooltip={item?.title}
            count={50}
            height="1.6rem"
            backgroundShade={200}
            borderWidth={"1px"}
            borderShade={300}
            showDelete
            borderRadius={theme.borderRadius.borderRadiusXL}
            onDelete={() => onDeleteCategory(item)}
            style={{
              marginRight: index !== Filtered.length - 1 ? "0.25rem" : 0,
            }}
          />
        );
      });
    }
    return "";
  }, [Filtered, selectedIds]);

  const VALUE = useMemo(() => {
    if (isMultiple && selectedIds?.length > 1) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "4px",
            borderRadius: theme.borderRadius.borderRadiusXL,
            paddingInline: "8px",
            paddingBlock: "4px",
            backgroundColor: theme.palette.color.slate[200],
            color: theme.palette.color.slate[600],
          }}
        >
          {t("Multiple")}
          <Box
            sx={{
              fontSize: "0.8rem",
              borderRadius: theme.borderRadius.main,
              backgroundColor: theme.palette.color.slate[300],
              height: "1.25rem",
              width: "1.25rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ml: "0.5rem",
            }}
          >
            {selectedIds?.length || 0}
          </Box>
        </div>
      );
    } else {
      return allView;
    }
  }, [allView, isMultiple, selectedIds, t]);

  return (
    <>
      <CategoryTreeSelect
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        type={categoryType}
        defaultType={defaultType}
        handleClosePopOver={onCloseDropDown}
        onClickCategoryTitle={onClickCategoryTitle}
        allowParentCategory
        isSelect
        hideUncategorize={hideUncategorize}
        defaultShowCategory={selectedIds}
      />
      <FilterBaseView
        icon={<LocalOfferIcon style={{ fontSize: "1rem", marginTop: "2px" }} />}
        heading={hideHeading ? null : heading}
        tooltip={tooltip}
        maxWidth={maxWidth}
        // isActive={isActive}
        isActive={false}
        value={VALUE}
        allValue={
          isMultiple && VALUE && selectedIds?.length > 1 ? (
            <Paper
              key={selectedIds?.length?.toString()}
              elevation={1}
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                height: "fit-content",
                gap: "0.5rem",
                p: "0.5rem",
                borderRadius: "4px",
              }}
            >
              {allView}
            </Paper>
          ) : null
        }
        tooltipSx={{
          padding: isMultiple && selectedIds?.length > 1 ? 0 : "0.5rem",
        }}
        onClick={onOpenDropDown}
        onClear={onClickReset}
      />
    </>
  );
};
export default CategoryFilterView;
