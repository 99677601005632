import {
  startOfQuarter,
  startOfMonth,
  startOfWeek,
  subQuarters,
  endOfMonth,
  subMonths,
  subWeeks,
} from "date-fns";

import { getVatPayMonths } from "./data";
import { Constant } from "./Constant";
import store from "../store";

export const getContactQueryKey = (data) => {
  return [
    "contact",
    {
      dataset: data?.dataset,
      page: data?.page,
      type: data?.type,
      search: data?.search || "",
    },
  ];
};

export const getPlanningChartQueryKey = (data) => {
  let _last_month_date = null;
  if (data?.tableType === "quarterly") {
    _last_month_date = new Date(
      startOfQuarter(subQuarters(new Date(), Constant.start_column_skip_count))
    );
  } else if (data?.tableType === "weekly") {
    _last_month_date = new Date(
      startOfWeek(subWeeks(new Date(), Constant.start_column_skip_count), {
        weekStartsOn: 1,
      })
    );
  } else {
    _last_month_date = new Date(
      startOfMonth(subMonths(new Date(), Constant.start_column_skip_count))
    );
  }

  let from_payment_date = new Date(data?.start_date);
  let to_payment_date = new Date(data?.end_date);

  if (from_payment_date > _last_month_date) {
    from_payment_date = _last_month_date;
  }
  const vat_pay_months = getVatPayMonths({
    default_vat_pay_months: data?.default_vat_pay_months,
  });
  return [
    "transactions",
    {
      dataset: data?.dataset,
      apiType: data?.apiType,
      tableType: data?.tableType,
      from_date: from_payment_date,
      to_date: to_payment_date,
      subMonthNumber: vat_pay_months,
    },
  ];
};

export const pastTableQueryKey = (data) => {
  const state = store?.getState()?.globalSlice?.state;
  const from_payment_date = startOfMonth(
    subMonths(new Date(), Constant.Past_Month)
  );
  const to_payment_date = endOfMonth(subMonths(new Date(data?.start_date), 1));
  // const to_payment_date = endOfMonth(subMonths(new Date(data?.start_date), 7));
  const multiStatesIds = state
    ?.filter((o1) => !Constant.calculationExcludeStates2.includes(o1?.title))
    ?.map((o1) => o1?.uuid);
  return [
    "transactions",
    {
      dataset: data?.dataset,
      apiType: data?.tableType, //monthly
      parentApiType: data?.parentApiType,
      from_payment_date,
      to_payment_date,
      multiStatesIds,
    },
  ];
};

export const tableQueryKey = (data) => {
  const dataSetData = store?.getState()?.boardSlice?.dataSetData;

  return [
    "transactions",
    {
      apiType: data?.tableType, //monthly
      multiScenarioIds: data?.multiScenarioIds,
      dataset: dataSetData?.uuid,
      from_payment_date: dataSetData?.start_date,
      to_payment_date: dataSetData?.end_date,
    },
  ];
};
