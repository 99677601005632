import {
  IconButton,
  Typography,
  useTheme,
  Tooltip,
  Button,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import {
  eachMonthOfInterval,
  startOfMonth,
  endOfMonth,
  format,
} from "date-fns";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdMotionPhotosPaused } from "react-icons/md";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { v4 as v4uuid } from "uuid";

import {
  calculateLoanTenure,
  calculateEndDate,
} from "../../../Helper/functions";
import DescriptionInput from "../../Overlay/DescriptionInput";
import DateInputPicker from "../../Overlay/DateInputPicker";
import TailwindButton from "../../Overlay/TailwindButton";
import { formatDateToLocal } from "../../../Helper/data";
import EndPoints from "../../../APICall/EndPoints";
import { SpinLoader } from "../../ComponentLoader";
import TitleInput from "../../Overlay/TitleInput";
import MenuView from "../../Overlay/MenuView";
import CustomPopover from "../../PopOver";
import APICall from "../../../APICall";
import theme from "./../../../theme";
import Icon from "../../Icon";

const menuOptions = [
  {
    value: 1,
    label: "Edit",
    icon: <ModeEditIcon />,
  },

  {
    value: 2,
    label: "Delete",
    icon: <DeleteIcon />,
  },
];
const PauseDurationsView = ({
  modalType,
  recurring_Obj,
  setRecurring_Obj,
  setIsRecurrenceUpdated,
  PausedDuration,
  PausedDurationItemList,
  VariableRatesItemList,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const menuItem = useRef({});
  const formData = useRef({ defaultData: {}, formType: "add" });

  //state
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [list, setList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);

  //api
  const getPauseDurationList = async (id) => {
    setIsListLoading(true);
    await APICall(
      "get",
      `${EndPoints.recurring_rules}${id}/pause_durations/`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        setList(response.data.results);
        setIsListLoading(false);
      }
    });
  };

  const deletePauseDurationById = async (uuid) => {
    let data = null;
    await APICall(
      "delete",
      `${EndPoints.recurring_rules}${recurring_Obj?.uuid}/pause_durations/${uuid}/`
    ).then((response) => {
      if (response.status === 204) {
        removeItem(uuid);
      }
    });

    return data;
  };

  const expected_end_date = useMemo(() => {
    if (recurring_Obj?.loan_calculation_method === 2) {
      return calculateEndDate({
        months: recurring_Obj?.payment_default,
        start_date: recurring_Obj?.start_date,
        extraMonth: PausedDuration?.current?.length || 0,
      });
    } else {
      const LoanTenure = calculateLoanTenure({
        principal: Math.abs(recurring_Obj?.value || 0),
        downPayment: Math.abs(recurring_Obj?.deposit_value || 0),
        interest_rate: Math.abs(recurring_Obj?.interest_rate || 0),
        interest_rate_type: recurring_Obj?.interest_rate_type,
        emi: Math.abs(recurring_Obj?.average_value || 0),
        VariableRatesItemList: VariableRatesItemList?.current,
        start_date: recurring_Obj?.start_date,
      });

      return calculateEndDate({
        months: LoanTenure,
        start_date: recurring_Obj?.start_date,
        extraMonth: PausedDuration?.current?.length || 0,
      });
    }
  }, [
    PausedDuration,
    VariableRatesItemList,
    recurring_Obj?.average_value,
    recurring_Obj?.deposit_value,
    recurring_Obj?.interest_rate,
    recurring_Obj?.loan_calculation_method,
    recurring_Obj?.payment_default,
    recurring_Obj?.start_date,
    recurring_Obj?.value,
  ]);

  //lifecycle
  useEffect(() => {
    if (recurring_Obj?.uuid && modalType === "edit") {
      getPauseDurationList(recurring_Obj?.uuid);
    }
  }, [modalType, recurring_Obj?.uuid]);

  useEffect(() => {
    if (PausedDuration) {
      PausedDurationItemList.current = list;
      let allDates = [];
      list?.forEach((item) => {
        const dateArray = eachMonthOfInterval({
          start: new Date(item?.from_date),
          end: new Date(item?.to_date),
        });
        dateArray?.forEach((date) => {
          const formattedDate = format(date, "yyyy-MM");
          if (!allDates?.includes(formattedDate)) {
            allDates.push(formattedDate);
          }
        });
      });
      PausedDuration.current = allDates;
      setRecurring_Obj((prev) => ({
        ...prev,
      }));
    }
  }, [list]);

  //functions
  const onClickAdd = (e) => {
    formData.current.defaultData = {
      title: `${t("loan_pause_duration_default_title")} ${list?.length + 1}`,
      uuid: v4uuid(),
    };
    formData.current.formType = "add";
    setAnchorEl(e.currentTarget);
  };

  const onClickMenu = ({ e, setLoading, item }) => {
    menuItem.current = {
      setLoading,
      item,
    };
    setAnchorElMenu(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorElMenu(null);
  };

  const onClickMenuItem = async (e, _value) => {
    const { item, setLoading } = menuItem.current;

    if (_value === 1) {
      formData.current.formType = "edit";
      formData.current.defaultData = item;
      setAnchorEl(e.currentTarget);
    }
    if (_value === 2) {
      closeMenu();
      setLoading(item?.uuid);
      if (modalType === "edit") {
        setIsRecurrenceUpdated(true);
        await deletePauseDurationById(item?.uuid);
      } else {
        removeItem(item?.uuid);
      }
    }
  };

  const removeItem = (uuid) => {
    // updateRecurrenceFlag();
    setList((prev) => prev?.filter((o1) => o1?.uuid !== uuid));
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  return (
    <Stack sx={{ width: "100%", mb: "1rem", ml: "1.5rem", pl: "0.5rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Icon
          icon={<MdMotionPhotosPaused />}
          color={theme.palette.color.blueGrey[700]}
          fontSize={"1.4rem"}
          style={{
            position: "absolute",
            left: "-3.25rem",
          }}
        />
        <Typography
          variant="subtitle1"
          fontWeight={"fontWeightMediumBold"}
          //   ml="0.75rem"
          color="color.mainTitle"
          sx={{
            fontSize: "0.9rem",
          }}
        >
          {t("loan_form_pause_duration")}
        </Typography>
        <Tooltip title={t("loan_form_pause_duration_info_tooltip")} placement="top"  >
          <HelpOutlineOutlinedIcon
            sx={{
              fontSize: "1rem",
              cursor: "pointer",
              ml: "0.5rem",
              mt: "1px",
              color: theme.palette.primary.main,
            }}
          />
        </Tooltip>
      </div>

      <Grid
        container
        columnsSpacing={6}
        sx={{
          mt: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {list?.map((item) => (
          <ItemView key={item?.uuid} item={item} onClickMenu={onClickMenu} />
        ))}
      </Grid>

      {Boolean(anchorElMenu) ? (
        <MenuView
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          options={menuOptions}
          handleClose={closeMenu}
          onClickItem={onClickMenuItem}
        />
      ) : null}
      <CustomPopover
        type="anchor"
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        BaseButton={
          <Button
            disabled={
              isListLoading || !recurring_Obj?.start_date || !expected_end_date
            }
            onClick={onClickAdd}
            size="medium"
            fontWeight={"fontWeightMediumBold"}
            sx={{
              width: "fit-content",
              height: "fit-content",
              mt: "1.5rem",
              fontSize: "0.85rem",
              color: theme.palette.color.slate[700],
              backgroundColor: theme.palette.color.slate[100],
              "&:hover": {
                backgroundColor: theme.palette.color.slate[200],
              },
            }}
          >
            {t("loan_form_add_pause_duration")}
          </Button>
        }
      >
        <FormOverlay
          recurring_Obj={recurring_Obj}
          setList={setList}
          defaultData={formData?.current?.defaultData}
          formType={formData?.current?.formType}
          modalType={modalType}
          menuItem={menuItem}
          onClosePopOver={onClosePopOver}
          expected_end_date={expected_end_date}
          setIsRecurrenceUpdated={setIsRecurrenceUpdated}
        />
      </CustomPopover>
    </Stack>
  );
};

export default memo(PauseDurationsView);

const FormOverlay = ({
  modalType,
  formType,
  menuItem,
  defaultData,
  recurring_Obj,
  setList,
  onClosePopOver,
  setIsRecurrenceUpdated,
  expected_end_date,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [item, setItem] = useState({
    uuid: defaultData?.uuid,
    title: defaultData?.title,
    note: defaultData?.note,
    from_date: defaultData?.from_date,
    to_date: defaultData?.to_date,
  });

  //api
  const addPauseDuration = async (recurring_uuid, obj) => {
    setIsLoading(true);
    await APICall(
      "post",
      `${EndPoints.recurring_rules}${recurring_uuid}/pause_durations/`,
      obj
    ).then((response) => {
      if (response.status === 201 && response.data) {
        setIsLoading(false);
        onClosePopOver();
        setList((prev) => [...prev, response.data]);
      }
    });
  };

  const updatePauseDuration = async (recurring_uuid, pause_uuid, obj) => {
    setIsLoading(true);
    await APICall(
      "patch",
      `${EndPoints.recurring_rules}${recurring_uuid}/pause_durations/${pause_uuid}/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response.data) {
        menuItem.current = { ...menuItem.current, item: response.data };
        setIsLoading(false);
        onClosePopOver();
        setList((prev) =>
          prev.map((o1) =>
            o1?.uuid === item?.uuid ? { ...response.data } : o1
          )
        );
      }
    });
  };

  //functions
  const onChange = (e) => {
    const { name, value } = e.target;
    setError({});
    setItem((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeRangeDate = (value, name) => {
    if (value?.toString() !== "Invalid Date") {
      let obj = {};
      if (name === "from_date") {
        obj.from_date = format(startOfMonth(new Date(value)), "yyyy-MM-dd");
        obj.to_date = null;
      } else {
        obj.to_date = format(endOfMonth(new Date(value)), "yyyy-MM-dd");
      }

      setError({});
      setItem((prev) => ({
        ...prev,
        ...obj,
      }));
    }
  };

  const onClickSave = () => {
    let errorText = "";
    let name = "";
    if (!item?.to_date) {
      name = "to_date";
      errorText = t("loan_issue_please_add_pause_duration_to_date");
    }
    if (!item?.from_date) {
      name = "from_date";
      errorText = t("loan_issue_please_add_pause_duration_from_date");
    }
    if (!item?.title?.trim("")) {
      name = "title";
      errorText = t("loan_issue_please_add_pause_duration_title");
    }
    if (errorText) {
      setError((prev) => ({ ...prev, [name]: errorText }));
      return;
    }
    if (modalType === "edit") {
      setIsRecurrenceUpdated(true);

      if (formType === "add") {
        addPauseDuration(recurring_Obj?.uuid, item);
      } else {
        updatePauseDuration(recurring_Obj?.uuid, item?.uuid, item);
      }
    } else {
      onClosePopOver();
      if (formType === "add") {
        setList((prev) => [...prev, item]);
      } else {
        menuItem.current = { ...menuItem.current, item };
        setList((prev) =>
          prev.map((o1) => (o1?.uuid === item?.uuid ? { ...item } : o1))
        );
      }
    }
  };

  return (
    <Box
      sx={{
        width: "30rem",
        minHeight: "17rem",
        position: "relative",
        p: "1.5rem",
        borderRadius: theme.borderRadius.main,
      }}
    >
      <TitleInput
        id="pause-duration-title"
        value={item?.title}
        label={t("Title")}
        tooltip={t("Title")}
        name="title"
        onChange={onChange}
        helperText={error?.["title"]}
        error={Boolean(error?.["title"])}
        hideTitle
        likeGoogle
        variant="filled"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: theme.palette.color.slate[50],
          },
        }}
      />

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <DateInputPicker
          name="from_date"
          label={t("loan_form_pause_duration_from_date")}
          tooltip={t("loan_form_pause_duration_from_date_tooltip")}
          minDate={new Date(recurring_Obj?.start_date)}
          maxDate={expected_end_date ? new Date(expected_end_date) : null}
          error={error?.["from_date"]}
          helperText={error?.["from_date"]}
          value={item?.from_date}
          onChange={onChangeRangeDate}
          variant="filled"
          hidePickerBlurEffect
          likeGoogle
          hideTitle
          fontSize="0.9rem"
          sx={{ width: "48%" }}
        />
        <DateInputPicker
          name="to_date"
          label={t("loan_form_pause_duration_to_date")}
          tooltip={t("loan_form_pause_duration_to_date_tooltip")}
          minDate={new Date(item?.from_date)}
          maxDate={expected_end_date ? new Date(expected_end_date) : null}
          error={error?.["to_date"]}
          helperText={error?.["to_date"]}
          value={item?.to_date}
          disabled={!item?.from_date}
          onChange={onChangeRangeDate}
          variant="filled"
          hidePickerBlurEffect
          likeGoogle
          hideTitle
          fontSize="0.9rem"
          sx={{ width: "48%" }}
        />
      </Stack>
      <DescriptionInput
        maxRows={3}
        value={item?.note}
        onChange={onChange}
        name="note"
        label={t("Note")}
        hideTitle
        likeGoogle
        variant="filled"
      />
      <Stack
        direction="row"
        sx={{ width: "100%", justifyContent: "flex-end", mt: "2rem" }}
      >
        <TailwindButton
          loading={isLoading}
          text={formType === "edit" ? t("Update") : t("Add")}
          onClick={onClickSave}
        />
      </Stack>
    </Box>
  );
};

const ItemView = ({ item, onClickMenu }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      sx={{
        minHeight: "3rem",
        width: "100%",
        ml: "-1rem",
        "&:hover": {
          cursor: "pointer",
          "& #trash-icon": {
            visibility: "visible",
          },
        },
      }}
    >
      <Grid item xs={4} sx={{
        color: theme.palette.color.slate[700],
        fontWeight: "fontWeightMediumBold",
        overflow: "hidden",
        fontSize: "0.8rem",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}>

        <b style={{ marginRight: "0.5rem" }}>~</b>
        {item?.title}
      </Grid>
      <Grid item xs={4} sx={{
        color: theme.palette.color.description,
        overflow: "hidden",
        fontSize: "0.8rem",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        pr: "1rem"
      }}>
        <Tooltip   placement="top" followCursor title={item?.note}>
          {item?.note}
        </Tooltip>
      </Grid>

      <Grid item xs={4} sx={{
        color: theme.palette.color.slate[700],
        fontWeight: "fontWeightMediumBold",
        fontSize: "0.8rem",
        display: "flex",
        justifyContent: "flex-end",
      }}>
        {formatDateToLocal((item?.from_date), "MMM yyyy")}
        {" - "}
        {formatDateToLocal((item?.to_date), "MMM yyyy")}
        <div
          style={{
            width: "2.5rem",
            paddingLeft: "0.5rem",
            marginTop: "-2px",
          }}
        >
          <MenuViewWrapper item={item} onClickMenu={onClickMenu} />
        </div>
      </Grid>
    </Stack >
  );
};

export const MenuViewWrapper = ({ onClickMenu, item }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const onClick = (e) => {
    // setLoading(uuid);
    onClickMenu({
      e,
      setLoading,
      item,
    });
  };

  if (loading === item?.uuid) {
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <SpinLoader size={18} />
      </div>
    );
  }

  return (
    <IconButton
      aria-label="more"
      id="trash-icon"
      aria-haspopup="true"
      onClick={onClick}
      sx={{
        background: "transparent !important",
        mb: "0.25rem",
        p: 0,
        visibility: "hidden",
        cursor: "pointer",
      }}
    >
      <MoreVertIcon
        sx={{
          fontSize: "1.2rem",
          color: theme.palette.color.slate[600],
        }}
      />
    </IconButton>
  );
};
