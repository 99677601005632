import { IoCloseCircleOutline } from "react-icons/io5";
import { Button, Stack, Tooltip } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getTailwindColor, truncate } from "../Helper/data";

const CategoryChip = ({
  disabled,
  onClick,
  customColor,
  color = "#475569", //slate600
  label,
  height = "1.7rem",
  width = "fit-content",
  maxWidth = "auto",
  fontSize = "0.7rem",
  backgroundShade = 200, //50
  borderShade = 300, //700
  colorShade = 900, //700
  fontWeight = 500,
  borderRadius = 0,
  borderType = "solid",
  borderWidth = "2px",
  style,
  labelStyle,
  tooltipPlacement = "top",
  showTooltip = false,
  categoryId,
  showDelete = false,
  onDelete,
  noCategoryLabel = "Set Category",
  noCategoryLabel_tooltip = "Set Category",
  hideNoCategoryColor,
  count,
  detailed = false,
  hideBG = false,
  isBankCategory,
  postView,
}) => {
  const { t } = useTranslation();
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );
  const selectionBankCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionBankCategoriesByID
  );

  const _selectionsCategoryByID = isBankCategory
    ? selectionBankCategoriesByID
    : selectionCategoriesByID;

  const categoryLabel = useMemo(() => {
    let catLabel = "";
    if (detailed) {
      let cardCategoryParent = null;
      let rootCat = null;
      let cardCategory = _selectionsCategoryByID[categoryId]
        ? _selectionsCategoryByID[categoryId][0]
        : null;

      if (cardCategory?.parent) {
        cardCategoryParent = _selectionsCategoryByID[cardCategory?.parent]
          ? _selectionsCategoryByID[cardCategory?.parent][0]
          : null;
      }
      if (cardCategoryParent?.parent) {
        rootCat = _selectionsCategoryByID[cardCategoryParent?.parent]
          ? _selectionsCategoryByID[cardCategoryParent?.parent][0]
          : null;
      }

      catLabel = categoryId
        ? truncate(cardCategory?.label)
        : truncate("Uncategorized");
      if (cardCategoryParent) {
        catLabel =
          truncate(cardCategoryParent?.label, 12) +
          "/" +
          truncate(cardCategory?.label, 12);
      }
      if (rootCat) {
        catLabel =
          "../" +
          truncate(cardCategoryParent?.label, 12) +
          "/" +
          truncate(cardCategory?.label, 12);
      }
    }

    return catLabel;
  }, [categoryId, _selectionsCategoryByID, detailed]);

  const category = _selectionsCategoryByID?.[categoryId]?.[0];

  label = categoryId
    ? detailed
      ? categoryLabel
      : category?.immutable
        ? t(category?.label)
        : category?.label
    : t(noCategoryLabel);

  let _color = category?.color || "#475569";
  let _borderShade = borderShade;
  let _colorShade = colorShade;
  let _backgroundShade = backgroundShade;
  if (!categoryId) {
    if (hideNoCategoryColor) {
      _color = "#475569";
    } else {
      _color = "pink";
      _borderShade = 500;
      _colorShade = 500;
      _backgroundShade = 50;
    }
  } else {
    _borderShade = 0;
  }

  const categoryColor = getTailwindColor(customColor || _color, _colorShade);
  let tooltip = categoryId
    ? category?.label
    : t(noCategoryLabel_tooltip || noCategoryLabel);
  let title = count ? truncate(label, count) : label;

  const onDeleteIcon = useCallback((e) => {
    e?.stopPropagation();
    if (onDelete) onDelete(e);
  }, []);

  const onClickButton = useCallback((e) => {
    if (onClick) {
      e?.stopPropagation();
      onClick(e);
    }
  }, []);

  return (
    <Tooltip
      title={showTooltip ? tooltip : ""}
      placement={tooltipPlacement}
      followCursor
    >
      <Stack
        sx={{
          width,
          height,
          backgroundColor: "#fff",
          borderRadius,
          ...labelStyle,
          ...style,
        }}
      >
        <Button
          disabled={disabled}
          onClick={onClickButton}
          sx={{
            maxWidth,
            borderRadius,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: width === "fit-content" ? "flex-start" : "center",
            position: "relative",
            gap: "0.5rem",
            minWidth: "auto",
            width: "100%",
            height: "100%",
            paddingInline: "0.5rem",
            color: `${categoryColor} !important`,
            backgroundColor: hideBG
              ? "transparent"
              : getTailwindColor(_color, _backgroundShade),
            border: `${borderWidth} ${borderType} ${getTailwindColor(
              _color,
              _borderShade
            )}`,

            "&:hover": {
              "& #close-icon": {
                display: "block !important",
              },
              "& #category-title": {
                maxWidth: showDelete ? "90% !important" : "100%",
              },

              backgroundColor: hideBG
                ? "transparent"
                : getTailwindColor(
                    _color,
                    _backgroundShade === 50
                      ? _backgroundShade + 50
                      : _backgroundShade + 100
                  ),
            },
          }}
        >
          <span
            id="category-title"
            style={{
              fontWeight,
              fontSize,
              lineHeight: `calc(${fontSize} + 2px)`,
              color: `${categoryColor} !important`,
              textAlign: "left",
              width: "fit-content",
              maxWidth: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </span>
          {postView}
          {showDelete && (
            <IoCloseCircleOutline
              id="close-icon"
              onClick={onDeleteIcon}
              style={{
                display: "none",
                fontSize: "1rem",
                position: "absolute",
                right: "3px",
                color: categoryColor,
              }}
            />
          )}
        </Button>
      </Stack>
    </Tooltip>
  );
};
export default React.memo(CategoryChip);
