import {
  ResponsiveContainer,
  LabelList,
  BarChart,
  XAxis,
  YAxis,
  Label,
  Cell,
  Bar,
} from "recharts";
import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import _ from "underscore";

import {
  getStatisticsDataWithParams,
  thinScrollbarStyle,
  formatAmount,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import ComponentLoader from "../../../../components/ComponentLoader";
import { Color, Constant } from "../../../../Helper";
import EmptyView from "./Component/EmptyView";

const CashFlowWidget = ({ widgetType, widget }) => {
  //state
  const [data, setData] = useState(null);
  console.log("🚀 ~ CashFlowWidget ~ data:", data);
  const [isLoading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation();

  const RenderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const isPositive = value >= 0;
    const isLabelOutside = Math.abs(width) < 20; // Customize the threshold value

    return (
      <text
        x={
          isLabelOutside
            ? isPositive
              ? x + width + 10
              : x - 10
            : x + width / 2
        }
        y={y + height / 2 + 1}
        fill="black"
        fontSize={12}
        textAnchor={isLabelOutside ? (isPositive ? "start" : "end") : "middle"}
        dominantBaseline="middle"
      >
        {formatAmount({
          amount: String(parseFloat(value ?? 0)),
        })}
        {}
      </text>
    );
  };

  const CustomYAxisTick = ({ x, y, payload, data }) => {
    const entry = data.find((d) => d.name === payload.value);
    const isPositive = entry.range >= 0;
    return (
      <text
        x={x - 150}
        y={y}
        dy={4}
        fill="black"
        fontSize={12}
        textAnchor="center"
      >
        <tspan fill={isPositive ? "dimgray" : "red"}>
          {isPositive ? "+ " : "- "}
        </tspan>
        {payload.value}
      </text>
    );
  };

  const CustomXAxisTick = ({ x, y, payload }) => {
    return (
      <text x={x} y={y} fontSize={12}>
        {formatAmount({
          amount: String(parseFloat(payload.value ?? 0)),
        })}
      </text>
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "99.5%",
        overflowY: "auto",
        ...thinScrollbarStyle,
      }}
    >
      <LogicFunctions
        widget={widget}
        setData={setData}
        setIsFetching={setIsFetching}
        setLoading={setLoading}
      />
      {isLoading || isFetching ? (
        <ComponentLoader
          loading
          hideNoDataPlaceholder
          height={"100%"}
          size={60}
        />
      ) : data && data?.transformedData ? (
        <>
          <Box sx={{ padding: 2 }}>
            <Grid container spacing={3} justifyContent="center">
              {Object.entries(data.cashFlowData).map(
                ([label, value], index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      borderBottom={1}
                      borderColor="grey.300"
                      pb={1}
                    >
                      <Typography variant="subtitle2" color="textSecondary">
                        {label}
                      </Typography>
                      <Typography variant="h6" fontWeight="semi-bold">
                        {formatAmount({
                          amount: String(parseFloat(value ?? 0)),
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart data={data?.transformedData} layout="vertical">
              <XAxis type="number" orientation="top" tick={<CustomXAxisTick />}>
                <Label
                  value={t("Cash Spent")}
                  position="left"
                  offset={150}
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    fill: Color.tailwind.red[500],
                    textAnchor: "start",
                  }}
                />
                <Label
                  value={t("Cash Received")}
                  position="left"
                  offset={300}
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    fill: Color.tailwind.green[600],
                    textAnchor: "start",
                  }}
                />
              </XAxis>
              {/* <XAxis type="number" orientation="top" /> */}
              <YAxis
                dataKey="name"
                type="category"
                tickLine={false}
                fontSize={12}
                width={300}
                tickMargin={150}
                tick={<CustomYAxisTick data={data?.transformedData} />}
              />
              {/* <CartesianGrid strokeDasharray="7 7" horizontal={false} /> */}

              <Bar dataKey="start" stackId="a" fill="transparent" />
              <Bar dataKey="range" stackId="a">
                <LabelList
                  dataKey="range"
                  content={<RenderCustomizedLabel />}
                />

                {data?.transformedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      entry.type === "add"
                        ? Color.tailwind.green[600]
                        : Color.tailwind.red[500]
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      ) : (
        <EmptyView type={widgetType} />
      )}
    </Box>
  );
};

export default CashFlowWidget;

const LogicFunctions = ({ setData, setIsFetching, setLoading, widget }) => {
  //redux
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice?.selectionCategoriesByID
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  const use_global_categories = useSelector(
    (state) => state.boardSlice?.dataSetData?.use_global_categories
  );
  const _selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const selectionCategories = useMemo(() => {
    return _selectionCategories?.filter((o1) =>
      use_global_categories ? !o1?.dataset : o1?.dataset === dataset
    );
  }, [_selectionCategories, dataset, use_global_categories]);

  const { t } = useTranslation();

  const WidgetData = useQuery({
    queryKey: [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "period_data",
        widget_uuid: widget?.uuid,
      },
    ],
    queryFn: ({ signal }) => {
      let param = {
        config: {
          signal,
        },
        type: "monthly",
        dataset: dataSetData?.uuid,
        from_payment_date: widget?.start_date,
        to_payment_date: widget?.end_date,
        multiStatesIds: widget?.states,
        multiCategoryIds: widget?.categories,
        multiScenarioIds: widget?.scenarios,
      };
      if (!dataSetData.use_global_categories) {
        param.category_dataset = dataSetData?.uuid;
      } else {
        param.global_category = true;
      }
      const result = getStatisticsDataWithParams(param);

      if (result) {
        return result;
      }
    },
    backgroundFetch: true,
    enabled: false,
    priority: 3,
  });

  //lifeCycle method
  useDebounce(
    () => {
      WidgetData.refetch();
    },
    500,
    [
      widget?.states,
      widget?.scenarios,
      widget?.categories,
      widget?.start_date,
      widget?.end_date,
    ],
    true
  );

  useEffect(() => {
    setIsFetching(WidgetData?.isFetching);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WidgetData?.isFetching]);

  useEffect(() => {
    if (!WidgetData?.isFetching && WidgetData?.data) {
      generateBalanceSheet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WidgetData?.data, WidgetData?.isFetching]);

  const generateBalanceSheet = () => {
    setLoading(true);
    const bookedData = WidgetData?.data?.results || [];
    // let bookedData = _data?.filter((o1) => o1.state === "Booked");
    if (bookedData?.length > 0) {
      let taxExpenseCategories = selectionCategories?.filter(
        (o1) => o1.type_of_cost === "tax"
      );
      let taxExpenseCategoryIds = taxExpenseCategories?.map((o1) => o1.value);

      let services = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "service"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );
      let products = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "Product"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let revenueStream = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "revenue stream"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let cogs = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "cost of goods sold (cogs)"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let researchAndDevelopment = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "research & development"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let salesAndMarketing = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "sales & marketing"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let generalAndAdministrative = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "general & administrative"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let otherOpex = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "other opex"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      const allOpenStates = Constant.openPositionsStates.filter(
        (o1) => !Constant.calculationExcludeStates2.includes(o1)
      );

      let amountReceivable = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            allOpenStates?.includes(item?.state) ? item?.inflow ?? 0 : 0
          ),
        0
      );

      const lateStatesData = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            Constant.lateState.includes(item?.state) &&
              ((item?.due_date &&
                format(new Date(item?.due_date), "yyyy-MM") <
                  format(new Date(), "yyyy-MM")) ||
                (item?.month &&
                  format(new Date(item?.month), "yyyy-MM") <
                    format(new Date(), "yyyy-MM")))
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let sentStateData = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(item?.state === "Invoice sent" ? item?.inflow ?? 0 : 0),
        0
      );

      let draftStateData = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(item?.state === "Invoice draft" ? item?.inflow ?? 0 : 0),
        0
      );

      let purchaseInvoice = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            item?.state === "Purchase invoice" ? item?.outflow ?? 0 : 0
          ),
        0
      );

      let depreciationAmortization = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "depreciation & amortization"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let interest = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 2 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "interest"
              ? item?.outflow ?? 0
              : 0
          ),
        0
      );

      let financingDept = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "financing: dept"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let financingEquity = bookedData?.reduce(
        (total, item) =>
          parseFloat(total) +
          parseFloat(
            selectionCategoriesByID?.[item?.category]?.[0]?.type === 1 &&
              selectionCategoriesByID?.[item?.category]?.[0]?.category_type ===
                "financing: equity"
              ? item?.inflow ?? 0
              : 0
          ),
        0
      );

      let Input_tax_paid = bookedData?.reduce(
        (total, item) => parseFloat(total) + parseFloat(item?.vat_outflow ?? 0),
        0
      );

      let gropedMonthlyDataByCategory = _.groupBy(bookedData, ({ category }) =>
        category ? category : "uncategorized"
      );

      let categoriesDataInflow = [
        { value: "uncategorized", label: "Uncategorized Inflow", type: 1 },
        ...selectionCategories?.filter((o1) => o1.type === 1),
      ];

      let categoriesDataOutflow = [
        {
          value: "uncategorized",
          label: "Uncategorized Outflow",
          type: 2,
        },
        ...selectionCategories?.filter((o1) => o1.type === 2),
      ];

      let unCategorizedIncome = 0;
      categoriesDataInflow?.forEach((o1) => {
        let data = gropedMonthlyDataByCategory[o1.value];
        if (data?.length && !taxExpenseCategoryIds?.includes(o1?.value)) {
          if (o1.value === "uncategorized") {
            let check = data?.find((item) => item.inflow_count > 0);
            if (check) {
              unCategorizedIncome = data?.reduce(
                (total, item) =>
                  parseFloat(total) + parseFloat(item?.inflow ?? 0),
                0
              );
            }
          }
        }
      });
      let unCategorizedExpenses = 0;
      categoriesDataOutflow?.forEach((o1) => {
        let data = gropedMonthlyDataByCategory[o1.value];
        if (data?.length && !taxExpenseCategoryIds?.includes(o1?.value)) {
          if (o1.value === "uncategorized") {
            let check = data?.find((item) => item.outflow_count > 0);
            if (check) {
              unCategorizedExpenses = data?.reduce(
                (total, item) =>
                  parseFloat(total) + parseFloat(item?.outflow ?? 0),
                0
              );
            }
          }
        }
      });

      const operatingCashFlow =
        unCategorizedIncome +
        services +
        products +
        revenueStream +
        cogs +
        unCategorizedExpenses +
        researchAndDevelopment +
        salesAndMarketing +
        generalAndAdministrative +
        otherOpex +
        Input_tax_paid +
        amountReceivable +
        lateStatesData +
        sentStateData +
        draftStateData +
        purchaseInvoice;

      const freeCashFlow = operatingCashFlow + depreciationAmortization;

      const netCashFlow =
        freeCashFlow + interest + financingDept + financingEquity;

      const rawData = [
        { name: t("Income"), value: unCategorizedIncome },
        { name: t("Service"), value: services },
        { name: t("Product"), value: products },
        { name: t("Revenue Stream"), value: revenueStream },
        { name: t("Cost of Goods Sold (COGS)"), value: cogs },
        { name: t("Expenses"), value: unCategorizedExpenses },
        { name: t("Research & Development"), value: researchAndDevelopment },
        { name: t("Sales & Marketing"), value: salesAndMarketing },
        {
          name: t("General & Administrative"),
          value: generalAndAdministrative,
        },
        { name: t("Other Opex"), value: otherOpex },
        { name: t("Taxes"), value: Input_tax_paid },
        { name: t("ADD amount receivables"), value: amountReceivable },
        { name: t("Late"), value: lateStatesData },
        { name: t("Invoice sent"), value: sentStateData },
        { name: t("Invoice draft"), value: draftStateData },
        {
          name: t("Amount payable (Purchase Invoice)"),
          value: purchaseInvoice,
        },
        //Operating cashflow end
        {
          name: t("Depreciation & Amortization"),
          value: depreciationAmortization,
        },
        // free cash flow end
        { name: t("Interest"), value: interest },
        { name: t("Finance"), value: financingDept + financingEquity },
        // Net cash flow end
      ];

      const transformedData = [];
      let cumulativeValue = 0;

      rawData.forEach((item) => {
        const start = cumulativeValue;
        cumulativeValue += item.value;
        const end = cumulativeValue;

        transformedData.push({
          name: item.name,
          start: start,
          end: end,
          range: Math.floor(end - start),
          type: item.value >= 0 ? "add" : "less",
        });
      });

      setData((prev) => ({
        ...(prev || {}),
        transformedData,
        cashFlowData: {
          [t("Operating Cash Flow")]: operatingCashFlow,
          [t("Free Cash Flow")]: freeCashFlow,
          [t("Net Cash Flow")]: netCashFlow,
        },
      }));
    } else {
      setData(null);
    }
    setLoading(false);
  };

  return <></>;
};
