import {
  LinearProgress,
  useMediaQuery,
  Typography,
  useTheme,
  Tooltip,
  Paper,
  alpha,
  Chip,
  Box,
} from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { BsFillCalendar2RangeFill, BsFillTagsFill } from "react-icons/bs";
import { MdAutoAwesome, MdWatchLater } from "react-icons/md";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import { HiMiniDocumentDuplicate } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RiBankCardFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { TbListSearch } from "react-icons/tb";
import { FaExpandAlt } from "react-icons/fa";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import format from "date-fns/format";
import _ from "underscore";
import React from "react";

import {
  getValidStateAfterCategorization,
  getValidCategoryAfterStateUpdate,
  getTransactionByParams,
  getFilteredStates,
  isStatesDisabled,
  cloneDeep,
  remToPx,
} from "../../../Helper/data";
import {
  DataSourceCell,
  GrossValueCell,
  ContactCell,
  TitleCell,
  DateCell,
} from "../../../components/MuiTableCells";
import {
  setTransactionsOverlayStatus,
  setPopupStatus4,
  setPopupStatus5,
} from "../../../store/slices/datasets";
import {
  setStageLoadingText,
  setRefreshBalance,
  setRefreshData,
} from "../../../store/slices/appmain";
import TransactionRuleButton from "../../../components/TransactionRuleButton";
import CombinedDataGridFooter from "./Components/CombinedDataGridFooter";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import { GlobalContext } from "../../../GlobalContextWrapper";
import ExportDialogView from "./Components/ExportDialogView";
import ScenarioChip from "../../../components/ScenarioChip";
import CategoryChip from "../../../components/CategoryChip";
// import ReconcileListView from "./ReconcileListView";
import StateChip from "../../../components/StateChip";
import ThemeTabs from "../../../components/ThemeTabs";
import EndPoints from "../../../APICall/EndPoints";
import initialData from "./../../../Helper/data";
import ListHeader from "./Components/ListHeader";
import useWidth from "../../../hooks/useWidth";
import Icon from "../../../components/Icon";
import { Constant } from "../../../Helper";
import { queryClient } from "../../../App";
import BulkListView from "./BulkListView";
import APICall from "../../../APICall";
import store from "../../../store";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme?.palette?.color?.appThemeBg,
      height: "100%",
      paddingTop: "1rem",
      width: "100%",
      overflow: "hidden",
      [`& .last-header-cell`]: {
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
      },
    },
    table: {
      "& .MuiDataGrid-columnHeaderTitle": {
        fontSize: "0.8rem",
        fontWeight: 600,
      },

      "& .MuiDataGrid-row": {
        cursor: "pointer",
      },
      "& .MuiDataGrid-row:hover .hover-icon": {
        opacity: 1,
      },
      "& .MuiDataGrid-row .hover-icon": {
        display: "flex",
        alignItems: "center",
        padding: "0.25rem",
        borderRadius: theme.borderRadius.borderRadiusMD,
        backgroundColor: theme.palette.color.white,
        border: `1px solid ${theme.palette.color.grey[300]}`,
        color: theme.palette.color.grey[500],
        position: "absolute",
        right: -14,
        top: "50%",
        transform: "translateY(-50%)",
        opacity: 0,
        transition: "opacity 0.3s",
      },
    },
    lastColumnHeader: {
      "& .MuiDataGrid-colCell:last-child": {
        borderRight: "none",
      },
    },
  };
});
const tabOptions = [
  {
    id: 1,
    key: "bulk",
    value: "bulkUnCatArray",
    iconPosition: "start",
    label: "Bulk categorization",
    icon: (
      <Icon
        icon={<BsFillTagsFill />}
        fontSize={"1.1rem"}
        color={theme.palette.color.slate[500]}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },
  // {
  //   id: 2,
  //   key: "suggestion",
  //   value: "suggestionArray",
  //   iconPosition: "start",
  //   label: "Suggestions",
  //   icon: (
  //     <AutoFixHighIcon color={Color.tailwind.slate[600]} />
  //   ),
  //   className: "",
  // },
  {
    id: 3,
    key: "bank",
    value: "bankArray",
    iconPosition: "start",
    label: "Bank",
    icon: (
      <Icon
        icon={<RiBankCardFill />}
        color={theme.palette.color.slate[500]}
        fontSize={"1.2rem"}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },
  {
    id: 4,
    key: "open",
    value: "openArray",
    iconPosition: "start",
    label: "Open Positions",
    icon: (
      <Icon
        icon={<HiMiniDocumentDuplicate />}
        color={theme.palette.color.slate[500]}
        fontSize={"1.2rem"}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },
  {
    id: 5,
    key: "budget",
    value: "plannedArray",
    iconPosition: "start",
    label: "Budgets",
    icon: (
      <Icon
        icon={<BsFillCalendar2RangeFill />}
        color={theme.palette.color.slate[500]}
        fontSize={"0.9rem"}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },
  // {
  //   id: 6,
  //   key: "reconcile",
  //   value: "reconcileArray",
  //   iconPosition: "start",
  //   label: "Reconcile",
  //   icon: (
  //     <CompareArrowsIcon
  //       sx={{
  //         fontSize: "1.2rem",
  //       }}
  //     />
  //   ),
  //   className: "",
  // },
  {
    id: 7,
    key: "late",
    value: "lateArray",
    iconPosition: "start",
    label: "Late",
    icon: (
      <Icon
        icon={<MdWatchLater />}
        color={theme.palette.color.slate[500]}
        fontSize={"1.2rem"}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },
  {
    id: 8,
    key: "all",
    value: "allArray",
    iconPosition: "start",
    label: "All",
    icon: (
      <Icon
        icon={<TbListSearch />}
        color={theme.palette.color.slate[500]}
        fontSize={"1.2rem"}
        style={{
          mr: "0.3rem",
        }}
      />
    ),
    className: "",
  },
];
const recurringTypeTabsByValue = _.groupBy(
  initialData?.recurringTypeTabs,
  "value"
);
const Transactions = () => {
  const currentWidth = useWidth();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);
  const s2133 = useMediaQuery("(min-width:2133px)");
  const s2400 = useMediaQuery("(min-width:2400px)");

  const timer = useRef(0);
  const prevent = useRef(false);
  const delay = useRef(200);

  const old_data = useRef([]);
  const updateType = useRef("single");
  const itemRow = useRef(null);

  const rulesRef = globalContext?.rulesRef;

  let HEIGHT = 2.5;
  let spacing = 110;
  const _width = document.body.clientWidth;
  const width = (_width * 85) / 100;
  if (s2133) {
    HEIGHT = 3;
  }
  if (s2400) {
    HEIGHT = 5;
  }

  //state
  const [rows, setRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [tab, setTab] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowCountState, setRowCountState] = useState(0);
  const [order_by, setOrder_by] = useState("-due_date");
  const [pageSize, setPageSize] = useState(20);

  //redux
  const vat = useSelector((state) => state.globalSlice.vat);
  const scenario = useSelector((state) => state.globalSlice.scenario);
  const stateByTitle = useSelector((state) => state.globalSlice.stateByTitle);
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);
  const refreshData = useSelector((state) => state.appSlice?.refreshData);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const costunits = useSelector((state) => state.globalSlice?.costunits);

  const filter_list = useSelector(
    (state) => state.globalSlice.appliedFilterlist?.list?.[tab] || {}
  );
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const {
    searchText = "",
    start_date = "",
    end_date = "",
    typeName = ["Expense", "Income"],
    selectedStates = [],
    selectedCategory = [],
    source = Constant.DefaultSource,
    recurring_type = [],
  } = filter_list;
  const isPlanned = tab === "plannedArray";
  const hideSource = tab !== "allArray";
  const hideDs =
    tab === "bulkUnCatArray" ||
    tab === "reconcileArray" ||
    tab === "lateArray" ||
    tab === "plannedArray" ||
    tab === "suggestionArray";
  const hideState =
    tab === "bulkUnCatArray" ||
    tab === "reconcileArray" ||
    tab === "lateArray" ||
    tab === "plannedArray" ||
    tab === "suggestionArray";
  const hideScenario =
    tab === "plannedArray" ||
    tab === "bankArray" ||
    tab === "bulkUnCatArray" ||
    tab === "reconcileArray";

  const hideContact =
    tab === "bulkUnCatArray" ||
    tab === "plannedArray" ||
    tab === "suggestionArray" ||
    tab === "reconcileArray" ||
    tab === "unCatArray";

  const hideCategory =
    tab === "suggestionArray" ||
    tab === "bulkUnCatArray" ||
    tab === "reconcileArray" ||
    tab === "unCatArray";
  const hideCostUnit =
    tab === "reconcileArray" ||
    tab === "plannedArray" ||
    tab === "bulkUnCatArray" ||
    tab === "suggestionArray";
  const hideSeqType = tab !== "plannedArray";

  const options = useMemo(() => {
    let data = {
      scenario: [],
      vat: [],
      cost_unit: [],
    };

    let costunits_option = [];
    let scenarios_option = [];

    let vats_option = [];
    costunits?.forEach((element) => {
      costunits_option.push({
        value: element.uuid,
        label: element.title,
      });
    });

    scenario?.forEach((element) => {
      scenarios_option.push({
        value: element.title,
        label: element?.title === "Base" ? t(element?.title) : element?.title,
      });
    });
    vat?.forEach((element) => {
      vats_option.push({
        value: element.uuid,
        label: element.title,
      });
    });
    data = {
      scenario: scenarios_option,
      vat: vats_option,
      cost_unit: costunits_option,
    };
    return data;
  }, [costunits, scenario, t, vat]);

  const getStateOptions = useCallback(
    (params) => {
      let filteredStates = getFilteredStates({
        source: params?.row?.source,
        default_income_expense_type: params?.row?.income_expense_type,
        income_expense_type: params?.row?.income_expense_type,
        gross_value: params?.row?.gross_value,
      });
      filteredStates = filteredStates.filter(
        (o1) =>
          !isStatesDisabled({
            state: params?.row?.state,
            destination_state: o1?.title,
            source: params?.row?.source,
            category: params?.row?.category,
          })
      );
      return filteredStates?.map((o1) => ({
        value: o1.title,
        label: t(o1.title),
      }));
    },
    [t]
  );

  //data
  const sameColumn = [
    {
      field: "due_date",
      headerName: t("Date"),
      flex: 0.65,
      editable: true,
      type: "date",
      cellClassName: "date-color",
      renderCell: (params) => <DateCell theme={theme} value={params.value} />,
    },
    {
      field: "title",
      headerName: t("Title"),
      flex: 2,
      renderCell: (params) => (
        <TitleCell theme={theme} value={params?.value} row={params?.row} />
      ),
    },
    {
      field: "contact_name",
      headerName: t("Contact"),
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <ContactCell theme={theme} value={params?.value} row={params?.row} />
      ),
    },

    {
      field: "note",
      headerName: t("Note"),
      flex: 1.7,
      editable: true,
      sortable: false,
      renderCell: (params) => (
        <TitleCell theme={theme} value={params?.value} row={params?.row} />
      ),
    },
    {
      field: "data_source",
      headerName: tab === "bankArray" ? t("Bank") : t("Data_Source"),
      flex: 0.7,
      editable: false,
      renderCell: (params) => (
        <DataSourceCell theme={theme} row={params.row} t={t} />
      ),
    },

    // {
    //   field: "cost_unit",
    //   headerName: t("Cost_Unit"),
    //   flex: 0.75,
    //   editable: true,
    //   sortable: false,
    //   type: "singleSelect",
    //   valueOptions: options.cost_unit,
    //   renderCell: (params) => (
    //     <CostUnitCell value={params.value} costunitsById={costunitsById} />
    //   ),
    // },
    {
      field: "gross_value",
      headerName: t("Value"),
      flex: 0.75,
      editable: true,
      type: "float",
      valueGetter: ({ value }) => {
        return value ? Number(value || 0) : null;
      },
      renderCell: (params) => <GrossValueCell {...params} theme={theme} />,
    },
    {
      field: "state",
      headerName: t("State"),
      flex: 0.7,
      editable: true,
      sortable: false,
      type: "singleSelect",
      valueOptions: getStateOptions,
      renderCell: (params) => (
        <StateCell value={params.value} row={params.row} />
      ),
    },
    {
      field: "scenario",
      headerName: t("Scenario"),
      flex: 0.65,
      editable: true,
      sortable: false,
      type: "singleSelect",
      valueOptions: (params) =>
        Constant.bookedPositionsStates.includes(params?.row?.state)
          ? [{ value: "Base", label: "Base" }]
          : options.scenario,
      renderCell: (params) => <ScenarioCell value={params.value} />,
    },
    {
      field: "category",
      headerName: t("category"),
      flex: 0.7,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <CategoryCell
          row={params.row}
          value={params.value}
          handleClick={handleClick}
        />
      ),
    },
    {
      field: "last_modified_rule",
      headerName: "",
      headerClassName: "last-header-cell",
      flex: 0.8,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <RuleCell
            row={params.row}
            t={t}
            theme={theme}
            onCellDoubleClick={onCellDoubleClick}
          />
          <div className="hover-icon">
            <FaExpandAlt />
          </div>
        </div>
      ),
    },
  ];
  const openColumn = [
    {
      field: "due_date",
      headerName: t("Date"),
      flex: 0.7,
      editable: !isPlanned,
      type: "date",
      cellClassName: "date-color",
      renderCell: (params) => <DateCell theme={theme} value={params.value} />,
    },
    {
      field: "invoice_date",
      headerName: t("Invoice Date"),
      flex: 0.6,
      editable: !isPlanned,
      type: "date",
      cellClassName: "date-color",
      renderCell: (params) => <DateCell theme={theme} value={params.value} />,
    },
    {
      field: "title",
      headerName: t("Title"),
      flex: 1.8,
      renderCell: (params) => (
        <TitleCell theme={theme} value={params?.value} row={params?.row} />
      ),
    },
    {
      field: "contact_name",
      headerName: t("Contact"),
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <ContactCell theme={theme} value={params?.value} row={params?.row} />
      ),
    },
    {
      field: "note",
      headerName: t("Note"),
      flex: 1.8,
      editable: !isPlanned,
      sortable: false,
      renderCell: (params) => (
        <TitleCell theme={theme} value={params?.value} row={params?.row} />
      ),
    },
    {
      field: "data_source",
      headerName: t("Data_Source"),
      flex: 0.7,
      editable: false,
      renderCell: (params) => (
        <DataSourceCell theme={theme} row={params.row} t={t} />
      ),
    },
    // {
    //   field: "cost_unit",
    //   headerName: t("Cost_Unit"),
    //   flex: 0.6,
    //   editable: !isPlanned,
    //   sortable: false,
    //   type: "singleSelect",
    //   valueOptions: options.cost_unit,
    //   renderCell: (params) => (
    //     <CostUnitCell value={params.value} costunitsById={costunitsById} />
    //   ),
    // },
    {
      field: "gross_value",
      headerName: t("Value"),
      flex: 0.8,
      editable: true,
      type: "float",
      valueGetter: ({ value }) => {
        return value ? Number(value || 0) : null;
      },
      renderCell: (params) => <GrossValueCell {...params} theme={theme} />,
    },
    {
      field: "tax",
      headerName: t("label_tax"),
      flex: 0.3,
      editable: !isPlanned,
      sortable: false,
      type: "singleSelect",
      valueOptions: options.vat,
      renderCell: (params) => {
        const data = options.vat?.find((o1) => o1.value === params?.row?.tax);

        return (
          <p
            key={params?.row?.uuid}
            style={{
              fontWeight: 400,
              fontFamily: theme.typography.fontFamily,
              fontSize: "0.8rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {data
              ? `${data?.label}`
              : params?.row?.source === 4
                ? `${(
                    ((Number(params?.row?.gross_value || 0) -
                      Number(params?.row?.calculated_net_value || 0)) *
                      100) /
                    Number(params?.row?.calculated_net_value || 0)
                  ).toFixed(1)}%`
                : ""}
          </p>
        );
      },
    },
    {
      field: "state",
      headerName: t("State"),
      flex: 0.7,
      editable: !isPlanned,
      sortable: false,
      type: "singleSelect",
      valueOptions: getStateOptions,

      renderCell: (params) => <StateCell value={params.value} />,
    },
    {
      field: "scenario",
      headerName: t("Scenario"),
      flex: 0.7,
      editable: !isPlanned,
      sortable: false,
      type: "singleSelect",
      valueOptions: (params) =>
        Constant.bookedPositionsStates.includes(params?.row?.state)
          ? [{ value: "Base", label: "Base" }]
          : options.scenario,
      renderCell: (params) => <ScenarioCell value={params.value} />,
    },
    {
      field: "category",
      headerName: t("category"),
      flex: 0.65,
      editable: !isPlanned,
      sortable: false,
      renderCell: (params) => (
        <CategoryCell
          row={params.row}
          value={params.value}
          handleClick={handleClick}
        />
      ),
    },
    {
      field: "last_modified_rule",
      headerName: "",
      headerClassName: "last-header-cell",
      flex: 0.8,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <RuleCell
          row={params.row}
          t={t}
          theme={theme}
          onCellDoubleClick={onCellDoubleClick}
        />
      ),
    },
  ];
  const plannedColumn = [
    {
      field: "start_date",
      headerName: t("Start Date"),
      flex: 1,
      type: "date",
      cellClassName: "date-color",
      renderCell: (params) => <DateCell theme={theme} value={params.value} />,
    },
    {
      field: "end_date",
      headerName: t("End Date"),
      flex: 1,
      type: "date",
      cellClassName: "date-color",
      renderCell: (params) => <DateCell theme={theme} value={params.value} />,
    },
    {
      field: "name",
      headerName: t("Title"),
      flex: 2,
      renderCell: (params) => (
        <TitleCell theme={theme} value={params?.value} row={params?.row} />
      ),
    },

    {
      field: "recurring_type",
      headerName: t("Type"),
      flex: 1.5,
      valueGetter: ({ value }) => {
        return t(recurringTypeTabsByValue?.[value]?.[0]?.display_name || "");
      },
      renderCell: (params) => {
        return (
          <Chip
            key={params?.row?.uuid}
            icon={
              recurringTypeTabsByValue?.[params?.row?.recurring_type]?.[0]?.icon
            }
            label={t(
              recurringTypeTabsByValue?.[params?.row?.recurring_type]?.[0]
                ?.display_name || ""
            )}
            sx={{
              fontSize: "0.8rem",
              lineHeight: "0.8rem",
              fontWeight: 600,
              height: "fit-content",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              backgroundColor: theme.palette.color.slate[100],
              color: theme.palette.color.slate[600],
              px: "0.5rem",
              py: "0.4rem",
              "& svg": {
                fontSize: "1.1rem",
                color: `${theme.palette.color.slate[600]} !important`,
              },
            }}
          />
        );
      },
    },
    {
      field: "value",
      headerName: t("Value"),
      flex: 1,
      editable: true,
      type: "float",
      valueGetter: ({ value }) => {
        return value ? Number(value || 0) : null;
      },
      renderCell: (params) => <GrossValueCell {...params} theme={theme} />,
    },
    {
      field: "tax",
      headerName: t("label_tax"),
      flex: 1,
      editable: !isPlanned,
      sortable: false,
      type: "singleSelect",
      valueOptions: options.vat,
      renderCell: (params) => {
        let data = options.vat?.find((o1) => o1.value === params?.row?.tax);

        return (
          <p
            key={params?.row?.uuid}
            style={{
              fontWeight: 400,
              fontFamily: theme.typography.fontFamily,
              fontSize: "0.8rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {data
              ? `${data?.label}`
              : params?.row?.source === 4
                ? `${(
                    ((params?.row?.gross_value -
                      params?.row?.calculated_net_value) *
                      100) /
                      params?.row?.calculated_net_value || 0
                  ).toFixed(1)}%`
                : ""}
          </p>
        );
      },
    },

    {
      field: "transaction_category",
      headerName: t("category"),
      headerClassName: "last-header-cell",
      flex: 1,
      editable: !isPlanned,
      sortable: false,
      renderCell: (params) => {
        return (
          <strong style={{ display: "inline-flex", alignItems: "center" }}>
            <CategoryChip
              onClick={
                isPlanned || params?.row?.recurring_rule
                  ? undefined
                  : (e) => handleClick(e, params)
              }
              categoryId={params.value}
              showTooltip
              width={`${16 * 0.5}rem`}
              noCategoryLabel={"Click to set category"}
            />
            <div style={{ width: "1rem" }}>
              {params?.row?.last_modified_rule || params?.row?.parent ? (
                <Tooltip title={t("Recently updated by finban")}>
                  <MdAutoAwesome
                    style={{
                      fontSize: "0.85rem",
                      color: theme.palette.color.slate[400],
                      cursor: "default",
                      marginLeft: "0.3rem !important",
                      alignSelf: "center",
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>
          </strong>
        );
      },
    },
  ];
  const suggestionColumn = [
    {
      field: "due_date",
      headerName: t("Due Date"),
      flex: 1,
      editable: true,
      type: "date",
      cellClassName: "date-color",
      renderCell: (params) => <DateCell theme={theme} value={params.value} />,
    },
    {
      field: "title",
      headerName: t("Title"),
      flex: 1,
      editable: true,
    },
    {
      field: "data_source",
      headerName: tab === "bankArray" ? t("Bank") : t("Data_Source"),
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <DataSourceCell theme={theme} row={params.row} t={t} />
      ),
    },

    {
      field: "note",
      headerName: t("Note"),
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params) => (
        <TitleCell theme={theme} value={params?.value} row={params?.row} />
      ),
    },
    {
      field: "gross_value",
      headerName: t("Value"),
      flex: 1,
      editable: true,
      type: "float",
      valueGetter: ({ value }) => {
        return value ? Number(value || 0) : null;
      },
      renderCell: (params) => <GrossValueCell {...params} theme={theme} />,
    },
    {
      field: "category",
      headerName: t("category"),
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <CategoryChip
            categoryId={params.value}
            showTooltip
            width={`${12}rem`}
            borderType="dashed"
            noCategoryLabel={"Click to set category"}
          />
        );
      },
    },
    {
      field: "suggested_category",
      headerName: t("suggested_category"),
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <CategoryChip
            categoryId={params.value}
            showTooltip
            width={`${12}rem`}
            borderType="dashed"
            noCategoryLabel={"Click to set category"}
          />
        );
      },
    },
    {
      field: "apply",
      headerName: "",
      headerClassName: "last-header-cell",
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip
            title={t("apply_suggestion_tooltip")}
            followCursor
            placement="top"
          >
            <Button
              onClick={(e) => onClickApprove(e, params)}
              variant="outlined"
              sx={{
                p: 0,
                width: "fit-content",
                minWidth: "fit-content",
                px: "0.5rem",
                textTransform: "initial",
                fontSize: "1rem",
              }}
            >
              {t("apply_suggestion")}
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  const columns = {
    allArray: sameColumn,
    catArray: sameColumn,
    suggestionArray: suggestionColumn,
    bankArray: sameColumn,
    openArray: openColumn,
    plannedArray: plannedColumn,
    lateArray: sameColumn,
    issueArray: sameColumn,
  };

  // lifeCycle method
  useEffect(() => {
    if (dataSetData?.uuid) {
      const array = location.pathname.split("/");
      const key = array[array.length - 1];
      if (key !== "list") {
        const filter = tabOptions?.find((o1) => o1?.key === key);
        if (filter) {
          setTab(filter?.value);
        }
      } else {
        setTab("bulkUnCatArray");
      }
    }
  }, [dataSetData?.uuid, location.pathname]);

  useDebounce(
    () => {
      if (
        tab &&
        tab !== "bulkUnCatArray" &&
        tab !== "reconcileArray" &&
        dataSetData?.uuid
      ) {
        updateList();
      }
    },
    500,
    [profile, options, filter_list, tab, page, refreshData, dataSetData?.uuid]
  );

  useDebounce(
    () => {
      if (selectedRowData?.length > 0 && dataSetData?.uuid) {
        dispatch(
          setPopupStatus5({
            overlay_type: "all",
            payload: { selectedRowData, resetKanban },
          })
        );
      } else {
        dispatch(setPopupStatus5(null));
      }
    },
    500,
    [selectedRowData],
    true
  );

  //api
  const updateCardByID = async (id, obj) => {
    await APICall("patch", EndPoints.transactions + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          dispatch(setStageLoadingText(null));
          let resetObj = null;
          if (obj?.hasOwnProperty("state")) {
            resetObj = {
              refetchBalance: true,
            };
          }
          resetKanban(resetObj);
        } else {
          setRows([...old_data.current]);
        }
        if (response.data?.category?.[0]?.includes("Cannot set category.")) {
          rulesRef.current?.onOpen({
            payload: [{ ...obj, uuid: id }],
            response: [{ ...response?.data, uuid: id }],
            message: response?.data?.category?.[0],
            transaction_rule: response?.data?.transaction_rule?.[0],
            rule_type: response?.data?.type?.[0],
            title: itemRow.current?.title,
            transaction_type: itemRow.current?.income_expense_type,
            category: obj?.category,
          });
        }
      }
    );
  };

  const getData = async (page, endUrl = "") => {
    setListLoading(true);
    const response = await getTransactionByParams({
      dataset: dataSetData?.uuid,
      page: page + 1,
      page_size: pageSize,
      order_by,
      is_reconciled: false,
      detailed: 2,
      url: endUrl,
      config: {
        doNotCatchRespond: true,
      },
    });
    if (response) {
      setRowCountState(response?.count);
      old_data.current = cloneDeep(response?.results || []);
      setRows(old_data.current);
    } else {
      setPage(0);
    }
    setListLoading(false);
  };

  const getDataLate = async (page, url1 = "", url2 = "") => {
    setListLoading(true);
    const response1 = await getTransactionByParams({
      dataset: dataSetData?.uuid,
      page: page + 1,
      page_size: 20,
      is_reconciled: false,
      detailed: 2,
      order_by: "-due_date",
      url: url1,
      config: {
        doNotCatchRespond: true,
      },
    });
    if (!response1) {
      setPage(0); //if error fetching data of higher page, set page to 0
    }
    const response2 = await getTransactionByParams({
      dataset: dataSetData?.uuid,
      page: page + 1,
      page_size: 20,
      is_reconciled: false,
      detailed: 2,
      order_by: "-due_date",
      url: url2,
      config: {
        doNotCatchRespond: true,
      },
    });
    if (!response2) {
      setPage(0); //if error fetching data of higher page, set page to 0
    }
    let count = response1?.count + response2?.count;

    setRowCountState(count);
    let data = [...(response1?.results || []), ...(response2?.results || [])];
    old_data.current = data;
    setRows(data);
    setListLoading(false);
  };

  const resetKanban = async (resetObj) => {
    updateList();

    global.allowFetch = { Inflow: [], Outflow: [] };
    let options = {
      predicate: (query) => query.queryKey[0] === "transactions",
    };
    queryClient.resetQueries(options);
    if (resetObj?.refetchBalance) {
      dispatch(setRefreshBalance(Date.now()));
    }
  };

  //function
  const getFilterListUrl = (filter_list) => {
    const {
      searchText = "",
      start_date = "",
      end_date = "",
      typeName = ["Expense", "Income"],
      selectedStates = [],
      selectedCostUnits = [],
      selectedScenarios = [],
      selectedContacts = [],
      selectedCategory = [],
      source = Constant.DefaultSource,
      date_type = 1,
      selectedDataSource = [],
    } = filter_list;

    let url = "&is_split=false";
    const use_global_categories =
      store.getState().boardSlice?.dataSetData?.use_global_categories;
    if (!use_global_categories) {
      url = url + `&category_dataset=${dataSetData?.uuid}`;
    } else {
      url = url + `&global_category=true`;
    }
    if (searchText && searchText?.length > 0) {
      url = url + `&title_or_note=${searchText}`;
    }
    if (typeName?.includes("Income") && !typeName?.includes("Expense")) {
      url = url + "&income_expense_type=1";
    }
    if (!typeName?.includes("Income") && typeName?.includes("Expense")) {
      url = url + "&income_expense_type=2";
    }

    if (start_date) {
      if (!date_type || date_type === 1) {
        url = url + `&from_date=${format(new Date(start_date), "yyyy-MM-dd")}`;
      } else {
        url =
          url +
          `&from_invoice_date=${format(new Date(start_date), "yyyy-MM-dd")}`;
      }
    }
    if (end_date) {
      if (!date_type || date_type === 1) {
        url = url + `&to_date=${format(new Date(end_date), "yyyy-MM-dd")}`;
      } else {
        url =
          url + `&to_invoice_date=${format(new Date(end_date), "yyyy-MM-dd")}`;
      }
    }

    if (!hideCategory && selectedCategory?.length > 0) {
      const isUncategorized = selectedCategory?.includes(
        "unCategorized_category"
      );

      const selectionCategoriesByID =
        store?.getState()?.categorySlice?.selectionCategoriesByID;
      if (selectedCategory?.length === 1 && isUncategorized) {
        url = url + "&categorized=false";
      } else {
        selectedCategory?.forEach((o1) => {
          const _category = selectionCategoriesByID?.[o1]?.[0]?.uuid;
          if (_category) {
            if (isUncategorized) {
              url = url + `&uncategorized_or_with_category=${_category}`;
            } else {
              url = url + `&category=${_category}`;
            }
          }
        });
      }
    }
    if (!hideState && tab !== "openArray" && selectedStates?.length > 0) {
      const stateById = store?.getState()?.globalSlice?.stateById;
      selectedStates.forEach((item) => {
        const _state = stateById?.[item]?.[0]?.uuid;
        if (_state) {
          url = url + `&state=${_state}`;
        }
      });
    }
    if (!hideScenario && selectedScenarios?.length > 0) {
      selectedScenarios.forEach((item) => {
        const _scenario = scenarioById?.[item]?.[0]?.uuid;
        if (_scenario) {
          url = url + `&scenario=${_scenario}`;
        }
      });
    }
    if (!hideCostUnit && selectedCostUnits?.length > 0) {
      url = url + `&cost_unit=${selectedCostUnits[0]}`;
    }
    if (!hideContact && selectedContacts?.length > 0) {
      selectedContacts.forEach((item) => {
        url = url + `&contact=${item}`;
      });
    }

    if (
      [
        "openArray",
        "allArray",
        "issueArray",
        "lateArray",
        "bankArray",
      ].includes(tab)
    ) {
      url = url + "&is_recurred=false";
    }
    if (!hideSource && source?.length > 0) {
      source?.forEach((item) => {
        const _source = Constant?.DefaultSourceByName?.[item];
        if (_source) {
          url = url + `&source=${_source}`;
        }
      });
    }
    if (!hideDs && selectedDataSource?.length > 0) {
      selectedDataSource?.forEach((id) => {
        url = url + `&data_source=${id}`;
      });
    }

    return url;
  };

  const onClickApprove = (e, params) => {
    e.stopPropagation();
    updateCardByID(params?.row?.uuid, {
      category: params?.row?.suggested_category,
    });
  };

  const updateList = () => {

    if (filter_list?.hasOwnProperty("searchText")) {
      setListLoading(true);
      setSelectedRowData([]);
      setSelectedRowIds([]);
      if (tab === "plannedArray") {
        const recurring_rules = store.getState().globalSlice?.recurring_rules;
        let filterData = [...recurring_rules];
        const isIncome =
          typeName?.includes("Income") && !typeName?.includes("Expense");
        const isExpense =
          !typeName?.includes("Income") && typeName?.includes("Expense");
        const isEmpty =
          !typeName?.includes("Income") && !typeName?.includes("Expense");
        if (searchText && searchText?.length > 0) {
          filterData = filterData?.filter((item) =>
            item?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
          );
        }
        if (start_date || end_date) {
          filterData = filterData?.filter((item) => {
            return (
              (start_date ? item?.start_date >= start_date : true) &&
              (end_date ? item?.end_date <= end_date : true)
            );
          });
        }
        if (selectedCategory?.length > 0) {
          const isUncategorized = selectedCategory?.includes(
            "unCategorized_category"
          );

          filterData = filterData?.filter((item) => {
            return (
              selectedCategory?.includes(item?.transaction_category) ||
              (isUncategorized ? !item?.transaction_category : false)
            );
          });
        }
        if (isIncome) {
          filterData = filterData?.filter((item) => {
            return item?.value >= 0;
          });
        }
        if (isExpense) {
          filterData = filterData?.filter((item) => {
            return item?.value < 0;
          });
        }
        if (isEmpty) {
          filterData = [];
        }
        if (recurring_type && recurring_type.length > 0) {
          filterData = filterData?.filter((item) => {
            return recurring_type?.includes(item?.recurring_type);
          });
        }
        setPageSize(20);
        old_data.current = filterData;
        setRows(filterData);
        setRowCountState(filterData?.length);
        setListLoading(false);
      } else {
        let url = getFilterListUrl(filter_list);
        if (tab === "issueArray") {
          url = url + "&missing_date=true";
        }
        if (
          tab === "catArray" &&
          (!selectedCategory || selectedCategory?.length === 0)
        ) {
          url = url + "&categorized=true";
        }
        if (tab === "unCatArray") {
          url = url + "&categorized=false";
        }
        if (tab === "bankArray") {
          url = url + `&source=2`;
        }

        if (tab === "suggestionArray") {
          url = url + `&category_suggested=true`;
        }
        if (tab === "openArray") {
          if (selectedStates?.length > 0) {
            selectedStates?.forEach((id) => {
              url = url + `&state=${id}`;
            });
          } else {
            Constant?.openPositionsStates?.forEach((item) => {
              const _state = stateByTitle?.[item]?.[0]?.uuid;
              if (_state) {
                url = url + `&state=${_state}`;
              }
            });
          }
        }
        if (tab === "bankArray" && selectedStates?.length > 0) {
          selectedStates.forEach((id) => {
            url = url + `&state=${id}`;
          });
        }
        if (tab === "lateArray") {
          const currentDate = format(new Date(), "yyyy-MM-dd");

          let url1 =
            url +
            `&from_invoice_date=&to_invoice_date=${currentDate}&missing_date=true`;
          let url2 = url + `&from_date=&to_date=${currentDate}`;

          Constant.lateState.forEach((item) => {
            let _state = stateByTitle?.[item]?.[0]?.uuid;
            if (_state) {
              url1 = url1 + `&state=${_state}`;
              url2 = url2 + `&state=${_state}`;
            }
          });
          getDataLate(page, url1, url2, tab);

          return;
        }

        if (tab === "allArray") {
          if (source?.length === 0) {
            setRowCountState(0);
            old_data.current = [];
            setRows([]);
            setListLoading(false);
            old_data.current = [];
            return;
          }
        }

        if (tab === "suggestionArray") {
          if (Number(profile?.auto_categorization) === 2) {
            getData(page, url, tab);
          } else {
            setListLoading(false);
            setRowCountState(0);
          }
        } else {
          getData(page, url, tab);
        }
      }
    }
  };

  const updateData = (tabValue) => {
    old_data.current = [];
    setRows([]);
  };

  const handleChangeTab = (event, newValue) => {
    if (tab !== newValue) {
      setTab(newValue);
      setSelectedRowData([]);
      setSelectedRowIds([]);
      setPage(0);
      updateData(newValue);
      const key = tabOptions?.find((o1) => o1?.value === newValue);
      let array = location.pathname.split("/");
      array.pop();
      array.push(key.key);
      let url = array.join("/");
      navigate(url);
    }
  };

  const onCellEditCommit = (data) => {
    const editedRow = rows?.find((row) => row.uuid === data.id);
    let obj;
    if (data.field === "due_date") {
      if (!data?.value || data?.value === "") {
        setRows(old_data.current);
        return null;
      }
      obj = {
        due_date: data.value
          ? format(new Date(data.value), "yyyy-MM-dd")
          : null,
      };
      if (data.value) {
        obj.invoice_date = null;
      }
    } else if (data.field === "invoice_date") {
      obj = {
        invoice_date: data.value
          ? format(new Date(data.value), "yyyy-MM-dd")
          : null,
      };
    } else if (data?.field === "state" && editedRow?.source === 2) {
      const categoryObj = getValidCategoryAfterStateUpdate({
        destination_state: data.value,
        category: editedRow?.category,
        gross_value: editedRow?.gross_value,
        income_expense_type: editedRow?.income_expense_type,
      });
      obj = {
        [data.field]: data.value,
        ...categoryObj,
      };
    } else if (data.field === "tax") {
      obj = {
        tax: data.value,
        tax_value: null,
      };
    } else if (data.field === "gross_value") {
      obj = {
        gross_value:
          editedRow?.income_expense_type === 1
            ? Math.abs(data?.value || 0)
            : -Math.abs(data?.value || 0),
        income_expense_type: editedRow?.income_expense_type,
      };
      if (
        !data?.value ||
        data?.value === "" ||
        data?.value === "-" ||
        Number(data?.value) === 0 ||
        isNaN(data?.value)
      ) {
        setRows(old_data.current);
        return null;
      }
    } else {
      obj = {
        [data.field]: data.value,
      };
    }

    updateCardByID(data.id, obj);
  };

  const handleClick = (e, item) => {
    itemRow.current = item.row;
    onCellDoubleClick();
    dispatch(
      setPopupStatus4({
        open: true,
        anchorEl: e.currentTarget,
        overlay_type: "category",
        payload: {
          defaultType: itemRow.current?.income_expense_type,
          type: 3,
          ruleTitle: itemRow.current?.title,
          onClickCategoryTitle,
        },
      })
    );
  };

  const onClickCategoryTitle = (e, item) => {
    const category =
      item?.uuid === "unCategorized_category" ? null : item?.uuid;
    let obj = {
      category,
    };
    const stateObj = getValidStateAfterCategorization({
      category,
      income_expense_type: itemRow.current?.income_expense_type,
      source: itemRow.current?.source,
      gross_value: itemRow.current?.gross_value,
      state: itemRow.current?.state,
    });
    obj = {
      ...obj,
      ...stateObj,
    };

    updateCardByID(itemRow.current.uuid, obj);
    closeCategoryOverlay();
  };

  const closeCategoryOverlay = () => {
    dispatch(setPopupStatus4(null));
  };

  const onSelectionModelChange = (ids) => {
    let current_page_data = [];
    if (tab === "plannedArray") {
      let copy_data = [...rows];
      const number = Math.ceil(rows?.length / 20);
      for (let i = 0; i < number; i++) {
        if (i === page) {
          current_page_data = copy_data.splice(20 * i, 20);
        }
      }
    } else {
      current_page_data = rows;
    }

    const selectedIDs = new Set(ids);
    const updateData = current_page_data.filter((row) =>
      selectedIDs.has(row.uuid.toString())
    );
    if (updateData?.length > 0) {
      updateType.current = "batch";
    } else {
      updateType.current = "single";
    }
    setSelectedRowIds(ids);
    setSelectedRowData(updateData);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const onPageSizeChange = (size) => {
    setPageSize(size);
    if (page !== 0) {
      setPage(0);
    } else {
      dispatch(setRefreshData(Date.now()));
    }
  };

  const onSortModelChange = (params) => {
    if (params && params[0]) {
      let { field, sort } = params[0];
      if (field === "title") {
        if (sort === "asc") {
          setOrder_by("title");
        } else {
          setOrder_by("-title");
        }
      }
      if (field === "due_date") {
        if (sort === "asc") {
          setOrder_by("due_date");
        } else {
          setOrder_by("-due_date");
        }
      }
      if (field === "invoice_date") {
        if (sort === "asc") {
          setOrder_by("invoice_date");
        } else {
          setOrder_by("-invoice_date");
        }
      }
      if (field === "gross_value") {
        if (sort === "asc") {
          setOrder_by("gross_value");
        } else {
          setOrder_by("-gross_value");
        }
      }
    } else {
      setOrder_by("");
    }
    if (page !== 0) {
      setPage(0);
    } else {
      dispatch(setRefreshData(Date.now()));
    }
  };

  const onRowClick = (params) => {
    timer.current = setTimeout(async () => {
      if (!prevent.current) {
        let item = null;
        if (tab === "plannedArray") {
          const state = Constant.staffState?.map(
            (s) => stateByTitle?.[s]?.[0]?.uuid
          );
          const data = await getTransactionByParams({
            dataset: dataSetData?.uuid,
            recurring_rule: [params?.row?.uuid],
            state,
          });
          item = data?.results?.[0];
        } else {
          item = params.row;
        }
        if (item) {
          dispatch(
            setTransactionsOverlayStatus({
              open: true,
              overlay_type: "drawer_modal_edit_form",
              anchor: "right",
              payload: {
                modalType: "edit",
                updateOverlay: resetKanban,
                item,
              },
            })
          );
        }
      }
      prevent.current = false;
    }, delay.current);
  };

  const onCellDoubleClick = () => {
    clearTimeout(timer.current);
    prevent.current = true;
  };

  const getCellClassName = (params) => {
    if (
      params.row?.recurring_rule ||
      (params.row?.source === 2 &&
        params.field !== "state" &&
        params.field !== "category")
    ) {
      return "super-app-theme--cell";
    } else {
      return "";
    }
  };

  const isCellEditable = (params) => {
    return params.row?.recurring_type || params.row?.recurring_rule
      ? false
      : params.row?.source !== 2
        ? true
        : params?.field === "state";
  };

  return (
    <Box className={classes.root}>
      <ListHeader
        width={width}
        hideCostUnit={hideCostUnit}
        hideCategory={hideCategory}
        hideState={hideState}
        hideSource={hideSource}
        hideDs={hideDs}
        hideSeqType={hideSeqType}
        hideScenario={hideScenario}
        hideContact={hideContact}
        optionsType={
          tab === "openArray" ? "Open" : tab === "bankArray" ? "Bank" : null
        }
        tab={tab}
      />
      <Box
        sx={{
          display: "flex",
          alignItem: "center",
          justifyContent: "space-between",
          width: width,
          maxWidth: "85%",
          mt: "1rem",
        }}
      >
        <ThemeTabs options={tabOptions} tab={tab} onChange={handleChangeTab} />

        <ExportDialogView
          rowCountState={rowCountState}
          headerData={columns?.[tab]?.slice() || []}
          getFilterListUrl={getFilterListUrl}
          order_by={order_by}
          tab={tab}
          setTab={setTab}
        />
      </Box>
      {tab === "bulkUnCatArray" ? (
        <BulkListView width={width} tab={tab} />
      ) : (
        <Paper
          sx={{
            boxShadow:
              tab === "bulkUnCatArray"
                ? "none !important"
                : "0px 3px 1px -2px rgb(0 0 0 / 16%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            width: width,
            borderRadius: "5px",
            backgroundColor:
              tab === "bulkUnCatArray"
                ? "transparent"
                : theme.palette.color.white,
            maxHeight: "80%",
            height: 20 * remToPx(currentWidth, HEIGHT) + spacing,
            position: "relative",
            "& .MuiDataGrid-overlay": {
              zIndex: 1,
            },
            "& .MuiDataGrid-columnHeaders": {
              border: 0,
            },
            "& .MuiDataGrid-columnHeader": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              width: width,
              position: "absolute",
              left: 0,
            },
            "& .MuiDataGrid-cell": {
              borderColor: theme.palette.color.slate[100],
              padding: "0 6px",
            },
            "& .super-app-theme--cell": {
              backgroundColor:
                tab === "plannedArray"
                  ? undefined
                  : alpha(theme.palette.color.slate[50], 0.45),
              borderColor: theme.palette.color.slate[100],
            },
            "& .divider-cell": {
              backgroundColor: theme.palette.color.green[300],
            },
            "& .date-color": {
              color:
                tab === "lateArray"
                  ? theme.palette.color.red[500]
                  : theme.palette.color.black,
            },
          }}
        >
          {/* {tab === "reconcileArray" ? (
            <ReconcileListView
              tab={tab}
              rowCountState={rowCountState}
              setRowCountState={setRowCountState}
            />
          ) : ( */}
          <DataGrid
            className={classes.table}
            rows={rows}
            showColumnRightBorder={false}
            columns={tab ? [...columns?.[tab]] : []}
            disableColumnMenu
            disableSelectionOnClick
            rowHeight={remToPx(currentWidth, HEIGHT)}
            checkboxSelection
            getRowId={(row) => row?.uuid}
            onCellEditCommit={onCellEditCommit}
            selectionModel={selectedRowIds}
            onSelectionModelChange={onSelectionModelChange}
            getCellClassName={getCellClassName}
            isCellEditable={isCellEditable}
            rowCount={rowCountState}
            onSortModelChange={onSortModelChange}
            onRowClick={onRowClick}
            onCellDoubleClick={onCellDoubleClick}
            page={page}
            onPageChange={onPageChange}
            pagination
            // autoPageSize
            // pageSize={20}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            rowsPerPageOptions={tab === "plannedArray" ? [20] : [20, 50, 100]}
            paginationMode={tab === "plannedArray" ? "client" : "server"}
            loading={listLoading}
            localeText={{
              noRowsLabel: "",
              noResultsOverlayLabel: "",
              MuiTablePagination: {
                labelRowsPerPage: t("mui_grid_rows_per_page"), // Translates "Rows per page"
              },
            }}
            componentsProps={{
              footer: {
                selectedRowData,
                tab,
                rowCountState,
                listLoading,
                hideCustomFooter: tab === "plannedArray",
              },
            }}
            components={{
              LoadingOverlay: LinearProgress,
              Footer: CombinedDataGridFooter,
            }}
            sx={{
              border: 0,
            }}
          />
          {/* )} */}
        </Paper>
      )}
    </Box>
  );
};

export default Transactions;

// CategoryCell Component
const CategoryCell = React.memo(({ row, value, handleClick }) => (
  <CategoryChip
    onClick={row?.recurring_rule ? undefined : (e) => handleClick(e, { row })}
    categoryId={value}
    showTooltip
    width="100%"
    noCategoryLabel="Click to set category"
    noCategoryLabel_tooltip={"list_view_no_category_tooltip"}
  />
));

// RuleCell Component
const RuleCell = React.memo(({ row, theme, t, onCellDoubleClick }) => {
  return (
    <strong
      style={{
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        maxWidth: "100%",
        height: "fit-content",
      }}
    >
      <TransactionRuleButton
        t={t}
        theme={theme}
        transaction={row}
        onClickPreCallback={onCellDoubleClick}
        truncateCount={9}
        sx={{
          p: "0.25rem",
          px: "0.5rem",
          fontSize: "0.7rem",
          height: "1.7rem",
        }}
      />
      <div style={{ width: "1rem" }}>
        {row?.parent && (
          <Tooltip title="Splitted Transactions">
            <CallSplitIcon className="icon-styling" />
          </Tooltip>
        )}
      </div>
    </strong>
  );
});

// CostUnitCell Component
const CostUnitCell = React.memo(({ value, costunitsById }) => {
  const costUnitTitle =
    value && costunitsById[value] ? costunitsById[value][0]?.title : "";
  return (
    <Tooltip title={costUnitTitle}>
      <Typography variant="h6" component="h6" className="cell-text-truncate">
        {costUnitTitle}
      </Typography>
    </Tooltip>
  );
});

// StateCell Component
const StateCell = React.memo(({ row, value }) => (
  <StateChip
    title={value}
    income_expense_type={row?.income_expense_type}
    hideBorder
    hideIcon
    showClickCursor
    defaultShade={100}
    width="100%"
    height="1.6rem"
    fontSize="0.7rem"
  />
));

// ScenarioCell Component
const ScenarioCell = React.memo(({ value }) => (
  <ScenarioChip
    title={value}
    hideBorder
    hideIcon
    showClickCursor
    width="100%"
    maxWidth={"100%"}
    height="1.6rem"
    fontSize="0.7rem"
    style={{ maxWidth: "100%" }}
  />
));
