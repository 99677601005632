import {
  FormControlLabel,
  Typography,
  Checkbox,
  useTheme,
  Tooltip,
  Button,
  Drawer,
  Alert,
  Stack,
  Box,
} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useCallback, useContext, useEffect, useState, useRef } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { addMonths, format, subMonths } from "date-fns";
import { IoPeopleCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import NotesIcon from "@mui/icons-material/Notes";
import CloseIcon from "@mui/icons-material/Close";
import LoopIcon from "@mui/icons-material/Loop";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  getValidCategoryAfterStateUpdate,
  getValidStateAfterCategorization,
  getAllTransactionsByParams,
  deleteBatchTransactions,
  addBatchTransactions,
  CurrencyPrefixIcon,
  getTransactionById,
  buildUrlFromParams,
  getStaffQueryKey,
  getTailwindColor,
  formatAmount,
} from "../../Helper/data";
import {
  setRefreshBalance,
  setRefreshData,
  setLoading,
} from "../../store/slices/appmain";
import {
  setTransactionsOverlayStatus,
  setPopupStatus4,
} from "../../store/slices/datasets";
import DSImage from "../../Pages/Settings/DataSource/DSImage";
import TransactionRuleButton from "../TransactionRuleButton";
import useSubscriptions from "../../hooks/useSubscriptions";
import DescriptionInput from "../Overlay/DescriptionInput";
import { GlobalContext } from "../../GlobalContextWrapper";
import ContactDropDown from "../Overlay/ContactDropDown";
import { formatDateToLocal } from "./../../Helper/data";
import RecurringFormView from "./RecurringFormView";
import ComponentLoader from "../ComponentLoader";
import EndPoints from "../../APICall/EndPoints";
import ActionButtonView from "./ActionBtnView";
import CategoryChip from "../CategoryChip";
import Android12Switch from "../A12Switch";
import ScenarioView from "./ScenarioView";
import CostUnitView from "./CostUnitView";
import { Constant } from "../../Helper";
import { queryClient } from "../../App";
import APICall from "../../APICall";
import StateView from "./StateView";
import TitleView from "./TitleView";
import store from "../../store";
import Icon from "../Icon";

const DrawerModalEditForm = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);
  const [isSubscriptionValid] = useSubscriptions();

  const seqFunctionRef = globalContext?.seqFunctionRef;
  const rulesRef = globalContext?.rulesRef;
  const internalDsRef = globalContext?.internalDsRef;

  const updatedObj = useRef({});
  const item = useRef(null);
  const oldTransactionId = useRef(null);
  const oldRecurId = useRef(null);
  const isTransactionFormUpdated = useRef(false);
  const isBalanceAffected = useRef(false);
  const income_expense_type = useRef(null);
  const PausedDuration = useRef([]);
  const VariableRatesItemList = useRef(null);
  const PausedDurationItemList = useRef(null);
  const SpecialPaymentsItemList = useRef(null);

  //redux
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const transactionsOverlayStatus = useSelector(
    (state) => state.datasetSlice?.transactionsOverlayStatus
  );
  const recurring_rulesById = useSelector(
    (state) => state.globalSlice?.recurring_rulesById
  );
  const transactionsItem = transactionsOverlayStatus?.payload?.item;
  const modalType = transactionsOverlayStatus?.payload?.modalType;
  const default_start_date = format(
    transactionsItem?.due_date
      ? new Date(transactionsItem?.due_date)
      : new Date(),
    "yyyy-MM-dd"
  );

  //state
  const [isRecurrenceUpdated, setIsRecurrenceUpdated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [isFormVisible, setFormVisible] = useState(modalType !== "edit");
  const [tab, setTab] = useState("single");
  const [cardItem, setCardItem] = useState({});
  const [recurring_Obj, setRecurring_Obj] = useState({
    name: "",
    recurring_type: "simple",
    salary_type: 2,
    value: transactionsItem?.gross_value
      ? Math.abs(transactionsItem?.gross_value)
      : 0,
    start_date: default_start_date,
    interest_rate_type: 3,

    value_sets: [
      {
        base_value: 0,
        base_value_name: "ongoing_cost",
        title: "ongoing_cost",
        items: [],
      },
      {
        base_value: 0,
        base_value_name: "one_off_cost",
        title: "one_off_cost",
        items: [],
      },
    ],
  });

  const disabled = cardItem?.source === 2;
  const showSaved =
    modalType === "edit" &&
    (isRecurrenceUpdated || isTransactionFormUpdated.current);

  const isStaff = tab === "employee" || tab === "loan" || tab === "leasing";
  const isLoanSpecialPayments =
    cardItem?.state === "Booked" && cardItem?.recurring_rule;
  const isRecurringSeq = cardItem?.recurring_rule;

  //api
  const getContacts = async (params) => {
    let result = null;
    await APICall("get", EndPoints.customers + buildUrlFromParams(params)).then(
      (response) => {
        if (response.status === 200 && response.data) {
          result = response.data;
        }
      }
    );
    return result;
  };

  const getRecurringRule = async (id) => {
    await APICall("get", EndPoints.recurring_rules + `${id}/`).then(
      async (response) => {
        if (response.status === 200 && response.data) {
          setTab(response.data?.recurring_type);

          let recurringItem = {
            ...response.data,
          };
          if (recurringItem?.recurring_type === "employee") {
            const StaffList = await queryClient.fetchQuery({
              queryKey: getStaffQueryKey(),
              queryFn: ({ signal }) => {
                const result = getContacts({
                  type: 3,
                });
                if (result) {
                  return result;
                }
              },
              backgroundFetch: true,
            });
            const contact = StaffList?.results?.find(
              (o) => o?.uuid === recurringItem?.contact
            );
            if (contact) {
              recurringItem.name = contact?.name;
            }
          }
          setRecurring_Obj(recurringItem);
        }
      }
    );
  };

  const updateCardByID = async (id, obj, doUpdate) => {
    await APICall("patch", EndPoints.transactions + `${id}/`, obj, {
      doNotCatchRespond: true,
    }).then((response) => {
      if (response.data?.category?.[0]?.includes("Cannot set category.")) {
        rulesRef.current?.onOpen({
          payload: [{ ...obj, uuid: id }],
          response: [{ ...response?.data, uuid: id }],
          message: response?.data?.category?.[0],
          oldObj: obj,
          transaction_rule: response.data?.transaction_rule?.[0],
          rule_type: response.data?.type?.[0],
          title: obj?.title,
          transaction_type: income_expense_type.current,
          category: obj?.category,
          callback: (updatedObj) => {
            isTransactionFormUpdated.current = true;
            setCardItem((draft) => ({ ...draft, ...updatedObj }));
            dispatch(setTransactionsOverlayStatus(null));
            if (showSaved || doUpdate) {
              dispatch(setRefreshData(Date.now()));
              if (transactionsOverlayStatus?.payload?.updateOverlay) {
                setTimeout(() => {
                  transactionsOverlayStatus?.payload?.updateOverlay({
                    from_edit_form: true,
                  });
                }, 0);
              }
            }
          },
        });
      } else if (response.status === 400 && response.data) {
        const hasAnyErrorKey =
          response?.data && Object.keys(response?.data)?.length > 0
            ? Object.values(response?.data)?.[0]?.[0]
            : null;
        if (hasAnyErrorKey) {
          enqueueSnackbar(hasAnyErrorKey, {
            variant: "error",
            preventDuplicate: true,
          });
        }
      } else {
        setCardItem((draft) => ({ ...draft, ...obj }));
        dispatch(setTransactionsOverlayStatus(null));
        if (showSaved || doUpdate) {
          dispatch(setRefreshData(Date.now()));
          if (transactionsOverlayStatus?.payload?.updateOverlay) {
            setTimeout(() => {
              transactionsOverlayStatus?.payload?.updateOverlay({
                from_edit_form: true,
              });
            }, 0);
          }
        }
      }
    });
  };

  const deleteCardByIdApi = async (id) => {
    await APICall("delete", EndPoints.transactions + `${id}/`).then(
      (response) => {
        if (response.status === 204 && response) {
          oldTransactionId.current = null;
          enqueueSnackbar(t("Card Deleted Successfully"));
          onClose(true);
        }
      }
    );
  };

  const deleteBatch = async (data) => {
    let uuids = data?.map((o1) => o1.uuid);
    dispatch(setLoading(true));
    const isDeleted = await deleteBatchTransactions(uuids);
    if (isDeleted) {
      dispatch(setLoading(false));
      onClose(true);
    }
  };

  const onChangeTransactionsType = async () => {
    let oldRecurData = await getAllTransactionsByParams({
      dataset: dataSetData?.uuid,
      recurring_rule: [oldRecurId.current],
      is_reconciled: false,
    });
    deleteBatch(oldRecurData);
  };

  //functions
  const onSaveRecurrence = async (obj) => {
    const { doClose = false, isPastIncluded = false } = obj;
    let deleteIds = [];
    if (oldTransactionId?.current) {
      deleteIds.push(oldTransactionId?.current);
    }
    if (
      modalType === "add"
        ? isSubscriptionValid({ showMessage: true, type: tab })
        : true
    ) {
      seqFunctionRef?.current?.onSaveRecurrence({
        cardItem,
        setCardItem,
        recurring_Obj,
        setRecurring_Obj,
        income_expense_type: income_expense_type.current,
        deleteIds,
        updatedObj,
        doNotUpdateApp: true,
        onCloseEditForm: doClose ? () => onClose(true) : null,
        setShowError,
        isPastIncluded,
        isLoanSpecialPayments,
        PausedDuration: PausedDuration.current,
        PausedDurationItemList: PausedDurationItemList.current,
        VariableRatesItemList: VariableRatesItemList.current,
        SpecialPaymentsItemList: SpecialPaymentsItemList.current,
      });
    }
  };

  const onClickEdit = () => {
    if (!global?.isInputFocused) {
      setFormVisible(!isFormVisible);
    }
  };

  const onClose = async (doUpdate) => {
    if (
      tab === "single" &&
      isTransactionFormUpdated.current &&
      cardItem?.uuid
    ) {
      await updateCardByID(
        cardItem?.uuid,
        updatedObj?.current?.cardItem,
        doUpdate
      );
    } else {
      dispatch(setTransactionsOverlayStatus(null));
      if (showSaved || doUpdate) {
        dispatch(setRefreshData(Date.now()));
        if (transactionsOverlayStatus?.payload?.updateOverlay) {
          setTimeout(() => {
            transactionsOverlayStatus?.payload?.updateOverlay({
              from_edit_form: true,
            });
          }, 0);
        }
      }
    }
  };

  const onCancel = async () => {
    dispatch(setTransactionsOverlayStatus(null));
  };

  const updateRecurrenceFlag = (obj) => {
    if (obj) {
      updatedObj.current = {
        ...updatedObj.current,
        cardItem: { ...(updatedObj.current?.cardItem || {}), ...obj },
      };
      setError({});
      setCardItem((prev) => ({
        ...prev,
        ...obj,
      }));
    }
    setShowError(false);
    setIsRecurrenceUpdated(true);
  };

  const updateTransactionFormFlag = (obj) => {
    if (obj) {
      updatedObj.current = {
        ...updatedObj.current,
        cardItem: { ...(updatedObj.current?.cardItem || {}), ...obj },
      };
      setCardItem((prev) => ({
        ...prev,
        ...obj,
      }));
    }
    setError({});
    isTransactionFormUpdated.current = true;
  };

  const updateFlag = (obj) => {
    if (modalType === "edit" ? isRecurringSeq : tab !== "single") {
      updateRecurrenceFlag(obj);
    } else {
      updateTransactionFormFlag(obj);
    }
  };

  const onBlurText = async () => {
    const obj = {
      title: cardItem.title,
      note: cardItem.note,
    };
    if (cardItem?.title?.trim() === "") {
      setError({ ...error, title: "empty_title_error" });
      return;
    }
    updateFlag(obj);
    global.isInputFocused = false;
  };

  const onClickState = (item) => {
    if (cardItem?.state !== item?.title) {
      const categoryObj = getValidCategoryAfterStateUpdate({
        destination_state: item?.title,
        category: cardItem?.category,
        gross_value: cardItem?.gross_value,
        income_expense_type: cardItem?.income_expense_type,
      });

      let obj = {
        state: item.title,
        ...categoryObj,
      };

      if (item?.title !== "Planned" && tab !== "single" && !isStaff) {
        setTab("single");
        if (recurring_Obj?.value) {
          obj.gross_value =
            income_expense_type.current === 1
              ? Math.abs(recurring_Obj?.value)
              : -Math.abs(recurring_Obj?.value);
          obj.tax = recurring_Obj?.tax;
        }
      }
      if (cardItem?.source === 2) {
        isBalanceAffected.current = true;
      }

      updateFlag(obj);
    }
  };

  const onClickScenario = (item) => {
    if (cardItem?.scenario !== item?.title) {
      const obj = { scenario: item.title };

      updateFlag(obj);
    }
  };

  const handleEditDescriptionChange = (e) => {
    let text = e.target.value;
    setCardItem({ ...cardItem, note: text });
  };

  const handleChangeHighLight = (e) => {
    const obj = {
      is_note_highlighted: e.target.checked,
    };
    updateFlag(obj);
  };

  const onChangeContact = ({ selectedItem, payload }) => {
    const obj = {
      contact: selectedItem?.uuid,
    };

    updateFlag(obj);
  };

  const onChangeCostUnit = (e, value) => {
    const obj = {
      cost_unit: value?.uuid,
    };

    updateFlag(obj);
  };

  const onChangeDataSource = (e, value) => {
    if (!value) return null;
    let source = 1;
    if (value?.type === 10) {
      source = 2;
    }
    if (value?.type === 2) {
      source = 3;
    }
    if (value?.type === 19) {
      source = 4;
    }

    const obj = {
      data_source: value?.uuid || dataSetData?.internal_data_source,
      source: source,
      transaction_system: value?.transaction_system,
    };
    updateFlag(obj);
  };

  const onCloseTransactionEdit = (obj, reason) => {
    if (modalType === "edit" && reason !== "escapeKeyDown") {
      if (!global?.isInputFocused) {
        // if (
        //   tab === "single" &&
        //   (!cardItem?.gross_value ||
        //     cardItem?.gross_value?.trim() === "" ||
        //     cardItem?.gross_value?.trim() === "-" ||
        //     Number(cardItem?.gross_value) === -0 ||
        //     Number(cardItem?.gross_value) === 0)
        // ) {
        //   enqueueSnackbar(t("please add an amount"), {
        //     variant: "warning",
        //     autoHideDuration: 5000,
        //     preventDuplicate: true,
        //   });
        //   return;
        // }
        if (tab === "single" && !cardItem?.due_date) {
          enqueueSnackbar(t("please add a due date"), {
            variant: "warning",
            autoHideDuration: 5000,
            preventDuplicate: true,
          });
          return;
        }

        if (isBalanceAffected) {
          dispatch(setRefreshBalance(Date.now()));
        }

        if (isRecurrenceUpdated) {
          onSaveRecurrence({
            doClose: true,
            isPastIncluded: obj?.isPastIncluded,
          });
        } else if (isTransactionFormUpdated.current && oldRecurId.current) {
          onChangeTransactionsType();
        } else {
          onClose();
        }
      }
    } else {
      onClose();
    }
  };

  const getTransactionInfo = async () => {
    setFetching(true);
    if (recurring_rulesById?.[cardItem?.recurring_rule]) {
      let data = recurring_rulesById?.[cardItem?.recurring_rule]?.[0];
      if (!data?.period) {
        setRecurring_Obj({
          ...data,
          recurring_type: "simple",
          value: cardItem?.gross_value,
        });
        setTab("simple");
      } else {
        let recurringItem = {
          ...data,
        };
        if (
          recurringItem?.contact &&
          recurringItem?.recurring_type === "employee"
        ) {
          const StaffList = await queryClient.fetchQuery({
            queryKey: getStaffQueryKey(),
            queryFn: ({ signal }) => {
              const result = getContacts({
                type: 3,
              });
              if (result) {
                return result;
              }
            },
            backgroundFetch: true,
          });
          const contact = StaffList?.results?.find(
            (o) => o?.uuid === recurringItem?.contact
          );
          if (contact) {
            recurringItem.name = contact?.name;
          }
        }

        setRecurring_Obj(recurringItem);
        setTab(data?.recurring_type);
        if (
          data?.recurring_type === "loan" ||
          recurringItem?.recurring_type === "leasing"
        ) {
          setFormVisible(true);
        }
      }
    } else {
      await getRecurringRule(cardItem?.recurring_rule);
    }
    setFetching(false);
  };

  const focusAmount = useCallback(() => {
    if (modalType !== "edit") {
      setTimeout(() => {
        const element = document.getElementById("currency-input");
        element?.focus();
      }, 1000);
    }
  }, [modalType]);

  //lifeCycle
  useEffect(() => {
    if (transactionsOverlayStatus?.payload?.item) {
      item.current = transactionsOverlayStatus?.payload?.item;
      const gross_value = Number(
        transactionsOverlayStatus?.payload?.item?.gross_value
      );
      income_expense_type.current =
        gross_value === 0
          ? transactionsOverlayStatus?.payload?.item?.income_expense_type
          : gross_value < 0
            ? 2
            : 1;
      setCardItem(transactionsOverlayStatus?.payload?.item);
      if (transactionsOverlayStatus?.payload?.recurringItem) {
        setRecurring_Obj(transactionsOverlayStatus?.payload?.recurringItem);
      }
      if (modalType === "edit") {
        updatedObj.current = null;
        isTransactionFormUpdated.current = false;
        isBalanceAffected.current = false;
        setIsRecurrenceUpdated(false);
      }
    }
  }, [modalType, transactionsOverlayStatus]);

  useEffect(() => {
    if (
      transactionsOverlayStatus?.open &&
      transactionsOverlayStatus?.overlay_type === "drawer_modal_edit_form"
    ) {
      setOpen(true);
      focusAmount();
    } else {
      setOpen(false);
    }
  }, [
    focusAmount,
    transactionsOverlayStatus?.open,
    transactionsOverlayStatus?.overlay_type,
  ]);

  useEffect(() => {
    if (cardItem?.recurring_rule && cardItem?.uuid) {
      getTransactionInfo();
    }
  }, [cardItem?.recurring_rule]);

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onCloseTransactionEdit}
      transitionDuration={300}
      SlideProps={{
        in: true,
      }}
      PaperProps={{
        sx: { borderRadius: "0px !important" },
      }}
      sx={{
        zIndex: 1303,
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "45rem",
          padding: "2rem",
          pb: "6rem",
          position: "relative",
          overflowY: "auto",
          ...theme.thinScrollBar,
        }}
      >
        <HeaderView
          theme={theme}
          cardItem={cardItem}
          showError={showError}
          showSaved={showSaved}
          onCloseTransactionEdit={onCloseTransactionEdit}
        />
        {fetching ? (
          <ComponentLoader
            loading={fetching}
            hideNoDataPlaceholder
            height="9rem"
          />
        ) : null}{" "}
        {!fetching && modalType === "edit" && !isFormVisible ? (
          <DatesView
            theme={theme}
            tab={tab}
            updatedObj={updatedObj}
            disabled={disabled}
            cardItem={cardItem}
            setCardItem={setCardItem}
            onClickEdit={onClickEdit}
            isFormVisible={isFormVisible}
            recurring_Obj={recurring_Obj}
            updateFlag={updateFlag}
            setRecurring_Obj={setRecurring_Obj}
            isLoanSpecialPayments={isLoanSpecialPayments}
          />
        ) : null}
        {!fetching && isFormVisible ? (
          <RecurringFormView
            PausedDuration={PausedDuration}
            VariableRatesItemList={VariableRatesItemList}
            PausedDurationItemList={PausedDurationItemList}
            SpecialPaymentsItemList={SpecialPaymentsItemList}
            tab={tab}
            setTab={setTab}
            cardItem={cardItem}
            isRecurrenceUpdated={isRecurrenceUpdated}
            setIsRecurrenceUpdated={setIsRecurrenceUpdated}
            showError={showError}
            setShowError={setShowError}
            setError={setError}
            error={error}
            disabled={disabled}
            recurring_Obj={recurring_Obj}
            setRecurring_Obj={setRecurring_Obj}
            setCardItem={setCardItem}
            updateRecurrenceFlag={updateRecurrenceFlag}
            updateFlag={updateFlag}
            updatedObj={updatedObj}
            oldRecurId={oldRecurId}
            oldTransactionId={oldTransactionId}
            isLoanSpecialPayments={isLoanSpecialPayments}
            income_expense_type={income_expense_type.current}
          />
        ) : null}
        <TitleView
          cardItem={cardItem}
          setCardItem={setCardItem}
          setError={setError}
          modalType={modalType}
          error={error}
          disabled={disabled}
          tab={tab}
          showError={showError}
          updateFlag={updateFlag}
          label={tab === "employee" ? t("Planning Title") : t("Title")}
        />
        <CategoryChipView
          theme={theme}
          showError={showError}
          tab={tab}
          recurring_Obj={recurring_Obj}
          setRecurring_Obj={setRecurring_Obj}
          cardItem={cardItem}
          income_expense_type={income_expense_type.current}
          setCardItem={setCardItem}
          modalType={modalType}
          updatedObj={updatedObj}
          updateRecurrenceFlag={updateRecurrenceFlag}
          updateFlag={updateFlag}
        />
        <StateView
          tab={tab}
          cardItem={cardItem}
          setCardItem={setCardItem}
          income_expense_type={income_expense_type.current}
          onClick={onClickState}
        />
        <ScenarioView cardItem={cardItem} onClick={onClickScenario} />
        <ContactView
          theme={theme}
          modalType={modalType}
          cardItem={cardItem}
          onChangeContact={onChangeContact}
        />
        <NotesView
          theme={theme}
          cardItem={cardItem}
          onChange={handleEditDescriptionChange}
          onBlur={onBlurText}
        />
        <Tooltip title={t("edit_form_highlight_note_tooltip")} placement="top">
          <FormControlLabel
            label={t("highlight note")}
            control={
              <Checkbox
                checked={cardItem?.is_note_highlighted}
                onChange={handleChangeHighLight}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            sx={{
              ml: "2.55rem",
            }}
          />
        </Tooltip>
        <CostUnitView
          theme={theme}
          cardItem={cardItem}
          modalType={modalType}
          onChangeCostUnit={onChangeCostUnit}
          onChangeDataSource={onChangeDataSource}
        />
        <UpdateDatesView theme={theme} cardItem={cardItem} />
      </Box>

      <ActionButtonView
        internalDsRef={internalDsRef}
        income_expense_type={income_expense_type.current}
        cardItem={cardItem}
        tab={tab}
        disabled={disabled}
        showSaved={showSaved}
        isTransactionFormUpdated={isTransactionFormUpdated}
        recurring_Obj={recurring_Obj}
        onCloseTransactionEdit={onCloseTransactionEdit}
        onSaveRecurrence={onSaveRecurrence}
        onClose={onClose}
        onCancel={onCancel}
        updateFlag={updateFlag}
        deleteCardByIdApi={deleteCardByIdApi}
      />
    </Drawer>
  );
};

export default DrawerModalEditForm;

const HeaderView = ({
  cardItem,
  theme,
  showSaved,
  showError,
  onCloseTransactionEdit,
}) => {
  const { t } = useTranslation();

  return (
    <Typography
      component={"div"}
      sx={{
        textAlign: "center",
        fontSize: "1.1rem",
        fontWeight: 500,
        textTransform: "uppercase",
        display: "flex",
        position: "relative",
        alignItems: "center",
        width: "100%",
        color: theme.palette.color.slate[500],
      }}
    >
      {formatDateToLocal(
        new Date(cardItem?.due_date ? cardItem?.due_date : new Date()),
        "MMMM yyyy"
      )}
      {showError ? (
        <Alert
          severity="warning"
          sx={{
            position: "fixed",
            top: "1.25rem",
            right: "46rem",
            boxShadow: "none !important",
            fontSize: "1rem",
            minHeight: "2rem",
            padding: "0.75rem 1.5rem",
            backgroundColor: "white",
            display: "inline-flex",
            alignItems: "start",
            maxWidth: "30rem",
            zIndex: 2,
            ml: "1.5rem",
            "& .MuiAlert-icon": {
              color: theme.palette.color.red[500],
              fontSize: "1.5rem",
              lineHeight: "1.5rem",
              alignItems: "center",
              p: 0,
            },
            "& .MuiAlert-message": {
              color: theme.palette.color.red[500],
              textAlign: "left",
              py: "0rem",
              display: "inline-flex",
            },
          }}
        >
          {t(typeof showError === "string" ? showError : showError?.errorText)}
        </Alert>
      ) : null}
      {showSaved && !showError ? (
        <Alert
          severity="success"
          sx={{
            boxShadow: "none !important",
            fontSize: "0.8rem",
            minHeight: "2rem",
            ml: "1.5rem",
            padding: "0rem 0.5rem",
            display: "inline-flex",
            alignItems: "center",
            alignSelf: "center",
            "& .MuiAlert-icon": {
              fontSize: "1rem",
              alignItems: "center",
            },
            "& .MuiAlert-message": {
              textAlign: "left",
              py: "0rem",
              display: "inline-flex",
            },
          }}
        >
          {t("Saved")}
        </Alert>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "2rem",
          position: "absolute",
          right: 0,
        }}
      >
        <CloseIcon
          onClick={onCloseTransactionEdit}
          sx={{
            cursor: "pointer",
            color: theme.palette.color.slate[500],
          }}
        />
      </div>
    </Typography>
  );
};

const DatesView = ({
  theme,
  tab,
  disabled,
  cardItem,
  setCardItem,
  isFormVisible,
  onClickEdit,
  updateFlag,
  recurring_Obj,
  setRecurring_Obj,
  updatedObj,
  isLoanSpecialPayments,
}) => {
  const { t } = useTranslation();

  const scenarioByTitle = useSelector(
    (state) => state.globalSlice.scenarioByTitle
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const _scenario = scenarioByTitle?.[cardItem?.scenario]?.[0];

  const start_date = cardItem?.recurring_rule
    ? recurring_Obj?.start_date
      ? formatDateToLocal(new Date(recurring_Obj?.start_date), "MMM yy")
      : null
    : cardItem?.due_date
      ? formatDateToLocal(new Date(cardItem?.due_date), "dd MMM yy")
      : null;
  const end_date =
    cardItem?.recurring_rule && recurring_Obj?.end_date
      ? formatDateToLocal(new Date(recurring_Obj?.end_date), "MMM yy")
      : null;

  const onClickLeftArrow = ({ isExtend = false, isShrink = false }) => {
    if (tab === "single") {
      const old_date = cardItem?.due_date || cardItem?.invoice_date;
      const new_date = format(subMonths(new Date(old_date), 1), "yyyy-MM-dd");
      const obj = {
        due_date: new_date,
        invoice_date: null,
      };
      setCardItem((draft) => ({ ...draft, ...obj }));
      updateFlag(obj);
    } else {
      const new_start_date = format(
        subMonths(new Date(recurring_Obj?.start_date), isShrink ? -1 : 1),
        "yyyy-MM-dd"
      );
      const new_end_date = format(
        subMonths(
          new Date(recurring_Obj?.end_date),
          isExtend || isShrink ? 0 : 1
        ),
        "yyyy-MM-dd"
      );
      const obj = {
        start_date: new_start_date,
        end_date: new_end_date,
      };
      updatedObj.current = {
        ...updatedObj.current,
        ...obj,
      };
      setRecurring_Obj({
        ...recurring_Obj,
        ...obj,
      });

      updateFlag();
    }
  };

  const onClickRightArrow = ({ isExtend = false, isShrink = false }) => {
    if (tab === "single") {
      const old_date = cardItem?.due_date || cardItem?.invoice_date;
      const new_date = format(addMonths(new Date(old_date), 1), "yyyy-MM-dd");
      const obj = {
        due_date: new_date,
        invoice_date: null,
      };
      setCardItem((draft) => ({ ...draft, ...obj }));
      updateFlag(obj);
    } else {
      const new_start_date = format(
        addMonths(
          new Date(recurring_Obj?.start_date),
          isExtend || isShrink ? 0 : 1
        ),
        "yyyy-MM-dd"
      );
      const new_end_date = format(
        addMonths(new Date(recurring_Obj?.end_date), isShrink ? -1 : 1),
        "yyyy-MM-dd"
      );
      const obj = {
        start_date: new_start_date,
        end_date: new_end_date,
      };
      updatedObj.current = {
        ...updatedObj.current,
        ...obj,
      };
      setRecurring_Obj({
        ...recurring_Obj,
        ...obj,
      });
      updateFlag();
    }
  };

  const onClickRightExtendArrow = () => {
    onClickRightArrow({ isExtend: true });
  };

  const onClickLeftExtendArrow = () => {
    onClickLeftArrow({ isExtend: true });
  };

  const onClickLeftShrinkArrow = () => {
    onClickLeftArrow({ isShrink: true });
  };

  const onClickRightShrinkArrow = () => {
    onClickRightArrow({ isShrink: true });
  };

  const ArrowButton = ({ tooltip, icon, disabled, onClick }) => {
    return (
      <Tooltip followCursor placement="top" title={t(tooltip)}>
        <span>
          <Button
            disabled={disabled}
            onClick={onClick}
            sx={{
              py: "0.5rem",
              px: ".25rem",
              minWidth: "unset",
              color: theme.palette.primary.main,
              borderRadius: 2,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: theme.palette.primary[100],
              },
              "& svg": {
                fontSize: "1.2rem",
              },
            }}
          >
            {icon}
          </Button>
        </span>
      </Tooltip>
    );
  };

  return (
    <div style={{ display: "flex", marginTop: "2rem", width: "100%" }}>
      {!cardItem?.recurring_rule ? (
        <Icon
          icon={CurrencyPrefixIcon?.[dataSetData?.currency]}
          size="1rem"
          style={{ height: "fit-content", mr: "1.5rem" }}
          color={theme.palette.color.blueGrey[700]}
        ></Icon>
      ) : (
        <LoopIcon
          sx={{
            color: theme.palette.color.blueGrey[700],
            ml: "0.2rem",
            mr: "1.5rem",
            fontSize: "1.4rem",
          }}
        />
      )}
      <div
        style={{
          width: "100%",
          padding: "1rem",
          backgroundColor: getTailwindColor(_scenario?.color, 50),
          borderRadius: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "0.2rem",
          }}
        >
          <AmountReadView theme={theme} cardItem={cardItem} />
          {isLoanSpecialPayments ? null : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.25rem",
              }}
            >
              {!disabled ? (
                <ArrowButton
                  icon={<KeyboardDoubleArrowLeftIcon />}
                  tooltip="edit_form_date_left_click_tooltip"
                  onClick={onClickLeftArrow}
                />
              ) : null}
              {tab !== "single" && !disabled ? (
                <ArrowButton
                  icon={
                    <KeyboardArrowLeftIcon style={{ fontSize: "1.4rem" }} />
                  }
                  tooltip="edit_form_date_extend_left_click_tooltip"
                  onClick={onClickLeftExtendArrow}
                />
              ) : null}
              {tab !== "single" && !disabled ? (
                <ArrowButton
                  icon={
                    <KeyboardArrowRightIcon style={{ fontSize: "1.4rem" }} />
                  }
                  tooltip="edit_form_date_shrink_left_click_tooltip"
                  onClick={onClickLeftShrinkArrow}
                />
              ) : null}
              <Typography
                sx={{
                  py: "0.5rem",
                  px: "1.25rem",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "0.85rem",
                  fontWeight: 600,
                  backgroundColor: theme.palette.color.white,
                  borderRadius: 2,
                  textTransform: "initial",
                  color: theme.palette.primary.main,
                }}
              >
                {`${start_date}${end_date ? ` -> ${end_date}` : ""}`}
              </Typography>
              {tab !== "single" && !disabled ? (
                <ArrowButton
                  icon={
                    <KeyboardArrowLeftIcon style={{ fontSize: "1.4rem" }} />
                  }
                  tooltip="edit_form_date_shrink_right_click_tooltip"
                  onClick={onClickRightShrinkArrow}
                />
              ) : null}
              {tab !== "single" && !disabled ? (
                <ArrowButton
                  icon={
                    <KeyboardArrowRightIcon style={{ fontSize: "1.4rem" }} />
                  }
                  tooltip="edit_form_date_extend_right_click_tooltip"
                  onClick={onClickRightExtendArrow}
                />
              ) : null}
              {!disabled ? (
                <ArrowButton
                  icon={<KeyboardDoubleArrowRightIcon />}
                  tooltip="edit_form_date_right_click_tooltip"
                  onClick={onClickRightArrow}
                />
              ) : null}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "baseline",
              marginRight: "1rem",
            }}
          >
            {cardItem.state === "Planned" ||
            dataSetData?.internal_data_source ===
              cardItem?.data_source ? null : (
              <DSImage
                ds_uuid={cardItem?.data_source}
                sx={{
                  width: "4rem",
                  height: "2rem",
                }}
              />
            )}
            {cardItem?.recurring_rule &&
            Constant?.staffState?.includes(cardItem?.state) ? (
              <Tooltip
                title={`${recurring_Obj?.recurring_type} recurring`}
                placement="top"
                followCursor
              >
                <Box
                  sx={{
                    display: "flex",
                    color: theme.palette.primary.main,

                    "& svg": {
                      fontSize: "1.25rem",
                      mr: "0.5rem",
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {recurring_Obj?.icon}
                </Box>
              </Tooltip>
            ) : null}
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "1rem",
                fontWeight: 600,
                color: theme.palette.primary.main,
                display: "flex",
                alignItems: "center",
              }}
            >
              {cardItem?.recurring_rule
                ? !Constant?.staffState?.includes(cardItem?.state)
                  ? cardItem?.bank_transaction_type
                  : recurring_Obj?.recurring_type === "employee"
                    ? t("Tab_Title_Staff")
                    : recurring_Obj?.recurring_type === "loan"
                      ? t("fixed_loan")
                      : recurring_Obj?.recurring_type === "leasing"
                        ? t("leasing")
                        : `Recurring - ${
                            recurring_Obj?.repetition === 1
                              ? "Monthly"
                              : `Every ${recurring_Obj?.repetition} Months`
                          }`
                : ""}
            </Typography>
          </div>
          {!isFormVisible ? (
            <Typography
              onClick={onClickEdit}
              sx={{
                textAlign: "center",
                fontSize: "0.85rem",
                fontWeight: 700,
                display: "flex",
                alignItems: "center",
                color: theme.palette.primary.main,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {t("Edit")}
            </Typography>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const AmountReadView = ({
  cardItem,
  theme,
  fontSize = "1.5rem",
  showIcon = false,
  onClick,
}) => {
  return (
    <Typography
      sx={{
        textAlign: "center",
        fontSize,
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        color:
          cardItem?.gross_value && cardItem?.gross_value >= 0
            ? theme.palette.color.green[500]
            : theme.palette.color.red[500],
        "&:hover": {
          "& #amount_edit_icon": {
            display: "flex",
          },
        },
      }}
    >
      {formatAmount({
        amount: cardItem?.gross_value,
        count: 2,
      })}

      {showIcon ? (
        <EditIcon
          onClick={onClick}
          id="amount_edit_icon"
          sx={{
            display: "none",
            cursor: "pointer",
            marginLeft: "1rem",
            fontSize: "2.5rem",
            color: theme.palette.primary.main,
          }}
        />
      ) : null}
    </Typography>
  );
};

const CategoryChipView = ({
  theme,
  showError,
  modalType,
  tab,
  recurring_Obj,
  cardItem,
  setCardItem,
  updateFlag,
  updateRecurrenceFlag,
  updatedObj,
  income_expense_type,
  setRecurring_Obj,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //state
  const [loanIncomeTransaction, setLoanIncomeTransaction] = useState({
    state: "Booked",
    scenario: "Base",
    bank_transaction_type: "LOAN_PAYMENT",
  });

  //api
  const updateRecurringRules = async (id, obj) => {
    await APICall("patch", EndPoints.recurring_rules + `${id}/`, obj).then(
      async (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  const getLoanTransaction = async (id) => {
    const dataSetData = store.getState()?.boardSlice?.dataSetData;

    try {
      const data = await getAllTransactionsByParams({
        dataset: dataSetData?.uuid,
        category_dataset: dataSetData?.use_global_categories
          ? null
          : dataSetData?.uuid,
        is_reconciled: false,
        order_by: "-due_date",
        recurring_rule: [id],
        bank_transaction_type: "LOAN_PAYMENT",
      });
      if (data?.[0]) {
        updatedObj.current = {
          ...updatedObj.current,
          loanIncomeTransaction: data?.[0],
        };
        setLoanIncomeTransaction(data?.[0]);
      } else {
        updatedObj.current = {
          ...updatedObj.current,
          loanIncomeTransaction,
        };
      }
    } catch (error) {}
  };

  //lifecycle
  useEffect(() => {
    if (
      recurring_Obj?.recurring_type === "loan" ||
      recurring_Obj?.recurring_type === "leasing"
    ) {
      if (recurring_Obj?.uuid && modalType === "edit") {
        getLoanTransaction(recurring_Obj?.uuid);
      } else {
        updatedObj.current = {
          ...updatedObj.current,
          loanIncomeTransaction,
        };
      }
    } else {
      updatedObj.current = {
        ...updatedObj.current,
        loanIncomeTransaction: null,
      };
    }
  }, [modalType, recurring_Obj?.recurring_type, recurring_Obj?.uuid]);

  //functions
  const onClickCategoryTitle = (e, item) => {
    if (item?.children?.length === 0) {
      const category =
        item?.uuid === `unCategorized_category` ? null : item?.uuid;

      const stateObj = getValidStateAfterCategorization({
        category,
        income_expense_type: cardItem?.income_expense_type,
        source: cardItem?.source,
        gross_value: cardItem?.gross_value,
        state: cardItem?.state,
      });

      let obj = {
        category,
        title: cardItem?.title,
        ...stateObj,
      };
      updatedObj.current = {
        ...updatedObj.current,
        ...obj,
      };

      updateFlag(obj);
      setCardItem((prev) => ({
        ...prev,
        ...obj,
      }));

      setTimeout(() => {
        dispatch(setPopupStatus4(null));
      }, 0);
    }
  };

  const onDeleteCategory = () => {
    const obj = { category: null };
    updateFlag(obj);
  };

  const onClickCategory = (e) => {
    dispatch(
      setPopupStatus4({
        open: true,
        anchorEl: e.currentTarget,
        overlay_type: "category",
        payload: {
          ruleTitle: cardItem?.title,
          type: tab === "single" ? 3 : cardItem?.income_expense_type,
          defaultType: cardItem?.income_expense_type,
          hideUncategorize: true,
          onClickCategoryTitle,
        },
      })
    );
  };

  ///////
  const onClickDepartmentTitle = (e, item) => {
    if (item?.children?.length === 0) {
      updateRecurrenceFlag();

      const obj = {
        category: item?.uuid === `unCategorized_department` ? null : item?.uuid,
      };

      setRecurring_Obj((prev) => ({
        ...prev,
        ...obj,
      }));
      dispatch(setPopupStatus4(null));
      if (!recurring_Obj?.is_department_separate) {
        setCardItem((prev) => ({
          ...prev,
          ...obj,
        }));
      }
    }
  };

  const onDeleteDepartment = () => {
    updateRecurrenceFlag();
    const obj = {
      category: null,
    };
    setRecurring_Obj((prev) => ({
      ...prev,
      ...obj,
    }));
    if (!recurring_Obj?.is_department_separate) {
      setCardItem((prev) => ({
        ...prev,
        ...obj,
      }));
    }
  };

  const onClickDepartment = (e) => {
    dispatch(
      setPopupStatus4({
        open: true,
        anchorEl: e.currentTarget,
        overlay_type: "category",
        payload: {
          ruleTitle: recurring_Obj?.name,
          hideUncategorize: true,
          type: 2,
          onClickCategoryTitle: onClickDepartmentTitle,
        },
      })
    );
  };

  ////////
  const onClickLoanIncomeCategoryTitle = (e, item) => {
    if (item?.children?.length === 0) {
      updateRecurrenceFlag();

      let obj = {
        category: item?.uuid === `unCategorized_category` ? null : item?.uuid,
        tax: null,
        tax_value: 0,
      };
      updatedObj.current = {
        ...updatedObj.current,
        loanIncomeTransaction: {
          ...updatedObj?.current?.loanIncomeTransaction,
          ...obj,
        },
      };
      dispatch(setPopupStatus4(null));
      setLoanIncomeTransaction((prev) => ({ ...prev, ...obj }));
    }
  };

  const onDeleteLoanIncomeCategory = () => {
    updateRecurrenceFlag();
    const obj = {
      category: null,
      tax: null,
      tax_value: 0,
    };
    updatedObj.current = {
      ...updatedObj.current,
      loanIncomeTransaction: {
        ...updatedObj?.current?.loanIncomeTransaction,
        ...obj,
      },
    };
    setLoanIncomeTransaction((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const onClickLoanIncomeCategory = (e) => {
    dispatch(
      setPopupStatus4({
        open: true,
        anchorEl: e.currentTarget,
        overlay_type: "category",
        payload: {
          // ruleTitle: recurring_Obj?.name,
          hideUncategorize: true,
          type: 1,
          onClickCategoryTitle: onClickLoanIncomeCategoryTitle,
        },
      })
    );
  };

  ////////
  const onChange = (e) => {
    updateRecurrenceFlag();
    setRecurring_Obj({
      ...recurring_Obj,
      is_department_separate: e.target.checked,
    });

    if (!e.target.checked) {
      setCardItem((prev) => ({
        ...prev,
        category: recurring_Obj?.category,
      }));
    }

    if (modalType === "edit") {
      updateRecurringRules(recurring_Obj?.uuid, {
        is_department_separate: e.target.checked,
      });
    }
  };

  return (
    <div style={{ display: "flex", marginTop: "1.5rem" }}>
      <LocalOfferOutlinedIcon
        sx={{
          color: theme.palette.color.blueGrey[700],
          ml: "0.2rem",
          mr: "1.6rem",
          fontSize: "1.4rem",
        }}
      />
      <Stack>
        {tab === "employee" ? (
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              alignItems: "start",
              flexDirection: "column",
              justifyContent: "start",
              marginBottom: "0.5rem",
            }}
          >
            <Typography
              variant="h6"
              component={"h6"}
              fontSize="0.9rem"
              fontWeight={"fontWeightMediumBold"}
              color={
                showError && !Boolean(recurring_Obj?.category)
                  ? "error.main"
                  : "color.mainTitle"
              }
              sx={{
                display: "flex",
                alignItems: "center",
                mr: "2rem",
              }}
            >
              {t("label_department")}
            </Typography>
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ mt: "1.5rem" }}
            >
              <CategoryChip
                maxWidth="12rem"
                height="2.25rem"
                fontSize="0.9rem"
                onClick={onClickDepartment}
                onDelete={onDeleteDepartment}
                showDelete={recurring_Obj?.category}
                categoryId={recurring_Obj?.category ?? null}
                noCategoryLabel={"Set Department"}
              />
              <Tooltip
                title={t(`department_separate_switch_tooltip`)}
                placement="top"
              >
                <Android12Switch
                  checked={recurring_Obj?.is_department_separate}
                  onChange={onChange}
                  sx={{
                    ml: 8,
                    mr: 2,
                  }}
                />
              </Tooltip>
              <Typography variant="caption" fontWeight={"fontWeightMedium"}>
                {recurring_Obj?.is_department_separate
                  ? t("department_separated_category")
                  : t("department_not_separated_category")}
              </Typography>
            </Stack>
          </div>
        ) : null}
        {tab === "loan" || tab === "leasing" ? (
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              alignItems: "start",
              flexDirection: "column",
              justifyContent: "start",
              marginBottom: "1rem",
            }}
          >
            <Typography
              variant="h6"
              component={"h6"}
              fontSize="0.9rem"
              fontWeight={"fontWeightMediumBold"}
              color={
                showError && !Boolean(loanIncomeTransaction?.category)
                  ? "error.main"
                  : "color.mainTitle"
              }
              sx={{
                display: "flex",
                alignItems: "center",
                mr: "2rem",
              }}
            >
              {t("loan_form_income_category")}
            </Typography>

            <CategoryChip
              maxWidth="12rem"
              height="2.25rem"
              fontSize="0.9rem"
              onClick={onClickLoanIncomeCategory}
              onDelete={onDeleteLoanIncomeCategory}
              showDelete={loanIncomeTransaction?.category}
              categoryId={loanIncomeTransaction?.category ?? null}
              noCategoryLabel={"Set Income Category"}
              style={{
                mt: "1rem",
              }}
            />
          </div>
        ) : null}
        {(tab === "employee" ? recurring_Obj?.is_department_separate : true) ? (
          <div
            style={{
              display: "inline-flex",
              alignItems: "start",
              flexDirection: "column",
              justifyContent: "start",
              marginBottom: "0.5rem",
              marginTop: tab === "employee" ? "2rem" : 0,
            }}
          >
            <Typography
              variant="h6"
              component={"h6"}
              fontSize="0.9rem"
              fontWeight={"fontWeightMediumBold"}
              color="color.mainTitle"
            >
              {tab === "loan" || tab === "leasing"
                ? t("loan_form_category")
                : t("category")}
            </Typography>
            <Stack direction={"row"} alignItems={"center"}>
              <CategoryChip
                key={`${tab}_${cardItem?.state}_category`} //important to update cache
                maxWidth="12rem"
                height="2.25rem"
                fontSize="0.9rem"
                onClick={onClickCategory}
                onDelete={onDeleteCategory}
                showDelete={cardItem?.category}
                categoryId={cardItem?.category ?? null}
                style={{
                  marginTop: "1rem",
                }}
              />
              {modalType === "edit" ? (
                <CreateRuleButton
                  key={tab} //important to update cache
                  theme={theme}
                  income_expense_type={income_expense_type}
                  cardItem={cardItem}
                  setCardItem={setCardItem}
                  updateFlag={updateFlag}
                />
              ) : null}
            </Stack>
          </div>
        ) : null}
      </Stack>
    </div>
  );
};

const CreateRuleButton = ({ theme, cardItem, setCardItem, updateFlag }) => {
  const { t } = useTranslation();

  const getTransaction = async () => {
    const data = await getTransactionById(cardItem?.uuid);
    if (data) {
      if (cardItem.category !== data.category) {
        updateFlag({ category: data?.category });
      }
      setCardItem(data);
    }
  };

  return (
    <TransactionRuleButton
      t={t}
      theme={theme}
      transaction={cardItem}
      callback={getTransaction}
      sx={{
        mt: "1rem",
        ml: "1rem",
      }}
    />
  );
};

const ContactView = ({ theme, modalType, onChangeContact, cardItem }) => {
  const { t } = useTranslation();
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  return (
    <div style={{ display: "flex", marginTop: "2rem", width: "100%" }}>
      <IoPeopleCircleOutline
        style={{
          color: theme.palette.color.blueGrey[600],
          marginLeft: "0.2rem",
          marginRight: "1rem",
          fontSize: "1.5rem",
        }}
      />
      <Stack>
        <Typography
          variant="span"
          fontSize="0.9rem"
          color="color.mainTitle"
          sx={{
            ml: "0.35rem",
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {t("Contact")}
        </Typography>
        <ContactDropDown
          width="25rem"
          value={{ uuid: cardItem?.contact, name: cardItem?.contact_name }}
          onChange={onChangeContact}
          placeholder={
            cardItem?.income_expense_type === 2
              ? "dropdown_placeholder_select_receiver"
              : "dropdown_placeholder_select_sender"
          }
          payload={{ cardItem }}
          disabled={modalType === "edit"}
          extraProps={{
            query_options_payload: {
              type: [cardItem?.income_expense_type],
              dataset: dataSetData?.uuid,
            },
            dropdownBaseSX: {
              border: "none",
              mt: "0.25rem",
              backgroundColor: theme.palette.color.slate[50],
            },
          }}
        />
      </Stack>
    </div>
  );
};

const NotesView = ({ theme, cardItem, onChange, onBlur }) => {
  const { t } = useTranslation();
  const onFocus = () => {
    global.isInputFocused = true;
  };
  return (
    <div style={{ display: "flex", marginTop: "2rem", width: "100%" }}>
      <NotesIcon
        sx={{
          color: theme.palette.color.blueGrey[700],
          mt: !cardItem?.note ? "0.75rem" : 0,
          ml: "0.2rem",
          mr: "0.8rem",
          fontSize: "1.4rem",
        }}
      />
      <DescriptionInput
        value={cardItem?.note || ""}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        likeGoogle
        variant="filled"
        hideTitle
        fontSize={"0.9rem"}
        tooltip={t("Note_Optional")}
        label={t("Note_Optional")}
        sx={{ width: "100%" }}
      />
    </div>
  );
};

const UpdateDatesView = ({ theme, cardItem }) => {
  const { t } = useTranslation();

  if (!cardItem?.last_modified_date && !cardItem?.creation_date) return null;
  return (
    <Stack
      direction={"row"}
      alignItems={"start"}
      justifyContent={"end"}
      sx={{
        width: "100%",
        mb: "4rem",
        backgroundColor: theme.palette.color.white,
        zIndex: 6,
      }}
    >
      <Typography
        variant="overline"
        fontWeight={"fontWeightMedium"}
        color="color.slate.700"
      >
        {t("transaction_updated_date")}:{" "}
        <b>
          {cardItem?.last_modified_date
            ? formatDateToLocal(cardItem?.last_modified_date)
            : ""}
        </b>
      </Typography>
      <Typography
        variant="overline"
        fontWeight={"fontWeightMedium"}
        color="color.slate.700"
        sx={{ ml: "2rem" }}
      >
        {t("transaction_created_date")}:{" "}
        <b>
          {cardItem?.creation_date
            ? formatDateToLocal(cardItem?.creation_date)
            : ""}
        </b>
      </Typography>
    </Stack>
  );
};
