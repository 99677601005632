import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { IoPeopleCircle } from "react-icons/io5";
import React, { useCallback } from "react";
import { format } from "date-fns";

import { formatAmount, getIntegrationCard, truncate } from "../Helper/data";
import { setPopupStatus2 } from "../store/slices/datasets";
import { Constant } from "../Helper";
import store from "../store";

// DateCell Component
export const DateCell = React.memo(({ theme, value, formatString, sx }) => {
  if (!value) return null;

  const profile = store?.getState()?.settingsSlice?.profile;
  const formattedDate = value
    ? format(
        new Date(value),
        formatString ||
          (profile?.locale === "de_DE" ? "dd.MM.yyyy" : "dd/MM/yyyy")
      )
    : null;
  return (
    <Typography
      variant="caption"
      className="cell-text-truncate"
      sx={{ fontSize: "0.8rem", ...sx }}
    >
      {formattedDate}
    </Typography>
  );
});

// GrossValueCell Component
export const GrossValueCell = React.memo(({ row, value, theme, sx }) => {
  if (!value) return null;

  const formattedAmount = formatAmount({ amount: value });
  return (
    <Tooltip title={formattedAmount}>
      <Typography
        sx={{
          fontSize: "0.8rem",
          color:
            parseFloat(row?.income_expense_type) === 1
              ? theme?.palette?.color?.green[500]
              : theme?.palette?.color?.red[500],
          ...sx,
        }}
        className="cell-text-truncate"
      >
        {formattedAmount}
      </Typography>
    </Tooltip>
  );
});

// DataSourceCell Component
export const DataSourceCell = React.memo(({ theme, row, t, sx }) => {
  const dataSourceById = store?.getState()?.globalSlice?.dataSourceById;
  const accountByDS = store?.getState()?.globalSlice?.accountByDS;
  const ds = dataSourceById?.[row?.data_source]?.[0];
  const account = accountByDS?.[row?.data_source]?.[0];
  let card = getIntegrationCard({ ds });
  if (ds?.internal_dataset || card?.title === "Manual_DS_title") {
    card = { title: "Internal data source" };
  }
  if (row?.source === 2) {
    card = { title: account?.bank_details?.title || card?.title };
  }
  return (
    <Tooltip title={t(card?.title)}>
      <Typography
        className="cell-text-truncate"
        sx={{
          width: "100%",
          fontSize: "0.8rem",
          textAlign: "left",
          ...sx,
        }}
      >
        {t(card?.title)}
      </Typography>
    </Tooltip>
  );
});

// ContactTypeCell Component
export const ContactTypeCell = React.memo(({ theme, value, t, sx }) => {
  return (
    <Chip
      label={t(Constant?.ContactType[value])}
      sx={{
        color: "color.slate.700",
        backgroundColor: "color.slate.100",
        height: "fit-content",
        width: "fit-content",
        p: "0.5rem",
        py: "0.25rem",
        borderRadius: theme?.borderRadius?.main,
        "& .MuiChip-label": {
          px: 0,
          fontSize: "0.8rem",
          fontWeight: theme?.typography?.fontWeightMediumBold,
        },
        ...sx,
      }}
    />
  );
});

export const ContactCell = React.memo(
  ({ theme, value, row, sx, textStyle }) => {
    const onClick = useCallback(
      (e) => {
        e?.stopPropagation();
        store?.dispatch(
          setPopupStatus2({
            open: true,
            overlay_type: "drawer_modal",
            anchor: "right",
            payload: {
              hideAccessInListViewFilter: true,
              hideHeaderActions: true,
              hideScenarioChain: true,
              cell: {
                contact: row?.contact,
                withUrl: true,
                clientType: row?.income_expense_type,
                isClientView: true,
                clientName: row?.contact_name,
              },
            },
          })
        );
      },
      [row?.contact, row?.contact_name, row?.income_expense_type]
    );

    if (!value && !row?.contact_name) return null;

    return (
      <Tooltip title={row?.contact_name || value} placement="top">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            maxWidth: "100%",
            borderRadius: theme.borderRadius.borderRadiusXXL,
            backgroundColor:
              theme.palette.color?.[
                row?.contact_name ? "violet" : "slate"
              ]?.[100],
            paddingBlock: "0.25rem",
            paddingLeft: row?.contact_name ? "0.25rem" : "0.5rem",
            paddingRight: "0.65rem",
            cursor: "pointer",
            ...sx,
          }}
        >
          {row?.contact_name ? (
            <IoPeopleCircle
              style={{
                width: "1.25rem",
                marginRight: "0.25rem",
                fontSize: "1.25rem",
                color: theme.palette.color.slate[500],
              }}
            />
          ) : null}
          <Typography
            onClick={row?.contact_name ? onClick : undefined}
            variant="caption"
            className="cell-text-truncate"
            sx={{
              fontSize: "0.8rem",
              maxWidth: "auto",
              width: row?.contact_name ? "calc(100% - 1.5rem)" : "100%",
              textDecoration: row?.contact_name ? "underline" : "none",
              ...textStyle,
            }}
          >
            {row?.contact_name || value}
          </Typography>
        </Box>
      </Tooltip>
    );
  }
);

export const TitleCell = React.memo(
  ({ theme, count = 200, value, row, sx }) => {
    return (
      <Tooltip title={value} placement="top">
        <Typography
          variant="caption"
          className="cell-text-truncate"
          sx={{
            maxWidth: "auto",
            width: "100%",
            textAlign: "left",
            fontSize: "0.8rem",
            ...sx,
          }}
        >
          {truncate(value, count)}
        </Typography>
      </Tooltip>
    );
  }
);
