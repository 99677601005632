import { Box, Fade } from "@mui/material";

import AvailableView from "../../../Pages/Settings/DataSource/AvailableView";
import { thinScrollbarStyle } from "../../../Helper/data";
import CustomModal from "../../Model/CustomModal";
import { Color } from "../../../Helper";

const IntegrationsDialogView = ({
  open,
  handleClickClose,
  type,
  isDSChanged,
  defaultSection = 0,
}) => {
  return (
    <CustomModal
      hideAction
      width="100%"
      height="100%"
      open={!!open}
      onClose={handleClickClose}
      restProps={{
        slots: { TransitionComponent: Fade },
      }}
      PaperPropsSx={{
        maxWidth: "none",
        paddingInline: "0.2rem",
        width: "auto",
        overflowY: "hidden",
        height: "70rem",
        maxHeight: "80vh",
      }}
    >
      <Box
        sx={{
          p: "1rem",
          px: "3rem",
          overflowY: "auto",
          height: "100%",
          backgroundColor: Color.appThemeBg,
          ...thinScrollbarStyle,
        }}
      >
        <AvailableView
          clickable
          closeAvailableOverlay={handleClickClose}
          type={type}
          defaultSection={type === "assign" ? 10 : defaultSection}
          isDSChanged={isDSChanged}
        />
      </Box>
    </CustomModal>
  );
};

export default IntegrationsDialogView;
