import { PaletteColor, createTheme } from "@mui/material/styles";
import { Color } from "@mui/material";

interface MyColor {
  50?: string;
  100?: string;
  200?: string;
  300?: string;
  400?: string;
  500?: string;
  600?: string;
  700?: string;
  800?: string;
  900?: string;
  950?: string;
  darkHover?: string;
}

type MyPaletteColorOptions = MyColor | Color | PaletteColor;

declare module "@mui/material/styles/createTypography" {
  interface TypographyOptions {
    fontWeightMediumBold?: React.CSSProperties["fontWeight"];
  }

  interface Typography {
    fontWeightMediumBold?: React.CSSProperties["fontWeight"];
  }
}

declare module "@mui/material/styles/createPalette" {
  interface PaletteColor extends MyColor {}
  interface SimplePaletteColorOptions extends MyColor {}
  interface PaletteOptions {
    color: {
      black: string;
      white: string;
      appThemeBg: string;
      FooterBG: string;
      mainTitle: string;
      inputTitle: string;
      description: string;
      description2: string;
      primary: MyPaletteColorOptions;
      blueGrey: MyPaletteColorOptions;
      brown: MyPaletteColorOptions;
      deepOrange: MyPaletteColorOptions;
      deepPurple: MyPaletteColorOptions;
      lightBlue: MyPaletteColorOptions;
      slate: MyPaletteColorOptions;
      orange: MyPaletteColorOptions;
      zinc: MyPaletteColorOptions;
      neutral: MyPaletteColorOptions;
      stone: MyPaletteColorOptions;
      grey: MyPaletteColorOptions;
      red: MyPaletteColorOptions;
      yellow: MyPaletteColorOptions;
      green: MyPaletteColorOptions;
      lightGreen: MyPaletteColorOptions;
      blue: MyPaletteColorOptions;
      indigo: MyPaletteColorOptions;
      amber: MyPaletteColorOptions;
      lime: MyPaletteColorOptions;
      emerald: MyPaletteColorOptions;
      teal: MyPaletteColorOptions;
      cyan: MyPaletteColorOptions;
      sky: MyPaletteColorOptions;
      violet: MyPaletteColorOptions;
      purple: MyPaletteColorOptions;
      fuchsia: MyPaletteColorOptions;
      pink: MyPaletteColorOptions;
      rose: MyPaletteColorOptions;
    };
  }

  interface Palette {
    color: PaletteOptions["color"];
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    borderRadius: {
      borderRadiusSM?: string;
      borderRadiusMD?: string;
      main?: string;
      borderRadiusLG?: string;
      borderRadiusXL?: string;
      borderRadiusXL2?: string;
      borderRadiusXXL?: string;
      full?: string;
    };
    boxShadow: string;
    thinScrollBar: {
      scrollbarWidth: string;
      "&::-webkit-scrollbar": {
        width: string;
      };
    };
  }
  interface ThemeOptions {
    shape: {
      borderRadiusSM?: number;
      borderRadiusMD?: number;
      borderRadius?: number;
      borderRadiusLG?: number;
      borderRadiusXL?: number;
    };
    boxShadow: string;
    borderRadius: {
      borderRadiusSM?: string;
      borderRadiusMD?: string;
      main?: string;
      borderRadiusLG?: string;
      borderRadiusXL?: string;
      borderRadiusXL2?: string;
      borderRadiusXXL?: string;
      full?: string;
    };
    thinScrollBar: {
      scrollbarWidth: string;
      "&::-webkit-scrollbar": {
        width: string;
      };
    };
  }
}

const BREAKPOINTS = {
  // xs: 0,
  // sm: 600,
  // md: 900,
  // lg: 1200,
  // xl: 1536,
  xs: 0, //mobile
  small: 600,
  sm: 768, //tablet
  md: 960,
  lg: 1280,
  mid: 1536,
  isMobile: 425,
  isTablet: 768,
  s1080: 1080,
  s1280: 1280,
  s1366: 1366,
  s1440: 1440,
  s1536: 1536,
  s1650: 1650,
  s1745: 1745,
  s1920: 1920,
  s2133: 2133,
  s2400: 2400,
  s2560: 2560,
  s2880: 2880,
  s3840: 3840,
};

const defaultTheme = createTheme();
const fontFamily = `'Inter', sans-serif`;

const primary = {
  main: "#6101D1",
  light: "#F1E5FF",
  dark: "#6101D1",
  darkHover: "#5701BC",
  50: "#F5F3FF",
  100: "#EDE9FE",
  200: "#DDD6FE",
  300: "#C4B5FD",
  400: "#A78BFA",
  500: "#8B5CF6",
  600: "#7C3AED",
  700: "#6D28D9",
  800: "#5B21B6",
  900: "#4C1D95",
  950: "#2E1065",
};
const color = {
  black: "#000",
  white: "#fff",
  appThemeBg: "#f8fafc",
  FooterBG: "#f1f5f9",
  mainTitle: "#364152", //slate.600
  inputTitle: "#94a3b8", //slate.400
  description: "#64748b",
  description2: "rgba(0, 0, 0, 0.5)",
  primary,
  blueGrey: {
    50: "#eceff1",
    100: "#cfd8dc",
    200: "#b0bec5",
    300: "#90a4ae",
    400: "#78909c",
    500: "#607d8b",
    600: "#546e7a",
    700: "#455a64",
    800: "#37474f",
    900: "#263238",
    A100: "#cfd8dc",
    A200: "#b0bec5",
    A400: "#78909c",
    A700: "#455a64",
  },
  brown: {
    50: "#efebe9",
    100: "#d7ccc8",
    200: "#bcaaa4",
    300: "#a1887f",
    400: "#8d6e63",
    500: "#795548",
    600: "#6d4c41",
    700: "#5d4037",
    800: "#4e342e",
    900: "#3e2723",
    A100: "#d7ccc8",
    A200: "#bcaaa4",
    A400: "#8d6e63",
    A700: "#5d4037",
  },
  deepOrange: {
    50: "#fbe9e7",
    100: "#ffccbc",
    200: "#ffab91",
    300: "#ff8a65",
    400: "#ff7043",
    500: "#ff5722",
    600: "#f4511e",
    700: "#e64a19",
    800: "#d84315",
    900: "#bf360c",
    A100: "#ff9e80",
    A200: "#ff6e40",
    A400: "#ff3d00",
    A700: "#dd2c00",
  },
  deepPurple: {
    50: "#ede7f6",
    100: "#d1c4e9",
    200: "#b39ddb",
    300: "#9575cd",
    400: "#7e57c2",
    500: "#673ab7",
    600: "#5e35b1",
    700: "#512da8",
    800: "#4527a0",
    900: "#311b92",
    A100: "#b388ff",
    A200: "#7c4dff",
    A400: "#651fff",
    A700: "#6200ea",
  },
  lightBlue: {
    50: "#e1f5fe",
    100: "#b3e5fc",
    200: "#81d4fa",
    300: "#4fc3f7",
    400: "#29b6f6",
    500: "#03a9f4",
    600: "#039be5",
    700: "#0288d1",
    800: "#0277bd",
    900: "#01579b",
    A100: "#80d8ff",
    A200: "#40c4ff",
    A400: "#00b0ff",
    A700: "#0091ea",
  },
  slate: {
    50: "#f8fafc",
    100: "#f1f5f9",
    200: "#e2e8f0",
    300: "#cbd5e1",
    400: "#94a3b8",
    500: "#64748b",
    600: "#475569",
    700: "#364152",
    800: "#27303f",
    900: "#1a202e",
    950: "#171b23",
  },
  orange: {
    50: "#fff8f1",
    100: "#feecdc",
    200: "#fcd9bd",
    300: "#ffc27f",
    400: "#ff9a3c",
    500: "#ff7c0f",
    600: "#db6b08",
    700: "#bd580c",
    800: "#8c3d10",
    900: "#77340d",
    950: "#572508",
  },
  zinc: {
    50: "#f8fafc",
    100: "#f1f5f9",
    200: "#e2e8f0",
    300: "#cbd5e1",
    400: "#94a3b8",
    500: "#64748b",
    600: "#475569",
    700: "#364152",
    800: "#27303f",
    900: "#1a202e",
    950: "#171b23",
  },
  neutral: {
    50: "#f9fafb",
    100: "#f4f5f7",
    200: "#e5e7eb",
    300: "#d2d6dc",
    400: "#9fa6b2",
    500: "#6b7280",
    600: "#4b5563",
    700: "#374151",
    800: "#252f3f",
    900: "#161e2e",
    950: "#0e131f",
  },
  stone: {
    50: "#f5f5f5",
    100: "#ebebeb",
    200: "#d4d4d4",
    300: "#a3a3a3",
    400: "#757575",
    500: "#525252",
    600: "#404040",
    700: "#2c2c2c",
    800: "#1f1f1f",
    900: "#141414",
    950: "#000000",
  },
  grey: {
    50: "#f9fafb",
    100: "#f4f5f7",
    200: "#e5e7eb",
    300: "#d2d6dc",
    400: "#9fa6b2",
    500: "#6b7280",
    600: "#4b5563",
    700: "#374151",
    800: "#252f3f",
    900: "#161e2e",
    950: "#000000",
  },
  red: {
    50: "#fef2f2",
    100: "#fee2e2",
    200: "#fecaca",
    300: "#fca5a5",
    400: "#f87171",
    500: "#ef4444",
    600: "#dc2626",
    700: "#b91c1c",
    800: "#991b1b",
    900: "#7f1d1d",
    950: "#000000",
  },
  yellow: {
    50: "#fffff0",
    100: "#fefcbf",
    200: "#faf089",
    300: "#f6e05e",
    400: "#ecc94b",
    500: "#d69e2e",
    600: "#b7791f",
    700: "#975a16",
    800: "#744210",
    900: "#5f370e",
    950: "#000000",
  },
  green: {
    50: "#f0fdf4",
    100: "#c6f6d5",
    200: "#9ae6b4",
    300: "#68d391",
    400: "#48bb78",
    500: "#38a169",
    600: "#2f855a",
    700: "#276749",
    800: "#22543d",
    900: "#1c4532",
    950: "#000000",
  },
  lightGreen: {
    50: "#f1f8e9",
    100: "#dcedc8",
    200: "#c5e1a5",
    300: "#aed581",
    400: "#9ccc65",
    500: "#8bc34a",
    600: "#7cb342",
    700: "#689f38",
    800: "#558b2f",
    900: "#33691e",
    950: "#000000",
    A100: "#ccff90",
    A200: "#b2ff59",
    A400: "#76ff03",
    A700: "#64dd17",
  },
  blue: {
    50: "#eff6ff",
    100: "#dbeafe",
    200: "#bfdbfe",
    300: "#93c5fd",
    400: "#60a5fa",
    500: "#3b82f6",
    600: "#2563eb",
    700: "#1d4ed8",
    800: "#1e40af",
    900: "#1e3a8a",
    950: "#000000",
  },
  indigo: {
    50: "#eef2ff",
    100: "#e0e7ff",
    200: "#c7d2fe",
    300: "#a5b4fc",
    400: "#818cf8",
    500: "#6366f1",
    600: "#4f46e5",
    700: "#4338ca",
    800: "#3730a3",
    900: "#312e81",
    950: "#000000",
  },
  amber: {
    50: "#fffbeb",
    100: "#fef3c7",
    200: "#fde68a",
    300: "#fcd34d",
    400: "#fbbf24",
    500: "#f59e0b",
    600: "#d97706",
    700: "#b45309",
    800: "#92400e",
    900: "#78350f",
    950: "#451a03",
  },
  lime: {
    50: "#F7FEE7",
    100: "#ECFCCB",
    200: "#D9F99D",
    300: "#BEF264",
    400: "#A3E635",
    500: "#84CC16",
    600: "#65A30D",
    700: "#4D7C0F",
    800: "#3F6212",
    900: "#365314",
    950: "#1A2E05",
  },
  emerald: {
    50: "#ECFDF5",
    100: "#D1FAE5",
    200: "#A7F3D0",
    300: "#6EE7B7",
    400: "#34D399",
    500: "#10B981",
    600: "#059669",
    700: "#047857",
    800: "#065F46",
    900: "#064E3B",
    950: "#022C22",
  },
  teal: {
    50: "#F0FDFA",
    100: "#CCFBF1",
    200: "#99F6E4",
    300: "#5EEAD4",
    400: "#2DD4BF",
    500: "#14B8A6",
    600: "#0D9488",
    700: "#0F766E",
    800: "#115E59",
    900: "#134E4A",
    950: "#042F2E",
  },
  cyan: {
    50: "#ECFEFF",
    100: "#CFFAFE",
    200: "#A5F3FC",
    300: "#67E8F9",
    400: "#22D3EE",
    500: "#06B6D4",
    600: "#0891B2",
    700: "#0E7490",
    800: "#155E75",
    900: "#164E63",
    950: "#083344",
  },
  sky: {
    50: "#F0F9FF",
    100: "#E0F2FE",
    200: "#BAE6FD",
    300: "#7DD3FC",
    400: "#38BDF8",
    500: "#0EA5E9",
    600: "#0284C7",
    700: "#0369A1",
    800: "#075985",
    900: "#0C4A6E",
    950: "#082F49",
  },
  violet: {
    50: "#F5F3FF",
    100: "#EDE9FE",
    200: "#DDD6FE",
    300: "#C4B5FD",
    400: "#A78BFA",
    500: "#8B5CF6",
    600: "#7C3AED",
    700: "#6D28D9",
    800: "#5B21B6",
    900: "#4C1D95",
    950: "#2E1065",
  },
  purple: {
    50: "#FAF5FF",
    100: "#F3E8FF",
    200: "#E9D5FF",
    300: "#D8B4FE",
    400: "#C084FC",
    500: "#A855F7",
    600: "#9333EA",
    700: "#7E22CE",
    800: "#6B21A8",
    900: "#581C87",
    950: "#3B0764",
  },
  fuchsia: {
    50: "#FDF4FF",
    100: "#FAE8FF",
    200: "#F5D0FE",
    300: "#F0ABFC",
    400: "#E879F9",
    500: "#D946EF",
    600: "#C026D3",
    700: "#A21CAF",
    800: "#86198F",
    900: "#701A75",
    950: "#4A044E",
  },
  pink: {
    50: "#FDF2F8",
    100: "#FCE7F3",
    200: "#FBCFE8",
    300: "#F9A8D4",
    400: "#F472B6",
    500: "#EC4899",
    600: "#DB2777",
    700: "#BE185D",
    800: "#9D174D",
    900: "#831843",
    950: "#500724",
  },
  rose: {
    50: "#FFF1F2",
    100: "#FFE4E6",
    200: "#FECDD3",
    300: "#FDA4AF",
    400: "#FB7185",
    500: "#F43F5E",
    600: "#E11D48",
    700: "#BE123C",
    800: "#9F1239",
    900: "#881337",
    950: "#4C0519",
  },
};
const boxShadow =
  "rgba(0, 0, 0, 0.1) 0px 0px 6px -1px, rgba(0, 0, 0, 0.05) 0px 0px 0px 0px";
const borderRadius = {
  borderRadiusSM: "2px",
  borderRadiusMD: "4px",
  main: `6px`,
  borderRadiusLG: "8px",
  borderRadiusXL: "10px",
  borderRadiusXL2: "13px",
  borderRadiusXXL: "16px",
  full: "100%",
};
const theme = createTheme({
  spacing: (factor: number) => `${0.25 * factor}rem`, // (Bootstrap strategy)
  shape: {
    borderRadiusSM: 0.5,
    borderRadiusMD: 1,
    borderRadius: 1.5,
    borderRadiusLG: 2,
    borderRadiusXL: 2.5,
  },
  borderRadius,
  thinScrollBar: {
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "0.3rem",
    },
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      ...BREAKPOINTS,
    },
  },
  palette: {
    primary,
    color,
    tonalOffset: {
      light: 0.2,
      dark: 0.2,
    },
  },
  typography: {
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightMediumBold: 600,
    fontWeightBold: 700,
    allVariants: {
      fontFamily,
      textTransform: "initial",
    },
  },
  boxShadow,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily,
          borderRadius: borderRadius.main,
          textTransform: "initial",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius.main,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: color.white,
          color: color.slate[700],
          fontSize: "0.75rem",
          borderRadius: borderRadius.main,
          fontFamily,
          // padding: "0.5rem",
          boxShadow,
          border: `1px solid ${color.slate[300]}`,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 1303, // Custom zIndex for Popovers
          boxShadow,
          borderRadius: borderRadius.borderRadiusXL2,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 1303, // Custom zIndex for Modals
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 1303, // Custom zIndex for Dialogs
        },
      },
    },
  },
});

export default theme;
