import { Box, keyframes, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const blink = keyframes`
0% {
  opacity: 0.5;
  font-weight:600
}
50% {
  opacity: .5;
  font-weight:500
}
100% {
  opacity: 1;
}
`;
const OverlayHeader = ({
  subTitle = "",
  hideClose = false,
  showBlink = false,
  showSaved = false,
  showError = false,
  icon = null,
  linkView = null,
  style,
  onClose,
  subTitleStyle,
  height,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: "1.5rem",
        pl: "2rem",
        borderTopRightRadius: theme.borderRadius.main,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height,
        backgroundColor: "transparent",
        position: "relative",
        borderBottom: `1px solid ${theme.palette.color.slate[200]}`,
        ...style,
      }}
    >
      <Typography
        variant="h6"
        color="color.mainTitle"
        fontWeight={"fontWeightMedium"}
        sx={{
          fontSize: { xs: "1.2rem", small: "1.4rem", isTablet: "1.6rem" },
          lineHeight: { xs: "1.2rem", small: "1.4rem", isTablet: "1.6rem" },
          animation: showBlink && `${blink} 500ms linear infinite`,
          display: "inline-flex",
          alignItems: "center",
          ...subTitleStyle,
        }}
      >
        {icon}
        {subTitle}{" "}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {showSaved || showError ? (
          <Typography
            variant="h5"
            component={"span"}
            fontWeight={"fontWeightBold"}
            sx={{
              display: "inline-flex",
              alignItems: "center",
              fontSize: "1rem",
              mr: "0.5rem",
              color: showError
                ? theme.palette.color.red[500]
                : theme.palette.color.green[500],
            }}
          >
            {showError ? t("transaction_overlay_header_error_msg") : t("Saved")}
          </Typography>
        ) : null}
        {linkView}
        {hideClose ? null : (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: theme.palette.color.slate[700],
              p: 0,
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
export default OverlayHeader;
